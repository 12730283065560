import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { MessageDialogModel } from './message-dialog.model';

@Injectable({
	providedIn: 'root'
})
export class MessageDialogService {
	constructor(private dialog: MatDialog) {}

	message(message: string, subMessage?: string, title?: string) {

		const dialogData = new MessageDialogModel(title, message, subMessage);

		const dialogRef = this.dialog.open(MessageDialogComponent, {
			maxWidth: '600px',
			data: dialogData
		});
		
		return dialogRef.afterClosed().toPromise();
	}
}
