<div fxLayout="row">
	<div fxLayout="column" fxFlex.gt-sm="300px" fxFlex="50">
		<div fxLayout="column">
			<pp-display label="Period start">
				{{ header?.startDateTime | date }}
			</pp-display>
			<pp-display label="Period end">
				{{ header?.endDateTime | date }}
			</pp-display>
			<pp-display label="Professional" [value]="header?.professionalName"></pp-display>
		</div>
	</div>

	<div fxLayout="column" fxFlex="50">
		<pp-organisation-hierarchy-edit
			[organisation]="organisation"
			[editDto]="header"
			[immediateUpdate]="true"
			(changed)="organisationChanged($event)"
		>
		</pp-organisation-hierarchy-edit>
	</div>
</div>
