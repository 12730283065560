<ag-grid-angular
	class="ag-material"
	[ngClass]="{ 'no-hscroll': noHScroll }"
	[gridOptions]="gridOptions"
	[columnDefs]="columns"
	[rowData]="rowData"
	[pinnedBottomRowData]="pinnedBottomRowData"
>
</ag-grid-angular>

<button
	*ngIf="hasAdd && (!readOnly || readOnlyAllowAdd)"
	class="add-button"
	mat-mini-fab
	matTooltip="Add"
	(click)="handleAdd($event)"
>
	<mat-icon>add</mat-icon>
</button>
