import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';

import {
	StatisticClientSessionDto,
	AgGridService,
} from 'app/services';
import { IEditComponent } from 'app/interfaces';

import { ClientEditBaseComponent } from '../client-edit-base.component';
import { CasesService } from 'app/cases/cases.service';
import { SelectionGridComponent } from 'app/shared/selection-grid/selection-grid.component';
import { ClientSessionEditComponent } from './client-session-edit/client-session-edit.component';

@Component({
	selector: 'pp-client-edit-sessions',
	templateUrl: './client-edit-sessions.component.html',
	styleUrls: ['./client-edit-sessions.component.scss']
})
export class ClientEditSessionsComponent extends ClientEditBaseComponent implements OnInit, IEditComponent {
	private changes: boolean;
	session: StatisticClientSessionDto;
	columnDefs: any[];
	isAdding: boolean;

	@ViewChild('grid', { static: true }) grid: SelectionGridComponent;
	@ViewChild('editor') editor: ClientSessionEditComponent;

	constructor(
		private agGrid: AgGridService,
		private service: CasesService,
	) {
		super();
	}

	hasChanges() {
		return this.changes;
	}

	markClean() {
		this.changes = false;
	}

	ngOnInit() {
		this.setUpAgGrid();
	}

	add(event) {
		this.changes = true;
		this.isAdding = true;
		this.session = <StatisticClientSessionDto> {
			sessionDateTime: moment().startOf('day').toDate(),
			sessionDurationHours: 1,
			sessionDurationMinutes: 0
		};

		this.client.statisticClientSessions.push(this.session);
		this.grid.refresh();
	}

	edit(session: StatisticClientSessionDto) {
		this.isAdding = false;
		this.session = session;
	}

	delete(session: StatisticClientSessionDto) {
		_.pull(this.client.statisticClientSessions, session);
		this.changes = true;
		this.session = undefined;
		this.grid.refresh();
	}

	selectSession(session: StatisticClientSessionDto) {
		this.session = session;
	}

	sessionUpdated(session: StatisticClientSessionDto) {
		this.changes = true;
		this.grid.refresh();
	}

	private setUpAgGrid() {
		this.columnDefs = [
			this.agGrid.makeDateColumn('Session date', 'sessionDateTime')
		];
	}
}
