import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
	selector: 'pp-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
	@Input() opened: boolean = false;
	@Input() role: string = 'alert';
	@Input('aria-live') ariaLive: 'polite' | 'assertive' | 'off' = 'polite';
}
