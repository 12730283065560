import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'app/services';

@Component({
	selector: 'pp-profile-navigator',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})

export class ProfileNavigatorComponent implements OnInit {
	
	profiles: string[];
	useCredentials = false;
	useRates = false;
	useChecks = false;
	useAttachments = false;
	useNotifications = false;
	useOffices = false;
	useContact = false;
	useWorkHS = false;
	useInsurance = false;

	constructor(
		private config: ConfigService,
		private router: Router,
	) {	
		this.useCredentials = config.systemPreferences.useProfileCredentials;
		this.useRates = config.systemPreferences.useProfileRates;
		this.useChecks  = config.systemPreferences.useProfileChecks;
		this.useAttachments  = config.systemPreferences.useProfileAttachments;
		this.useNotifications  = config.systemPreferences.useProfileNotifications;
		this.useWorkHS = config.systemPreferences.useProfileWorkHS;
		this.useInsurance = config.systemPreferences.useProfileInsurance;
		this.useOffices  = config.systemPreferences.useProfileOffices;
		this.useContact  = config.systemPreferences.useProfileContact;
	}

	ngOnInit() {
		const userConfig = this.config.config;
		this.profiles = [];
		if (this.useCredentials) this.profiles.push('Credentials');
		if (this.useRates) this.profiles.push('Rates');
		if (this.useChecks) this.profiles.push('Checks');
		if (this.useAttachments) this.profiles.push('Attachments');
		if (this.useNotifications) this.profiles.push('Notifications');
		if (this.useWorkHS) this.profiles.push('Work Health & Safety');
		if (this.useInsurance) this.profiles.push('Insurance');
		if (this.useOffices) this.profiles.push('Offices');
		if (this.useContact) this.profiles.push('Contact');
	}

	gotoProfile(e: Event, profile: string) {
		e.stopPropagation();
		
		switch (profile){
			case 'Credentials':
				this.router.navigate(['profile/credentials']);
				break;

			case 'Rates':
				this.router.navigate(['profile/rates']);
				break;

			case 'Checks':
				this.router.navigate(['profile/checks']);
				break;

			case 'Notifications':
				this.router.navigate(['profile/notifications']);
				break;

			case 'Attachments':
				this.router.navigate(['profile/attachments']);
				break;
				
			case 'Work Health & Safety':
				this.router.navigate(['profile/workhealthsafety']);
				break;

			case 'Insurance':
				this.router.navigate(['profile/insurance']);
				break;

			case 'Offices':
				this.router.navigate(['profile/offices']);
				break;
	
			case 'Contact':
				this.router.navigate(['profile/contact']);
				break;

			default:
		}
	}
}
