import { Injectable, ElementRef } from '@angular/core';
import * as moment from 'moment';

import { ScheduleComponent, RenderCellEventArgs } from '@syncfusion/ej2-angular-schedule';
import { closest, attributes, addClass, removeClass, createElement } from '@syncfusion/ej2-base';

import {
	IAppointmentEvent,
	Resource,
	SLOT_MINUTES,
} from 'app/common-editing/calendar-common/calendar-common.service';

@Injectable()
export class SyncfusionService {

	private readonly startTimeClass = 'e-start-time';
	private readonly workTimeClass = 'e-work-hours';
	private readonly techOnlyTimeClass = 'e-tech-only-time';

	private readonly dayStatusRecurring = 'e-header-show-recurring';
	private readonly dayStatusEdited = 'e-header-show-edited';
	private readonly dayStatusActivity = 'e-header-show-activity';

	private readonly startSelectedCell = 'e-selected-start-cell';
	private readonly endSelectedCell = 'e-selected-end-cell';

	private readonly fifteenMinutesInMilliseconds = 1000 * 60 * SLOT_MINUTES;

	showAvailability(
		ref: ElementRef,
		date: Date | moment.Moment,
		scheduler: ScheduleComponent,
		resources: Resource[],
		appointments: IAppointmentEvent[],
		showStatus: boolean = false,
		fnHasActivityType: any = () => false,
	) {
		this.reset(ref, scheduler);

		const times = this.init(appointments),
			working = this.getWorkTimes(date, resources, times.unavailable);

		this.setWorkingTimes(ref, scheduler, working, resources);
		this.setStartTimes(ref, times.start, resources);
		this.setTechTimes(ref, times.tech, resources);
		if (showStatus) {
			this.setDayStatus(ref, appointments, resources, fnHasActivityType);
		}
		/// ejs-schedule td.e-left-indent table,
		/// ejs-schedule div.e-time-cells-wrap table,
		/// table.e-recurrence-table.e-repeat-content-wrapper,
		/// table.e-recurrence-table.e-month-expand-wrapper

		const hoursTable: HTMLElement[] = ref.nativeElement.querySelectorAll(
			`ejs-schedule td.e-left-indent table,
			ejs-schedule div.e-time-cells-wrap table,
			table.e-recurrence-table.e-repeat-content-wrapper,
			table.e-recurrence-table.e-month-expand-wrapper`
		);
		hoursTable.forEach(x => attributes(x, { 'aria-hidden': 'true' }))
	}

	addTechTime(
		ref: ElementRef,
		groupIndex: number,
		start: Date | moment.Moment,
		end: Date | moment.Moment,
	) {
		const cells = this.getCellsForDates(ref, groupIndex, start, end);
		this.resetStateOfCell(cells);
		addClass(cells, this.techOnlyTimeClass);
		this.setAriaLabel(cells);
	}

	addAvailableTime(
		ref: ElementRef,
		groupIndex: number,
		start: Date | moment.Moment,
		end: Date | moment.Moment,
	) {
		const cells = this.getCellsForDates(ref, groupIndex, start, end);
		this.resetStateOfCell(cells);
		addClass(cells, this.workTimeClass);
		this.setAriaLabel(cells);
	}

	addUnavailableTime(
		ref: ElementRef,
		groupIndex: number,
		start: Date | moment.Moment,
		end: Date | moment.Moment,
	) {
		const cells = this.getCellsForDates(ref, groupIndex, start, end, { withClass: [this.techOnlyTimeClass, this.workTimeClass] });
		this.resetStateOfCell(cells);
		this.setAriaLabel(cells);
	}

	addStartTime(
		ref: ElementRef,
		groupIndex: number,
		dateTime: Date | moment.Moment,
	) {
		const cells = this.getCellsForDates(ref, groupIndex, dateTime, dateTime);
		this.resetStateOfCell(cells, true);
		addClass(cells, this.startTimeClass);
		this.setAriaLabel(cells);
	}

	clearStartTime(
		ref: ElementRef,
		groupIndex: number,
		dateTime: Date | moment.Moment,
	) {
		const cells = this.getCellsForDates(ref, groupIndex, dateTime, dateTime, { withClass: [this.startTimeClass] });
		this.resetStateOfCell(cells, true);
		addClass(cells, this.workTimeClass);
		this.setAriaLabel(cells);
	}

	setSelectedClasses(ref: ElementRef) {
		/// Set class for the first select cell
		/// Set class for all the cell # Already done by Syncfusion
		/// Set class for the last select cell


		// Unset classes set
		const oldStart = Array.from<HTMLElement>(ref.nativeElement.querySelectorAll(`.${this.startSelectedCell}`)),
			oldEnd = Array.from<HTMLElement>(ref.nativeElement.querySelectorAll(`.${this.endSelectedCell}`)),
			oldCell: HTMLElement[] = [];

		if (oldStart) {
			oldCell.push(...oldStart);
		}

		if (oldEnd) {
			oldCell.push(...oldEnd);
		}

		setTimeout(
			() => {
				const
					selectedCell = Array.from<HTMLElement>(ref.nativeElement.querySelectorAll('.e-work-cells.e-selected-cell')),
					selectedCellbyDate = selectedCell.reduce(
						(acc, elem) => {
							const unix = parseInt(elem.attributes['data-date'].value);
							acc[unix] = elem;
							return acc;
						},
						{} as { [key: number]: HTMLElement }
					),
					selectedDates = Object.keys(selectedCellbyDate).sort();

				if (selectedDates.length) {
					const
						startUnix = parseInt(selectedDates[0]),
						endUnix = parseInt(selectedDates[selectedDates.length - 1]),
						start = selectedCellbyDate[startUnix],
						end = selectedCellbyDate[endUnix];

					removeClass(
						oldCell,
						[this.startSelectedCell, this.endSelectedCell]
					);

					addClass([start], this.startSelectedCell);
					addClass([end], this.endSelectedCell);
				}
			},
		0);
	}

	utcInLocalTime(originalDate: Date | moment.Moment): moment.Moment {
		const
			og = moment(originalDate),
			ogOffset = og.utcOffset(),
			newDate = og.clone().utcOffset('Z', false).utcOffset(ogOffset, true);

		return newDate;
	}

	renderCell(args: RenderCellEventArgs) {
		if (args.elementType == 'workCells') {
			args.element.appendChild(
				createElement('div', {
					innerHTML: `
							<div style="display: none;" class="e-cell-available">
								<span class="cdk-visually-hidden">Available</span>
								<!-- <i class="fa fa-check-circle" aria-hidden="true"></i> -->
							</div>
							<div style="display: none;" class="e-cell-unavailable">
								<span class="cdk-visually-hidden">Unavailable</span>
								<!-- <i class="fa fa-ban" aria-hidden="true"></i> -->
							</div>
							<div style="display: none;" class="e-cell-start-Time">
								<span class="cdk-visually-hidden">Start Time</span>
								<!-- <i class="fa fa-tag" aria-hidden="true"></i> -->
							</div>
							<div style="display: none;" class="e-cell-technology-only">
								<span class="cdk-visually-hidden">Technology only</span>
								<!-- <i class="fa fa-laptop" aria-hidden="true"></i> -->
							</div>
						`,
					className: 'templatewrap'
				})
			);
		}
	}

	private init(appointments: IAppointmentEvent[] = []): {
		unavailable: IAppointmentEvent[],
		tech: IAppointmentEvent[],
		start: IAppointmentEvent[],
	} {
		return appointments.reduce(
			(acc, x) => {

				if (!x.isStartTime && !x.appointment && !x.isTechOnly) {
					acc.unavailable.push(x);
				} else if (x.isTechOnly) {
					acc.tech.push(x);
				} else if (x.isStartTime) {
					acc.start.push(x);
				}

				return acc;
			},
			{
				unavailable: [],
				tech: [],
				start: [],
			}
		)
	}

	private setWorkingTimes(ref: ElementRef, scheduler: ScheduleComponent, workings: IWorkTime, resources: Resource[]) {
		// to set availability of each resource we need there index not there id
		// so here a dictionary to get the index easily
		const resourceIndexDictionary = resources.reduce(
			(acc, x, index) => {
				acc[x.id] = index;
				return acc;
			},
			{}
		);

		for (let dateString in workings) {
			let
				date = moment(dateString).startOf('day').toDate(),
				day = workings[dateString];

			for (const resource in day) {
				for (const time of day[resource]) {
					if (resource in resourceIndexDictionary) {
						scheduler.setWorkHours([date], time.start, time.end, resourceIndexDictionary[resource]);
					}
				}
			}
		}

		const availableElements: HTMLElement[] = ref.nativeElement.querySelectorAll('.e-work-cells.e-work-hours'); // available
		const unavailableElements: HTMLElement[] = ref.nativeElement.querySelectorAll('.e-work-cells:not(.e-work-hours)'); // unavailable

		this.setAriaLabel([...availableElements, ...unavailableElements]);
	}

	private setTechTimes(ref: ElementRef, techs: IAppointmentEvent[], resources: Resource[]) {
		const
			cellByRessourceAndByDate = Array.from<HTMLElement>(ref.nativeElement.querySelectorAll('.e-work-cells'))
				.reduce(
					(acc, x: HTMLElement) => {
						const
							unixms = x.attributes['data-date'].value,
							resourceIndex = x.attributes['data-group-index'].value,
							resourceId = resources[resourceIndex]?.id;

						if (resourceIndex in resources) {
							if (!(resourceId in acc)) {
								acc[resourceId] = {}
							}
							acc[resourceId][unixms] = x;
						}

						return acc;
					},
					{} as { [resourceIndex: number]: { [date: number]: HTMLElement } }
				),
			techTimesCell = techs
				.reduce(
					(acc, x) => {

						const
							start = this.utcInLocalTime(x.start.local()).startOf('minute'),
							end = this.utcInLocalTime(x.end.local()).startOf('minute'),
							resourceId = x.resourceId

						/**
						 * Loop all 15 minutes between the start and the end,
						 * and get the cell found in the object with the unix in milliseconds
						 *
						 * instead of looping 600+ cell and looking if the date is between start and end
						 */
						for (var i = start.valueOf(); i < end.valueOf(); i += this.fifteenMinutesInMilliseconds) {
							if (resourceId in cellByRessourceAndByDate && i in cellByRessourceAndByDate[resourceId]) {
								acc.push(cellByRessourceAndByDate[resourceId][i]);
							}
						}

						return acc;
					},
					[] as HTMLElement[]
				);

		addClass(techTimesCell, this.techOnlyTimeClass);
		this.setAriaLabel(techTimesCell);
	}

	private setStartTimes(ref: ElementRef, start: IAppointmentEvent[], resources: Resource[]) {
		const
			resourceIndexById = resources.reduce(
				(acc, x, index) => {
					acc[x.id] = index;
					return acc;
				},
				{} as { [id: number]: number }
			),
			startTimeElements = start.reduce(
				(acc, x) => {
					const
						start = this.utcInLocalTime(x.start.local()).startOf('minute'),
						resourceIndex = resourceIndexById[x.resourceId],
						element = Array.from(ref.nativeElement.querySelectorAll(`.e-work-cells[data-date="${start.valueOf()}"][data-group-index="${resourceIndex}"]`));

					acc.push(...element);
					return acc;
				},
				[]
			);

		addClass(startTimeElements, this.startTimeClass);
		this.setAriaLabel(startTimeElements);
	}

	private reset(ref: ElementRef, scheduler: ScheduleComponent) {
		scheduler.resetWorkHours();

		const toRemoveClass = ref.nativeElement.querySelectorAll(`.${this.startTimeClass}, .${this.techOnlyTimeClass}`);
		removeClass(toRemoveClass, [this.startTimeClass, this.techOnlyTimeClass]);
	}

	private getWorkTimes(date: Date | moment.Moment, resources: Resource[], unavailable: IAppointmentEvent[]): IWorkTime {
		// We need to invert the availability because we currently track the time the user is off
		// but we need the time the user is working
		const
			newTimes: IWorkTime = {}, // the return working time
			currentWeekTime: { [date: string]: true } = {}, // current week dates

			StartDate = moment(date).startOf('week'),
			EndDate = moment(date).startOf('week').add(6, 'day'),
			dayNbr = EndDate.diff(StartDate, 'day'),
			resourcesById = resources.reduce(
				(acc, x) => {
					acc[x.id] = x;
					return acc;
				},
				{}
			)

		// Get current view date
		for (let d = 0; d <= dayNbr; d++) {
			let _date = moment(date).startOf('week').add(d, 'day').format('YYYY-MM-DD');
			if (!(_date in currentWeekTime)) {
				currentWeekTime[_date] = true;
			}
		}


		if (unavailable) {
			let times: { [date: string]: { [resource: number]: any[] } } = unavailable.reduce(
				(acc, x) => {
					const
						date = x.start.format('YYYY-MM-DD'),
						start = x.start.format('HH:mm'),
						end = x.end.format('HH:mm'),
						resourceId = parseInt(x.resourceId);

					if (!(date in acc)) {
						acc[date] = {};
					}

					if (!(resourceId in acc[date])) {
						acc[date][resourceId] = [];
					}


					acc[date][resourceId].push({ start, end, resourceId });

					return acc;
				},
				{}
			);
			for (const date in times) {
				let resourceTime = times[date];

				if (!(date in newTimes)) {
					newTimes[date] = {};
				}

				for (const resource in resourceTime) {
					let time = resourceTime[resource];

					if (!(resource in newTimes[date])) {
						newTimes[date][resource] = [];
					}

					const tmp = [];
					for (let ava of time) {
						if (ava.start != '00:00') {
							tmp.push(ava.start)
						}
						if (ava.end != '00:00') {
							tmp.push(ava.end)
						}
					}

					// if a time start from 00:00 to xx:xx
					// add back 00:00 at the start of the tmp array
					if (tmp.length % 2 === 1 && tmp.length > 0) {
						tmp.unshift('00:00');
					}

					// @TODO: we need to support this case `xx:xx to 24:00`
					// but it look like this range is not savable so I didn't do it

					for (var i = 0, len = 2; i < tmp.length; i += len) {
						const
							t = tmp.slice(i, i + len),
							[start, end] = t,

							workingHours = { start, end };

						newTimes[date][resource].push(workingHours);
					}
				}

			}

			// Look for day where no availability was fetch and assume they work all day
			for (let currentWeekDate in currentWeekTime) {
				for (const resource in resourcesById) {
					if (!(currentWeekDate in newTimes)) {
						newTimes[currentWeekDate] = {};
					}
					if (!(resource in newTimes[currentWeekDate])) {
						newTimes[currentWeekDate][resource] = [{ start: '00:00', end: '24:00' }];
					}
				}
			}
		}
		return newTimes;
	}

	private setDayStatus(ref: ElementRef, appointment: IAppointmentEvent[], resource: Resource[], fnHasActivityType: any = () => false) {
		const
			resourceIndexPerId = resource.reduce(
				(acc, x, index) => {
					acc[x.id] = index;
					return acc;
				},
				{}
			),
			statusPerDayPerResource = appointment.reduce(
				(acc, x) => {
					if (x.allDay && x.resourceId in resourceIndexPerId) {
						// use byte to store if its recurring or edited, and if its has an activity
						// 0b00
						// first byte => is recurring or is edited
						// seconde byte => has activity
						const
							start = this.utcInLocalTime(x.start.clone().local()).startOf('minute').valueOf(),
							resourceIndex = resourceIndexPerId[x.resourceId],
							isActivityType = parseInt(`${+!!fnHasActivityType(+x.resourceId, x.start)}0`, 2),
							recurringByte = parseInt(`0${+!!x.isRecurring}`, 2);

						if (!(start in acc)) {
							acc[start] = {};
						}

						if (!(resourceIndex in acc[start])) {
							acc[start][resourceIndex] = recurringByte;
						} else {
							acc[start][resourceIndex] = acc[start][resourceIndex] & recurringByte;
						}

						acc[start][resourceIndex] = acc[start][resourceIndex] | isActivityType;
					}
					return acc;
				},
				{}
			),
			htmlElement = {
				edited: [],
				recurring: [],
				activity: [],
			}
		/*
			Recurring or edited
		*/
		for (const date in statusPerDayPerResource) {
			for (const resourceIndex in statusPerDayPerResource[date]) {
				const elements = ref.nativeElement.querySelectorAll(`.e-header-cells[data-date="${date}"][data-group-index="${resourceIndex}"]`);

				// if first byte is 1 then its recurring
				// else its edited
				if ((statusPerDayPerResource[date][resourceIndex] & 0b01) === 0b01) {
					htmlElement.recurring.push(...elements)
				} else {
					htmlElement.edited.push(...elements)
				}

				// if seconde byte is 1 then its also has an activity
				if ((statusPerDayPerResource[date][resourceIndex] & 0b10) === 0b10) {
					htmlElement.activity.push(...elements);
				}
			}
		}

		addClass(htmlElement.activity, this.dayStatusActivity);
		addClass(htmlElement.edited, this.dayStatusEdited);
		addClass(htmlElement.recurring, this.dayStatusRecurring);
	}

	private setAriaLabel(elements: HTMLElement[]) {
		elements.forEach(x => {
			const date = moment(parseInt(x.attributes['data-date'].value));
			const label = Array.from(x.classList)
				.reduce(
					(acc, x) => {
						switch (x) {
							case this.workTimeClass:
								acc.push('available');
								break;
							case this.techOnlyTimeClass:
								acc.push('technology only');
								break;
							case this.startTimeClass:
								acc.push('start time');
								break;
						}
						return acc;
					},
					[] as string[]
				);
			attributes(x, { 'aria-label': `${date.format('dddd, MMMM Do YYYY, h:mm:ss a')}: ${label.length ? label.join(', ') : 'unavailable'}` })
		});
	}

	private getCellsForDates(
		ref: ElementRef,
		groupIndex: number,
		start: Date | moment.Moment,
		end: Date | moment.Moment,
		options: {
			notWithClass?: string[];
			withClass?: string[];
		} = {},
		convertDateTimeLocalToUTC: boolean = false
	) {
		const
			{
				notWithClass = [],
				withClass = []
			} = options,
			baseClass = '.e-work-cells',
			baseAttribute = `[data-group-index="${groupIndex}"]`,
			notClasses = notWithClass.map(x => `:not(.${x})`)

		let selector = `${baseClass}${baseAttribute}${notClasses}`;
		if (withClass.length) {
			selector = withClass.map(x => `${baseClass}.${x}${baseAttribute}${notClasses}`).join(', ')
		}

		const
			cellsForResourceByDate = Array.from<HTMLElement>(ref.nativeElement.querySelectorAll(selector))
				.reduce(
					(acc, x: HTMLElement) => {
						const unixms = x.attributes['data-date'].value;
						acc[unixms] = x;
						return acc;
					},
					{} as { [date: number]: HTMLElement }
				),
			cells = [{ start: moment(start), end: moment(end) }]
				.reduce(
					(acc, x) => {

						const
							start = convertDateTimeLocalToUTC ? this.utcInLocalTime(x.start.local()).startOf('minute') : x.start.startOf('minute'),
							end = convertDateTimeLocalToUTC ? this.utcInLocalTime(x.end.local()).startOf('minute') : x.end.startOf('minute');

						/**
						 * Loop all 15 minutes between the start and the end,
						 * and get the cell found in the object with the unix in milliseconds
						 *
						 * instead of looping 600+ cell and looking if the date is between start and end
						 */
						if (start.valueOf() !== end.valueOf()) {
							for (var i = start.valueOf(); i < end.valueOf(); i += this.fifteenMinutesInMilliseconds) {
								if (i in cellsForResourceByDate) {
									acc.push(cellsForResourceByDate[i]);
								}
							}
						} else {
							acc.push(cellsForResourceByDate[start.valueOf()]);
						}

						return acc;
					},
					[] as HTMLElement[]
				);

		console.log(selector, cells)
		return cells;
	}

	private resetStateOfCell(cells: HTMLElement[], clearStartTime: boolean = false) {
		if (clearStartTime) {
			removeClass(cells, [this.startTimeClass]);
		} else {
			removeClass(cells, [this.techOnlyTimeClass, this.workTimeClass]);
		}
	}
}


export interface IWorkTime {
	[date: string]: {
		[resource: number]: {
			start: string;
			end: string;
		}[]
	}
}
