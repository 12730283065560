<mat-card>
	<mat-card-title>
		<h1 class="page-header">Dashboard</h1>
	</mat-card-title>

	<div fxLayout="column" fxLayout.gt-lg="row" fxLayoutGap="8px" fxLayoutGap.xs="4px" class="component-container">
		<pp-dashboard-appointments class="mat-elevation-z6" fxFlexOrder.lt-lg="2" fxFlex fxFlex.lt-lg="100"></pp-dashboard-appointments>

		<div
			fxLayout="row"
			fxLayout.gt-lg="column"
			fxFlexOrder.lt-lg="1"
			fxLayoutAlign="start stretch"
			fxLayoutGap.xs="4px"
			fxLayoutGap="8px">
			<div fxLayout="row" fxFlex="50" fxFlex.gt-lg="200px" class="mat-elevation-z6">
				<pp-active-cases fxFlex fxFlex.gt-lg="400px"></pp-active-cases>
			</div>
			<div fxLayout="row" fxFlex="50" fxFlex.gt-lg="200px" class="mat-elevation-z6">
				<pp-active-invoices *ngIf="!isEmployee" fxFlex fxFlex.gt-lg="400px"></pp-active-invoices>
			</div>
		</div>
	</div>
</mat-card>
