import { Component, OnChanges, AfterViewInit, Input, forwardRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import * as _ from 'lodash';

import { BaseComponent } from './base-component';

let identifier = 0;

class SelectItem {
	label: string;
	value: any;
}

@Component({
	selector: 'pp-select',
	template: `
		<div class="form-component">
			<div fxLayout="row">
				<mat-form-field fxFlex>
					<mat-select
						fxFlex
						#inputElement
						[required]="required"
						[ngStyle]="{ 'width.px': width }"
						[id]="identifier"
						[placeholder]="placeholder"
						[disabled]="isDisabled"
						[(ngModel)]="value"
						(keydown.delete)="onKeyDelete($event)"
						(selectionChange)="onSelectionChange($event)"
						(blur)="onBlur()"
					>
						<mat-option aria-role="option" *ngFor="let opt of selectOptions" [value]="opt.value">
							{{ opt.label }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="validation-message" *ngIf="control?.touched && control.hasError('required')">
				{{ label }} is required
			</div>
		</div>
	`,
	styleUrls: ['./select.component.scss'],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectComponent), multi: true },
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => SelectComponent), multi: true }
	]
})
export class SelectComponent extends BaseComponent<any> implements OnChanges, AfterViewInit {
	private labelOpacity = 1;
	private _options: SelectItem[];

	@Input() label: string;
	@Input() options: any[];
	@Input() labelKey = 'name';
	@Input() placeholder = 'Select...';
	@Input() dataKey = 'id';
	@Input() width: number;
	@Input() nullable = true;
	@Input() getLabel: Function;
	@Input() selectionChange: Function;

	public identifier = `select-${identifier++}`;

	@ViewChild(MatSelect, { static: true }) select: MatSelect;

	get selectOptions() {
		return this._options;
	}

	ngAfterViewInit() {
		if (this.required) {
			this.select.required = true;
		}
	}

	onBlur() {
		this.touch();
	}

	onKeyDelete(e) {
		if (this.nullable) {
			this.value = undefined;
		}
	}

	onSelectionChange($event){
		if (this.selectionChange) {
			this.selectionChange($event);
		}
	}

	ngOnChanges(inputs) {
		if (inputs.placeholder) {
			if (this.placeholder === undefined) {
				this.placeholder = this.label || '';
			}
		}

		if (inputs.options) {
			if (typeof this.options === 'string') {
				throw new Error(`Invalid options - should be [] "${this.options}"`);
			}

			if (this.getLabel) {
				this._options = (this.options || []).map(o => {
					return <SelectItem>{
						label: this.getLabel.apply(o),
						value: o[this.dataKey]
					};
				});
			} else {
				this._options = (this.options || []).map(o => {
					return <SelectItem>{
						label: o[this.labelKey],
						value: o[this.dataKey]
					};
				});
			}
		}

		if (inputs.label) {
			if (!this.label) {
				this.label = 'X';
				this.labelOpacity = 0;
			}
		}
	}

	setFocus() {
		setTimeout(() => {
			this.select.focus();
		});
	}
}
