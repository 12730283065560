<form novalidate autocomplete="off" [formGroup]="form">
	<div fxLayout="column">
		<div fxLayout="row">
			<pp-select 
				fxFlex
				fxFlex.gt-sm="400px"
				formControlName="organisationGroupId" 
				[options]="groups" 
				label="Group" 
				placeholder="Group">
			</pp-select>
		</div>

		<div fxLayout="row">
			<pp-select fxFlexOffset="20px"
				fxFlex
				fxFlex.gt-sm="380px"
				formControlName="organisationDivisionId" 
				[options]="divisions" 
				label="Division" 
				placeholder="Division">
			</pp-select>
		</div>

		<div fxLayout="row">
			<pp-select fxFlexOffset="40px"
				fxFlex
				fxFlex.gt-sm="360px"
				formControlName="organisationDepartmentId" 
				[options]="departments"
				label="Department"
				placeholder="Department">
			</pp-select>
		</div>
	</div>
</form>
