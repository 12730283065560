import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'app/services/base-service';
import { CommonService, AdditionalSessionRequestReasonsDto } from 'app/services';

@Injectable()
export class AdditionalSessionEditService extends BaseService {
	
	constructor(common: CommonService) {
		super(common);
	}

	getAdditionalSessionRequestReasons(): Observable<AdditionalSessionRequestReasonsDto> {
		return this.crud.loadWithGet<AdditionalSessionRequestReasonsDto>('api/additionalSessionRequestReasons');
	}
	
}
