<form novalidate autocomplete="off" [formGroup]="form">
	<div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
		<div fxFlex>{{ choice.description }}</div>
		<pp-text 
			fxFlex="100px"
			[numericOnly]="true"
			[decimalPlaces]="0"
			[allowNegative]="false"
			label=""
			formControlName="int">
		</pp-text>
		<pp-text 
			fxFlex="100px"
			[numericOnly]="true"
			[decimalPlaces]="0"
			[allowNegative]="false"
			label=""
			formControlName="intExtra1">
		</pp-text>
	</div>
</form>