<h1 mat-dialog-title>
	{{ title }}
</h1>

<div mat-dialog-content>
	<p>{{ message }}</p>
</div>

<div mat-dialog-actions align="end">
	<button mat-raised-button color="primary" (click)="onConfirm()">Yes</button>
	<button mat-button color="warn" (click)="onDismiss()">No</button>
</div>
