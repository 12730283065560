<form novalidate autocomplete="off" [formGroup]="form">
	<div fxLayout="row" *ngIf="!dto.requestId">
		<div
			id="new-request-save-reminder"
			fxFlex
			fxFlex.gt-sm="50"
			fxLayout="row"
			fxLayoutAlign="start center"
			fxLayoutGap="5px"
		>
			<mat-icon>warning</mat-icon>
			<div>Your request will not be submitted until you save.</div>
		</div>
	</div>

	<div *ngIf="dto.approvalId" fxLayout="row">
		<mat-icon fxHide.gt-sm="true" class="additional-session-status approved">thumb_up</mat-icon>
		<h3>Extra approved sessions</h3>
	</div>
	<div
		*ngIf="dto.approvalId"
		fxLayout="column"
		fxLayout.gt-sm="row"
		fxLayoutGap="8px"
		fxLayoutGap.gt-sm="16px"
		id="approval-details"
	>
		<div fxLayout="column" fxFlex="noshrink" fxFlex.gt-sm="120px">
			<pp-display label="Per client" [value]="dto.approvedNumberPerClient"></pp-display>
		</div>

		<div fxLayout="column" fxFlex="noshrink" fxFlex.gt-sm="flex">
			<pp-display label="Approved date">{{ dto.dateApproved | date }}</pp-display>
			<pp-display label="Approved by">{{ dto.approvedBy }}</pp-display>
			<pp-display label="Notes">
				<div style="overflow: auto;" class="notes" [innerHtml]="approvalNotesHtml"></div>
			</pp-display>
		</div>
	</div>

	<div *ngIf="dto.declineId" fxLayout="row">
		<mat-icon fxHide.gt-sm="true" class="additional-session-status declined">thumb_down</mat-icon>
		<h3>Request declined</h3>
	</div>
	<div *ngIf="dto.declineId" fxLayout="column" fxLayout.gt-sm="row" id="decline-details">
		<div fxLayout="column" fxFlex="noshrink" fxFlex.gt-sm="flex">
			<pp-display label="Declined date">{{ dto.dateDeclined | date }}</pp-display>
			<pp-display label="Declined by">{{ dto.declinedBy }}</pp-display>
			<pp-display label="Notes">
				<div style="overflow: auto;" class="notes" [innerHtml]="declineNotesHtml"></div>
			</pp-display>
		</div>
	</div>

	<div fxLayout="row">
		<mat-icon
			fxHide.gt-sm="true"
			*ngIf="!dto.approvalId && !dto.declineId"
			class="additional-session-status unknown"
			>schedule</mat-icon
		>
		<h3>Request</h3>
	</div>
	<div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="8px" fxLayoutGap.gt-sm="16px" id="edit">
		<div fxLayout="column" fxFlex="noshrink" fxFlex.gt-sm="50">
			<div fxLayout="row" fxLayoutGap="16px">
				<pp-display
					label="Date of request"
					[value]="dto.dateOfRequest | date"
					fxFlex
					fxFlex.gt-xs="120px"
				></pp-display>
				<pp-text
					label="Number requested"
					numericOnly="true"
					fxFlex="90px"
					fxFlex.gt-xs="140px"
					formControlName="requestedNumberPerClient"
					#requested
				></pp-text>
				<pp-button
					id="delete-button"
					*ngIf="!dto.requestId"
					label="Delete"
					(clicked)="deleteRequest()"
				></pp-button>
			</div>

			<pp-select
				*ngIf="requestedByIsProfessional"
				[options]="professionals"
				label="Requested by"
				placeholder="Requested by"
				[required]="true"
				formControlName="requestedByBusinessRoleId"
			>
			</pp-select>
			<pp-display
				*ngIf="!requestedByIsProfessional"
				label="Requested by"
				[value]="requestedByNonProfessionalName"
			></pp-display>

			<pp-select
				*ngIf="isReasonCombo"
				placeholder="Primary concern and reason for request"
				[options]="reasons"
				[selectionChange]="reasonSelection"
			>
			</pp-select>

			<pp-textarea
				*ngIf="isReasonManual"
				label="Primary concern and reason for request"
				formControlName="primaryConcernAndReason"
			></pp-textarea>
			<pp-textarea
				label="Goals to be achieved (how to resolve the client's issues)"
				formControlName="issueResolutionAndGoal"
			></pp-textarea>
			<pp-textarea
				label="Will the client be referred to another service (and/or other notes)"
				formControlName="notes"
			></pp-textarea>
		</div>

		<div *ngIf="!useCustomPart2" fxLayout="column" fxFlex="noshrink" fxFlex.gt-sm="50">
			<pp-radio-group
				label="Client at work"
				labelHorizontal="true"
				formControlName="isClientWorking"
				[options]="triStateOptions"
			></pp-radio-group>
			<pp-radio-group
				*ngIf="!hideRehabCase"
				label="Rehabilitation case"
				labelHorizontal="true"
				formControlName="isRehabilitationCase"
				[options]="triStateOptions"
			></pp-radio-group>
			<pp-radio-group
				*ngIf="!hideNewIssue"
				label="New issue"
				labelHorizontal="true"
				formControlName="isNewIssue"
				[options]="triStateOptions"
			></pp-radio-group>
			<pp-radio-group
				*ngIf="!hideWorkIssue"
				label="Work issue"
				labelHorizontal="true"
				formControlName="isWorkRelatedIssue"
				[options]="triStateOptions"
			></pp-radio-group>
			<pp-radio-group
				label="Client has consented to discuss with org."
				labelHorizontal="true"
				formControlName="isClientConsentToOrgDiscussion"
				[options]="triStateOptions"
			></pp-radio-group>
			<pp-radio-group
				label="Client has consented to release name to org."
				labelHorizontal="true"
				formControlName="isClientConsentToReleaseName"
				[options]="triStateOptions"
			></pp-radio-group>
			<pp-radio-group
				label="Supported by organisation person"
				labelHorizontal="true"
				formControlName="isSupported"
				[options]="triStateOptions"
			></pp-radio-group>

			<div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px">
				<pp-text label="Role" formControlName="supportedByRole" fxFlex fxFlex.gt-sm="50"></pp-text>
				<pp-text label="Name" formControlName="supportedByName" fxFlex fxFlex.gt-sm="50"></pp-text>
			</div>
		</div>

		<div *ngIf="useCustomPart2" fxLayout="column" fxFlex="noshrink" fxFlex.gt-sm="50">
			<pp-radio-group
				label="New issue"
				labelHorizontal="true"
				formControlName="isNewIssue"
				[options]="triStateOptions"
			></pp-radio-group>
			<pp-radio-group
				label="Are there legal issues?"
				labelHorizontal="true"
				formControlName="isLegalIssue"
				[options]="triStateOptions"
			></pp-radio-group>
			<pp-radio-group
				label="Has a manager or HR person recommended extra sessions?"
				labelHorizontal="true"
				formControlName="isHROrManagerRecommended"
				[options]="triStateOptions"
			></pp-radio-group>
			<pp-radio-group
				label="Does the client already have another external Mental Health professional?"
				labelHorizontal="true"
				formControlName="isClientUsingOtherExternalProfessional"
				[options]="triStateOptions"
			></pp-radio-group>
			<pp-radio-group
				label="Do you need help developing a plan to support this client?"
				labelHorizontal="true"
				formControlName="isHelpRequiredDevelopingClientSupportPlan"
				[options]="triStateOptions"
			></pp-radio-group>
		</div>
	</div>
</form>
