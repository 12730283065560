import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
	MatAutocompleteSelectedEvent,
	MatAutocomplete
} from '@angular/material/autocomplete';
import * as _ from 'lodash';
import {
	StatisticContainerDto,
	StatisticalTypeDto,
	StatisticalChoiceDto,
	StatisticDto,
} from 'app/services';

@Component({
	selector: 'pp-chips-picker',
	templateUrl: './chips-picker.component.html',
	styleUrls: ['./chips-picker.component.scss']
})
export class ChipsPickerComponent implements OnInit, OnChanges {
	statistics: StatisticDto[];
	filteredChoices: StatisticalChoiceDto[];
	filteredOptions: StatisticalChoiceDto[];

	@Input() container: StatisticContainerDto;
	@Input() type: StatisticalTypeDto;
	@Input() canAdd: boolean;
	@Output() changed = new EventEmitter<StatisticDto>();

	@ViewChild('chipInput', { static: true }) inputEl: ElementRef;
	@ViewChild('auto', { static: true }) autoComplete: MatAutocomplete;

	removable: boolean = true;

	autoCompleteChipList: UntypedFormControl;

	ngOnInit() {
		this.autoCompleteChipList = new UntypedFormControl();
		this.autoCompleteChipList.valueChanges.subscribe(val => {
			this.filterOptions(val);
		});

		this.statistics = this.container.statistics
			.filter(s => s.statisticalTypeId === this.type.id && !s.isEmpty);
		this.updateFilteredChoices();
	}

	ngOnChanges(changes) {
		if (changes.canAdd) {
			if (!this.canAdd) {
				this.updateFilteredChoices();
			}

			if (this.autoCompleteChipList) {
				if (this.canAdd) {
					this.autoCompleteChipList.enable();
				} else {
					this.autoCompleteChipList.disable();
				}
			}
		}
	}

	focused() {
		this.autoComplete.showPanel = true;
	}

	getChoice(statistic: StatisticDto): StatisticalChoiceDto {
		const result = this.type.statisticalChoices.find(c => c.id === statistic.statisticalChoiceId);
		return result;
	}

	itemAdd(type: StatisticalTypeDto, event: MatAutocompleteSelectedEvent, input: any) {
		if (!this.canAdd) {
			return;
		}

		let choice: StatisticalChoiceDto = event.option.value;

		const statistic = <StatisticDto>{
			statisticalChoiceId: choice.id,
			statisticalTypeId: choice.statisticalTypeId,
		};

		this.statistics.push(statistic);
		const containerStats = this.container.statistics;
		containerStats.push(statistic);

		this.changed.emit(statistic);

		if (input) {
			input.value = '';
		}

		this.afterAddRemove();
	}

	itemRemove(type: StatisticalTypeDto, statistic: StatisticDto) {
		const containerStats = this.container.statistics;
		let index = containerStats.indexOf(statistic);
		containerStats.splice(index, 1);

		index = this.statistics.indexOf(statistic);
		this.statistics.splice(index, 1);

		this.changed.emit(statistic);
		this.afterAddRemove();
	}

	private afterAddRemove() {
		this.updateFilteredChoices();
		this.inputEl.nativeElement.focus();
	}

	private updateFilteredChoices() {
		if (!this.statistics) {
			return;
		}
		const choiceIDs = this.statistics.map(s => s.statisticalChoiceId);
		const choices = this.type.statisticalChoices;
		let filtered = choices;

		// Exclude choices already selected.
		this.filteredChoices = filtered.filter(c => choiceIDs.indexOf(c.id) === -1);
		this.filterOptions('');
	}

	private filterOptions(text) {
		let searchText = (text || '').toString().toLowerCase();
		this.filteredOptions = this.filteredChoices
			.filter(obj => obj.description.toLowerCase().indexOf(searchText) !== -1);
	}
}
