import { Pipe, PipeTransform } from '@angular/core';
import { AppointmentRendererService } from 'app/appointments/appointment-renderer.service';
import { CommAddressDisplayDto } from 'app/services';

@Pipe({
	name: 'appointmentPhone'
})
export class AppointmentPhonePipe implements PipeTransform {

	constructor(private renderer: AppointmentRendererService) { }

	transform(value: CommAddressDisplayDto): string {
		return this.renderer.formatPhoneNumber(value);
	}

}
