<div fxLayout="column">
	<div fxLayout="row" fxLayoutAlign="start center">
		<h2>Search</h2>
		<span fxFlex></span>
		<button
			mat-icon-button
			matTooltip="Close"
			matDialogClose="cancel"
			tabindex="-1"
		>
			<mat-icon>close</mat-icon>
		</button>
	</div>
	
	<!-- Delete Me: Not working, repalced with code below
	<mat-tab-group
		[selectedIndex]="selectedTabIndex"
		(selectedTabChange)="onSelectedTabChange($event)"
		[animationDuration]="200"
		>
		<mat-tab label="Cases">
			<ng-template matTabContent>
				<pp-case-search
				#cases
				(resultSelected)="resultSelected()"
				></pp-case-search>
			</ng-template>
		</mat-tab>
		
		<mat-tab label="Invoices" *ngIf="!isEmployee">
			<ng-template matTabContent>
				<pp-invoice-search
				#invoices
				(resultSelected)="resultSelected()"
				></pp-invoice-search>
			</ng-template>
		</mat-tab>
		
	</mat-tab-group>
	-->

	<nav mat-tab-nav-bar>
		<a (click)="selectCases()" mat-tab-link [active]="isCases">Cases</a>
		<a *ngIf="!isEmployee" (click)="selectInvoices()" mat-tab-link [active]="isInvoices">Invoices</a>
	</nav>

	<div fxFlex>
		<pp-case-search #cases [fxHide]="!isCases" (resultSelected)="resultSelected()"></pp-case-search>
		<pp-invoice-search #invoices [fxHide]="!isInvoices" (resultSelected)="resultSelected()"></pp-invoice-search>
	</div>
</div>
