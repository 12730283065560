import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { UntypedFormGroup } from '@angular/forms';

import { UtilsService } from 'app/services';

@Component({
	selector: 'pp-search-filter',
	templateUrl: './search-filter.component.html',
	styleUrls: ['./search-filter.component.scss'],
})
export class SearchFilterComponent implements OnInit, AfterViewInit {
	private wasExpanded: boolean;
	private expanded: boolean;

	// Template properties
	filterDescription: string;
	showingAll: boolean;

	@Input() form: UntypedFormGroup;
	@Input() hideOpenOnly = false;
	@Input() hideShowAll = false;
	@Input() openLabel = 'Open only';
	@Input() filterTextDatePrefix = '';
	@ViewChild('panel', { static: true }) expansionPanel: MatExpansionPanel;

	constructor(private utils: UtilsService) { }
	// Workaround for angular component issue #13870
	disableAnimation = true;
	ngAfterViewInit(): void {
		// timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
		setTimeout(() => (this.disableAnimation = false));
	}

	ngOnInit() {
		this.buildFilterDescription();
	}

	clearFilter() {
		this.form.patchValue({
			dateFrom: null,
			dateTo: null,
			openOnly: false,
			showAll: false,
		});
		this.closeFilter();
	}

	showAllChanged(ev) {
		// Retain the previous status of the panel when the Show all is changed.
		if (this.wasExpanded) {
			this.openFilter();
		} else {
			this.closeFilter();
		}

		const showAllState = this.form.controls.showAll.value;
		if (showAllState) {
			this.form.controls.dateFrom.disable();
			this.form.controls.dateTo.disable();
			this.form.controls.openOnly.disable();

			this.form.patchValue({
				openOnly: false,
			});
		} else {
			this.form.controls.dateFrom.enable();
			this.form.controls.dateTo.enable();
			this.form.controls.openOnly.enable();
		}

		this.showingAll = showAllState;
		this.buildFilterDescription();
	}

	panelOpened() {
		this.wasExpanded = this.expanded;
		this.expanded = true;
	}

	panelClosed() {
		this.wasExpanded = this.expanded;
		this.expanded = false;
		this.buildFilterDescription();
	}

	closeFilter() {
		this.expansionPanel.close();
	}

	openFilter() {
		this.expansionPanel.open();
	}

	private buildFilterDescription() {
		const formVal = this.form.value;
		let desc = '';

		const descParts = [];

		if (!this.hideOpenOnly && formVal.openOnly) {
			descParts.push(this.openLabel);
		}

		let formattedDateFrom = '';
		let formattedDateTo = '';
		let datePrefix = this.filterTextDatePrefix;
		if (datePrefix) {
			datePrefix += ' ';
		}
		if (formVal.dateFrom) {
			formattedDateFrom = this.utils.formatDate(formVal.dateFrom);
		}

		if (formVal.dateTo) {
			formattedDateTo = this.utils.formatDate(formVal.dateTo);
		}

		if (formattedDateFrom && formattedDateTo) {
			descParts.push(`${datePrefix} Date between ${formattedDateFrom} and ${formattedDateTo}`);
		} else if (formattedDateFrom) {
			descParts.push(`${datePrefix} Date from ${formattedDateFrom}`);
		} else if (formattedDateTo) {
			descParts.push(`${datePrefix} Date to ${formattedDateTo}`);
		}

		if (descParts.length) {
			desc += descParts.join(', ');
		}

		this.filterDescription = desc;
	}
}
