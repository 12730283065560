<div fxLayout="column">
	<div *ngFor="let item of this.extendedDetails.extendedDetailsItems" fxLayout="row">
		<div fxLayout="row" fxFlex class="item" fxLayoutAlign="start center">
			<div *ngIf="item.displayImage" class="flag-container">
				<i class="fa fa-flag" [ngStyle]="{'color':redFlagColour}"></i>
				<i class="fa fa-flag-o"></i>
			</div>
			<span fxFlex class="text"> {{ item.text }}</span>
			<button fxFlex="50px" class="item-yes" [ngClass]="{active: item.value }" (click)="toggleItemState(item)">Yes</button>
			<button fxFlex="50px" class="item-no" [ngClass]="{active: !item.value }" (click)="toggleItemState(item)">No</button>
		</div>
	</div>
</div>