import { UntypedFormGroup, UntypedFormControl,  } from '@angular/forms';
import { IForm } from 'app/interfaces';

const DISABLED = 'DISABLED';

export class UpdaterOptions {
	static NULL_IF_MISSING: UpdaterOptions = {
		nullIfMissing: true
	};
	static AS_NEW: UpdaterOptions = {
		asNew: true
	}

	nullIfMissing?: boolean;
	asNew?: boolean;
}

export class FormUtils {
	static applyChanges(form: UntypedFormGroup, obj: Object, options: UpdaterOptions = undefined) {
		const formValue = form.value;

		if (!obj) {
			return;
		}

		for (const controlProp in form.controls) {
			if (form.controls.hasOwnProperty(controlProp)) {
				const control = form.controls[controlProp] as UntypedFormControl;
				if (obj.hasOwnProperty(controlProp) || (options && options.asNew)) {
					if (options && options.nullIfMissing && !formValue.hasOwnProperty(controlProp)) {
						obj[controlProp] = undefined;
						continue;
					}
					obj[controlProp] = control.value;
				}
			}
		}
	}

	static hasChanges(formHolder: IForm | UntypedFormGroup) {
		let form;
		if (formHolder instanceof UntypedFormGroup) {
			form = formHolder as UntypedFormGroup;
		} else {
			form = (formHolder as IForm).getForm();
			if (typeof formHolder.hasChanges === 'function') {
				return formHolder.hasChanges();
			}
		}

		return form.dirty;
	}

	static updateObject(formHolder: IForm, obj) {
		const form = formHolder.getForm();
		if (typeof formHolder.applyChanges === 'function') {
			formHolder.applyChanges(obj);
		} else {
			FormUtils.applyChanges(form, obj);
		}
	}

	/**
	 * Touches all controls on a form (recursively) and returns whether the form is valid.
	 * Touching the controls will cause their validation message to be shown if they're invalid.
	 * @static
	 * @param {IForm | FormGroup} formHolder The FormGroup or IForm formHolder
	 * @returns {boolean} True if the form is valid.
	 * @memberof Updater
	 */
	static isFormValid(formHolder: IForm | UntypedFormGroup): boolean {
		let form;
		if (formHolder instanceof UntypedFormGroup) {
			form = formHolder as UntypedFormGroup;
		} else {
			form = (formHolder as IForm).getForm();
		}

		FormUtils.markFormGroupTouched(form);
		const isValid = form.valid || form.status === DISABLED;
		if (!isValid) {
			// console.log('Invalid form', form);
		}
		return isValid;
	}

	/**
	 * Recursively touch all controls on a form.
	 * @private
	 * @static
	 * @param {FormGroup} formGroup The form.
	 * @memberof Updater
	 */
	private static markFormGroupTouched(formGroup: UntypedFormGroup) {
		(<any>Object).values(formGroup.controls).forEach(control => {
			control.markAsTouched();

			if (control.controls) {
				control.controls.forEach(c => this.markFormGroupTouched(c));
			}
		});
	}
}
