<mat-sidenav-container [ngClass.lt-md]="{ 'lt-md': true }">
	<mat-sidenav (openedChange)="sidenavOpenedChanged($event)" [opened]="sidenavOpened" [mode]="sidenavMode" #sideNav>
		<div fxLayout="column" fxFlex>
			<div class="sidebar-title">
				<img id="side-nav-icon" src="\assets\icons\eap-exec-icon.svg" alt="EAP Professional Portal" />
				<span class="title">
					{{ title }}
					<!--　@TODO: do we still need this string. From this commit: https://github.com/ExecutiveSoftware/EapCore/commit/d22e87a8a84a8298c753f998235717c829cc9052 -->
					{{ 'HELLO' | translate: param }}
				</span>
			</div>

			<div id="menu-container" fxFlex fxLayout="column">
				<div class="username">{{ username }}</div>
				<div class="separator"></div>

				<mat-list fxLayout="column" id="sidenav-content" fxFlex>
					<nav>
						<a
							*ngFor="let item of routes"
							mat-list-item
							[routerLink]="[item.route]"
							[routerLinkActive]="['active']"
							ariaCurrentWhenActive="page"
							(click)="!media.isActive('gt-md') && sideNav.close()">
							<mat-icon>{{ item.icon }}</mat-icon>
							<span class="title">
								{{ item.title }}
							</span>
						</a>
					</nav>

					<!--
					<a mat-list-item (click)="testError()">
							<mat-icon>exit_to_app</mat-icon>Test error</a>
					-->
					<pp-profile-navigator *ngIf="useProfile"></pp-profile-navigator>

					<div *ngIf="!(auth.isPracticeMode$ | async)" fxFlex></div>

					<pp-practice-navigator *ngIf="auth.isPracticeMode$ | async" fxFlex></pp-practice-navigator>

					<button mat-list-item (click)="logout()" class="logout" fxFlex="none">
						<mat-icon>exit_to_app</mat-icon>Log out
					</button>

					<div class="separator"></div>

					<span class="version">Version {{ version }} (API: {{ serverVersion }})</span>
					<img class="eap-logo" src="\assets\icons\eap-executive-rgb.svg" alt="Executive Software logo" />
					<span *ngIf="showCopyright" class="mat-caption copyright">Copyright &copy; 2018 Executive Software</span>
				</mat-list>
			</div>
		</div>
	</mat-sidenav>

	<div class="layout-nav" fxFlex="noshrink">
		<div class="layout-nav-wrapper" fxLayout="column">
			<mat-toolbar
				color="primary"
				fxLayout="row"
				fxFlex="none"
				fxLayoutAlign="center center"
				fxLayoutGap="16px"
				[ngStyle]="toolbarStyle">
				<button mat-icon-button [attr.aria-expanded]="sidenavOpened" aria-label="Open/close side navigation" matTooltip="Open/close side navigation" (click)="toggleSidenav()">
					<mat-icon>menu</mat-icon>
				</button>

				<a tabindex="0" class="tabulate link" [routerLink]="['/dashboard']">
					{{ title }}
				</a>
				<span flex></span>

				<div *ngIf="showAnnouncements" (click)="openAnnouncements($event)">
					<button class="announcements" matTooltip="Announcements" [ngStyle]="getAnnouncementStyle()">
						<mat-icon>notifications</mat-icon>
					</button>
					<div *ngIf="unread > 0" class="announcement-unread" [ngClass]="{'announcement-unreadx': unread >= 10 }">{{getAnnouncementUnread()}}</div>
				</div>
				
				<div class="search" #search>
					<button mat-mini-fab aria-label="Search" matTooltip="Search" (click)="showSearch()">
						<mat-icon>search</mat-icon>
					</button>
				</div>

				<button [fxHide.xs]="true" mat-raised-button (click)="logout()" matTooltip="Logout" color="accent">Logout</button>

				<pp-lang-selector></pp-lang-selector>
			</mat-toolbar>

			<div class="outlet-relative">
				<main class="outlet">
					<router-outlet id="main-router-outlet"></router-outlet>
				</main>
			</div>
		</div>
	</div>
</mat-sidenav-container>
