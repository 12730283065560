import * as JsonPackage from '../../package.json';
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

console.log(JsonPackage);
export const environment = {
	production: false,
	hmr: true,
	PACKAGE: 'default' in JsonPackage ? (<any>JsonPackage).default : JsonPackage,
};
