import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
	StatisticalTypeDto,
	StatisticDto,
} from 'app/services';
import { StatisticEditorBase } from '../statistic-editor-base';

@Component({
	selector: 'pp-editor-picklist',
	templateUrl: './editor-picklist.component.html',
	styleUrls: ['./editor-picklist.component.scss']
})
export class EditorPicklistComponent extends StatisticEditorBase implements OnInit {
	form: UntypedFormGroup;

	constructor(
		private fb: UntypedFormBuilder,
	) {
		super();
	}

	ngOnInit() {
		this.buildForm();

		this.form.patchValue({
			statisticalChoiceId: this.statistic.statisticalChoiceId
		});

		this.form.controls.statisticalChoiceId.valueChanges.subscribe(id => {
			this.statistic.statisticalChoiceId = id;
			this.emitChange();
		});
	}

	private buildForm() {
		this.form = this.fb.group({
			statisticalChoiceId: undefined
		});

		if (this.type.isRequired) {
			this.form.controls.statisticalChoiceId.setValidators(Validators.required);
		}
	}
}
