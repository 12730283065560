import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageDialogModel } from '../services/message-dialog.model';

@Component({
	selector: 'pp-message-dialog',
	templateUrl: './message-dialog.component.html',
	styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent {
	title: string;
	message: string;
	subMessage: string;

	constructor(
		public dialogRef: MatDialogRef<MessageDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: MessageDialogModel
	) {
		// Update view with given values
		this.title = data.title;
		this.message = data.message;
		this.subMessage = data.subMessage;
	}

	onClose(): void {
		// Close the dialog, return true
		this.dialogRef.close(true);
	}
}
