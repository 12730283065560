<div fxLayout="column" class="editor">
	<div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" #modalArea class="header">
		<span class="type-header" fxFlex>{{ type?.description }}</span>
		<span class="direction-header positive" fxFlex="100px">+ve impact</span>
		<span class="direction-header negative" fxFlex="100px">-ve impact</span>
		<span fxFlex="60px"></span>
	</div>

	<div fxFlex id="choices">
		<div *ngFor="let choice of choices" fxLayout="row">
			<pp-editor-two-num fxFlex 
				[type]="type"
				[choice]="choice"
				[statistic]="getStatistic(choice)"
				(changed)="valueChanged($event)">
			</pp-editor-two-num>
			<div fxFlex="60px"></div>
		</div>
	</div>

	<div class="fab-container">
		<button mat-mini-fab matTooltip="Add" (click)="addEntry(type)"><mat-icon>add</mat-icon></button>		
	</div>
</div>
