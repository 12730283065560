import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { UnmanagedCookieService } from 'ng2-cookies';
import { LangService } from '../../services/lang.service'

@Component({
  selector: 'pp-lang-selector',
  templateUrl: './lang-selector.component.html',
  styleUrls: ['./lang-selector.component.scss']
})
export class LangSelectorComponent {
	language = {
		'en': 'English',
		'fr': 'Français',
	}
	languageSelection = {
		'en': 'fr',
		'fr': 'en',
	}
	get currentLanguage(): string { return this._lang.currentLang; }

	constructor(
		private _lang: LangService
	){ }

	changeLang(lang: string) {
		this._lang.changeLang(lang);
		window.location.reload();
	}


}
