import { Injectable } from '@angular/core';

/**
 * A very simple logging service - to be enhanced.
 * @export
 * @class LoggerService
 */
@Injectable({
	providedIn: 'root',
})
export class LoggerService {
	logError(error, ...args) {
		if (args.length) {
			console.error(error, args);
		} else {
			console.error(error);
		}
	}

	logWarning(warning, ...args) {
		if (args.length) {
			console.warn(warning, args);
		} else {
			console.warn(warning);
		}
	}

	logInfo(info, ...args) {
		if (args.length) {
			console.info(info, args);
		} else {
			console.info(info);
		}
	}
}
