<div class="split-pane-content-form">
    <div class="split-pane-content-full-width flags-section-container" appearance="fill" >
        <mat-label><b>Change Red Flag Appearance</b></mat-label>
        <div fxflex fxLayout="row"class="flags-container">
            <div class="flag-container" (click)="changeColour('blue')">
                <i class="fa fa-flag" style="color: blue; "></i>
                <i class="fa fa-flag-o" ></i>
            </div>
            <div class="flag-container" (click)="changeColour('green')">
                <i class="fa fa-flag" style="color: green;"></i>
                <i class="fa fa-flag-o" ></i>
            </div>
            <div class="flag-container" (click)="changeColour('yellow')">
                <i class="fa fa-flag" style="color: yellow;" ></i>
                <i class="fa fa-flag-o" ></i>
            </div>
            <div class="flag-container" (click)="changeColour('orange')">
                <i class="fa fa-flag" style="color: orange;" ></i>
                <i class="fa fa-flag-o" ></i>
            </div>
            <div class="flag-container" (click)="changeColour('purple')">
                <i class="fa fa-flag" style="color: purple;"></i>
                <i class="fa fa-flag-o"></i>
            </div>
        </div>
        <br/>
        <div class="reset-container">
            <button (click)="changeColour('red')" >
                <div>
                    <i class="fa fa-flag reset-button-flag" style="color: red;"></i>
                    <span>Reset</span>
                </div>
            </button>
        </div>

        <label >Create Date/Time </label>
        <div class="date-label">
            {{extendedDetails.createDateTime | date:'medium'}}
        </div>
    </div>
    <div *ngIf="extendedDetails.hasUsedServiceBefore" class="used-before">
        <label>Month Used</label>
        <br/>
        <mat-form-field class="used-before-input-container">
            <input matInput readonly [value]="extendedDetails.usedServiceMonthFormatted" class="used-before-input-show">
            <input [matDatepicker]="datePicker" [max]="today" class="used-before-input-hide">
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker startView="multi-year" (monthSelected)="closeDatePicker($event, datePicker)"></mat-datepicker>
        </mat-form-field>
        <br>
		<pp-text label="Service Used" [(ngModel)]="extendedDetails.usedService" (change)="detailsChanged()"> </pp-text>
	    <pp-text label="Professional Used" [(ngModel)]="extendedDetails.usedProfessional" (change)="detailsChanged()"> </pp-text>
    </div>
    <mat-form-field class="split-pane-content-full-width" appearance="fill">
     <mat-label>Medications Taken</mat-label>
      <textarea matInput placeholder="Medications Taken" [(ngModel)]="extendedDetails.medicationsTaken" (change)="detailsChanged()"></textarea>
    </mat-form-field>
    <mat-form-field class="split-pane-content-full-width" appearance="fill">
        <mat-label>Disabilities to Consider</mat-label>
        <textarea matInput placeholder="Disabilities to Consider" [(ngModel)]="extendedDetails.disabilities" (change)="detailsChanged()"></textarea>
    </mat-form-field>

</div>
