import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { StatisticalTypeDto, StatisticalChoiceDto } from './../../../../services/server-data';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { StatisticDto } from '../../../../services';
import { tap } from 'rxjs/operators';

interface IFormValue {
	int: number;
	intExtra1: number;
}

@Component({
	selector: 'pp-container-pick-and-add-two-num-item',
	templateUrl: './container-pick-and-add-two-num-item.component.html',
	styleUrls: ['./container-pick-and-add-two-num-item.component.scss'],
})
export class ContainerPickAndAddTwoNumItemComponent implements OnInit {
	@Input() statistic: StatisticDto;
	@Input() type: StatisticalTypeDto;
	@Output() changed = new EventEmitter<StatisticDto>();
	@Output() deleted = new EventEmitter<StatisticDto>();

	form: UntypedFormGroup;

	choice: StatisticalChoiceDto;

	constructor(private fb: UntypedFormBuilder) {
		this.buildForm();
	}

	valueUpdated() {
		this.changed.emit(this.statistic);
	}

	ngOnInit() {
		this.choice = this.type.statisticalChoices.find((c) => c.id === this.statistic.statisticalChoiceId);

		this.form.patchValue({
			int: this.statistic.int,
			intExtra1: this.statistic.intExtra1,
		});

		this.form.valueChanges
			.pipe(
				tap((val: IFormValue) => {
					this.statistic.int = Number(val.int).valueOf();
					this.statistic.intExtra1 = Number(val.intExtra1).valueOf();

					this.valueUpdated();
				})
			)
			.subscribe();
	}

	deleteChoice() {
		this.deleted.emit(this.statistic);
	}

	private buildForm() {
		this.form = this.fb.group({
			int: ['', Validators.required],
			intExtra1: ['', Validators.required],
		});
	}
}
