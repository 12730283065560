import { Injectable } from '@angular/core';
import { AdditionalSessionDto } from 'app/services';

@Injectable({
	providedIn: 'root',
})
export class AdditionalSessionStatus {
	getSessionStatusIcon(additionalSession: AdditionalSessionDto) {
		if (additionalSession.approvalId) {
			return 'thumb_up';
		} else if (additionalSession.declineId) {
			return 'thumb_down';
		}

		return 'schedule';
	}

	getSessionStatusClass(additionalSession: AdditionalSessionDto) {
		if (additionalSession.approvalId) {
			return 'approved';
		} else if (additionalSession.declineId) {
			return 'declined';
		}

		return 'unknown';
	}
}
