
import { EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
	MatSnackBar,
} from '@angular/material/snack-bar';

import { CommonService } from './common.service';
import { CrudService } from './crud.service';
import { ConfigService } from './config.service';
import { SaveResult, SaveResultDto } from './server-data';
import { LoggerService } from './logger.service';
import { ChangeNotification } from './change-notification';
import { WarningsService } from '.';

const DELETED_SNACKBAR_DURATION = 2000;

export abstract class BaseService {
	protected crud: CrudService;
	protected http: HttpClient;
	protected logger: LoggerService;
	protected config: ConfigService;
	protected snackBar: MatSnackBar;
	protected warnings: WarningsService;

	constructor(common: CommonService) {
		this.crud = common.crud;
		this.http = common.http;
		this.logger = common.logger;
		this.config = common.config;
		this.snackBar = common.snackBar;
		this.warnings = common.warnings;
	}

	protected handleError(err) {
		// TODO: Implement better error handling.

		let message = 'An error occurred';
		let httpError = err as HttpErrorResponse;
		if (httpError) {
			if (httpError.status === 500) {
				message = 'Internal server error';
			}
		}

		this.warnings.showMultiErrors('Error', message);
	}

	protected saveInfo<T, TResult>(url: string, dto: T, eventEmitter: EventEmitter<ChangeNotification<TResult>>
	): Observable<SaveResultDto<TResult>> {
		return this.crud.saveWithInfo<T, TResult>(url, dto).pipe(
			tap(result => {
				switch (result.saveResult) {
					case SaveResult.OK:
						if (eventEmitter) {
							eventEmitter.emit(ChangeNotification.update<TResult>(result.resultDto));
						}
						break;
					case SaveResult.Error:
						this.warnings.showMultiErrors('Save failed', result.errorMessage);
						break;
					case SaveResult.Warnings:
						break;
					default:
						break;
				}
			}, err => {
				this.handleError(err);
			}));
	}

	protected save<T>(url: string, dto: T, eventEmitter?: EventEmitter<ChangeNotification<T>>): Observable<SaveResultDto<T>> {

		return this.crud.save(url, dto).pipe(
			tap(result => {
				switch (result.saveResult) {
					case SaveResult.OK:
						if (eventEmitter) {
							eventEmitter.emit(ChangeNotification.update<T>(result.resultDto));
						}
						break;
					case SaveResult.Error:
						this.warnings.showMultiErrors('Save failed', result.errorMessage);
						break;
					case SaveResult.Warnings:
						break;
					default:
						break;
				}
			}, err => {
				this.handleError(err);
			}));
	}

	protected delete<T>(url: string, id: number, eventEmitter?: EventEmitter<ChangeNotification<T>>, objectTypeName: string = '') {
		this.crud.delete(url)
			.subscribe(result => {
				switch (result.saveResult) {
					case SaveResult.OK:
						objectTypeName = objectTypeName || 'object';
						const snackMessage = _.startCase(`${objectTypeName} deleted`);
						this.snackBar.open(snackMessage, '', {
							duration: DELETED_SNACKBAR_DURATION
						});

						if (eventEmitter) {
							eventEmitter.emit(ChangeNotification.delete<T>(id));
						}
						break;
					case SaveResult.Error:
						this.warnings.showMultiErrors('Delete failed', result.errorMessage);
						break;
					case SaveResult.Warnings:
						break;
					default:
						break;
				}
			}, err => {
				this.handleError(err);
			});
	}
}
