<div fxLayout="row">
	<h2>{{ title }}</h2>
	<span fxFlex></span>
	<pp-apply-cancel 
		[active]="active"
		[asSave]="true"
		[form]="form"
		(result)="applyResult($event)">
	</pp-apply-cancel>
</div>
