<form novalidate autocomplete="off" [formGroup]="form">
	<div fxLayout="row" fxLayoutGap="16px">
		<div fxFlex="40">
			<pp-text #anonRef
				label="Anonymous client reference"
				formControlName="anonymousReference">
			</pp-text>
		</div>

		<div fxLayout="column" fxFlex="60">
			<pp-display 
				label="Professional"
				[value]="client?.professionalName">
			</pp-display>

			<pp-organisation-hierarchy-edit
				[organisation]="organisation"
				[editDto]="client"
				[immediateUpdate]="true"
				(changed)="organisationChanged($event)">
			</pp-organisation-hierarchy-edit>
		</div>
	</div>
</form>