<div fxLayout="column" id="container">
	<mat-expansion-panel #panelPracticeDiaries
		[expanded]="true"
		(closed)="closePanel(panelPracticeDiaries)"
		(opened)="openPanel(panelPracticeDiaries)"
		[fxFlex]="panelPracticeDiaries.expanded ? calcFlexHeight : 'none'">
		<mat-expansion-panel-header>
			<mat-panel-title>
				<mat-icon>location_city</mat-icon>
				Practice office diaries
			</mat-panel-title>
		</mat-expansion-panel-header>
		<div class="selection-container">
			<mat-nav-list class="practice-navigator">
				<mat-list-item *ngFor="let office of offices">
					<a (click)="gotoOffice($event, office)" class="nav-item" matLine>{{ office.name }}</a>
				</mat-list-item>
			</mat-nav-list>
		</div>
	</mat-expansion-panel>

	<mat-expansion-panel #panelProfessionalDiaries
		(closed)="closePanel(panelProfessionalDiaries)"
		(opened)="openPanel(panelProfessionalDiaries)"
		[fxFlex]="panelProfessionalDiaries.expanded ? calcFlexHeight : 'none'">
		<mat-expansion-panel-header>
			<mat-panel-title>
				<mat-icon>face</mat-icon>
				Professional diaries
			</mat-panel-title>
		</mat-expansion-panel-header>
		<div fxLayout="column"  class="selection-container">
			<mat-nav-list class="practice-navigator">
				<mat-list-item (click)="gotoProfessionalDiary($event, professional)" *ngFor="let professional of professionals">
					<a class="nav-item" matLine>{{ professional.name }}</a>
				</mat-list-item>
			</mat-nav-list>
		</div>
	</mat-expansion-panel>

	<mat-expansion-panel #panelProfessionalAvailabilities
		(closed)="closePanel(panelProfessionalAvailabilities)"
		(opened)="openPanel(panelProfessionalAvailabilities)"
		[fxFlex]="panelProfessionalAvailabilities.expanded ? calcFlexHeight : 'none'">
		<mat-expansion-panel-header>
			<mat-panel-title>
				<mat-icon>people</mat-icon>
				Availability
			</mat-panel-title>
		</mat-expansion-panel-header>
		<div fxLayout="column"  class="selection-container">
			<mat-nav-list class="practice-navigator">
				<mat-list-item (click)="gotoProfessionalAvailability($event, professional)" *ngFor="let professional of professionals">
					<a class="nav-item" matLine>{{ professional.name }}</a>
				</mat-list-item>
			</mat-nav-list>
		</div>
	</mat-expansion-panel>
</div>
