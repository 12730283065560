import { Component, OnChanges, OnDestroy, Input, Output, EventEmitter, ViewContainerRef, } from '@angular/core';
import { GridOptions, ColDef, GridReadyEvent } from 'ag-grid-community/main';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { TdDialogService } from '@covalent/core/dialogs';
import { AgGridService, ChangeNotificationBase, ChangeType } from 'app/services';

export class IconClickedEvent {
	colId: string;
	rowData: any;
}

@Component({
	selector: 'pp-selection-grid',
	templateUrl: './selection-grid.component.html',
	styleUrls: ['./selection-grid.component.scss']
})
export class SelectionGridComponent implements OnChanges, OnDestroy {
	private changeSub: Subscription;

	private lastWidth: number;
	private lastHeight: number;

	@Input() columnDefs: ColDef[];
	@Input() rowData: any[];
	@Input() pinnedBottomRowData: any[];
	@Input() hasAdd: boolean = true;
	@Input() hasEdit: boolean = true;
	@Input() hasViewWithEdit: boolean = false;
	@Input() hasDelete: boolean = true;
	@Input() typeName: string;
	@Input() confirmDelete: boolean = false;
	@Input() rowClickIsEdit: boolean = false;
	@Input() readOnly: boolean = false;
	@Input() noAutoSize: boolean = false;
	@Input() canDelete: boolean = true;
	@Input() readOnlyAllowAdd: boolean = false;
	@Input() noHScroll: boolean = false;

	@Output() add = new EventEmitter<any>();
	@Output() edit = new EventEmitter<any>();
	@Output() view = new EventEmitter<any>();
	@Output() delete = new EventEmitter<any>();
	@Output() cellClicked = new EventEmitter<any>();
	@Output() iconClicked = new EventEmitter<IconClickedEvent>();
	@Output() doubleClicked = new EventEmitter<any>();
	@Output() gridReady = new EventEmitter<GridReadyEvent>();

	columns: ColDef[];
	gridOptions: GridOptions;

	constructor(
		private agGrid: AgGridService,
		private dialog: TdDialogService,
	) { }

	ngOnChanges(changes) {
		if (changes.readOnly || !this.gridOptions) {
			let overrides: any = {};
			if (this.readOnly) {
				overrides.rowSelection = 'none';
			}

			this.buildGridOptions(overrides);
		}

		if (changes.rowData || changes.pinnedBottomRowData) {
			this.setData();
		}

		if (changes.columnDefs) {
			this.buildColumnDefs();
		}
	}

	ngOnDestroy() {
		this.unsubscribe();
	}

	subscribe(emitter: EventEmitter<ChangeNotificationBase>) {
		this.changeSub = emitter.subscribe(this.changed.bind(this));
	}

	unsubscribe() {
		if (this.changeSub) {
			this.changeSub.unsubscribe();
			this.changeSub = undefined;
		}
	}

	changed(note: ChangeNotificationBase) {
		switch (note.changeType) {
			case ChangeType.Update:
				const index = _.findIndex(this.rowData, data => data.id === note.instance.id);
				if (index !== -1) {
					this.rowData.splice(index, 1, note.instance);
				} else {
					if (!this.rowData) {
						this.rowData = [];
						this.setData();
					}
					this.rowData.push(note.instance);
				}
				break;
			case ChangeType.Delete:
				_.remove(this.rowData, data => data.id === note.id);
				break;
			default:
				break;
		}
		this.refresh();
	}

	handleAdd(e) {
		this.add.emit();
	}

	refresh() {
		this.gridOptions.api.setRowData(this.rowData);
		this.gridOptions.api.refreshCells();
	}

	exportDataAsCsv(params = {}) {
		this.gridOptions.api.exportDataAsCsv(params);
	}

	private setData() {
		if (!this.gridOptions.api) {
			return;
		}

		this.gridOptions.api.setRowData(this.rowData);
		this.gridOptions.api.setPinnedBottomRowData(this.pinnedBottomRowData);
	}

	private onGridSizeChanged(e) {
		if (!this.gridOptions.api || this.noAutoSize) {
			return;
		}

		// Check whether the size has actually changed - don't size columns if the size hasn't changed.
		const w: number = e.clientWidth,
			h: number = e.clientHeight;

		if (w === this.lastWidth && h === this.lastHeight) {
			return;
		}

		this.lastWidth = w;
		this.lastHeight = h;

		this.gridOptions.columnApi.sizeColumnsToFit(e.clientWidth);
	}

	private onGridReady(e: GridReadyEvent) {
		this.setData();
		this.gridReady.emit(e);
	}

	private onDelete(data) {
		if (!this.confirmDelete) {
			this.delete.emit(data);
			return;
		}

		this.dialog
			.openConfirm({
				message: `Are you sure you want to delete this ${this.typeName}?`,
				title: 'Confirm delete',
				acceptButton: 'Delete',
			})
			.afterClosed()
			.subscribe((accept: boolean) => {
				if (!accept) {
					return;
				}
				this.delete.emit(data);
			});
	}

	private onCellClicked(e) {
		const data = e.data;

		switch (e.colDef.colId) {
			case 'edit':
				this.edit.emit(data);
				break;
			case 'delete':
				this.onDelete(data);
				break;
			case 'cellClicked':
				this.cellClicked.emit(e);
				break;
			case 'pageview':
				this.view.emit(data);
				break;
			default:
				if (this.rowClickIsEdit) {
					this.edit.emit(data);
					return;
				}

				this.iconClicked.emit(<IconClickedEvent>{
					colId: e.colDef.colId,
					rowData: data
				});
				break;
		}
	}

	private onRowDoubleClicked(e) {
		this.doubleClicked.emit(e.data);
	}

	private buildGridOptions(overrides: any) {
		this.gridOptions = this.agGrid.buildGridOptions(overrides);

		this.gridOptions.onGridReady = this.onGridReady.bind(this);
		this.gridOptions.onGridSizeChanged = this.onGridSizeChanged.bind(this);
		this.gridOptions.onCellClicked = this.onCellClicked.bind(this);
		this.gridOptions.onRowDoubleClicked = this.onRowDoubleClicked.bind(this);
	}

	private buildColumnDefs() {
		const colDefs = _.cloneDeep(this.columnDefs);

		if (this.hasDelete && !this.readOnly && this.canDelete) {
			const deleteCol = this.agGrid.makeIconColumn('delete', this, 'Delete');
			colDefs.unshift(deleteCol);
		}
		if (this.hasEdit && !this.rowClickIsEdit && !this.readOnly) {
			const editCol = this.agGrid.makeIconColumn('edit', this, 'Edit');
			colDefs.unshift(editCol);
		}
		if (this.hasEdit && !this.rowClickIsEdit && (this.readOnly || this.hasViewWithEdit)) {
			const viewCol = this.agGrid.makeIconColumn('pageview', this, 'View');
			colDefs.unshift(viewCol);
		}

		this.columns = colDefs;
	}
}
