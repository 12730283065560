import {
	Component,
	OnInit,
	OnChanges,
	Input,
	Output,
	EventEmitter,
	ChangeDetectorRef,
	ViewChild,
	ElementRef
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { CommunicationAddressDto, CountryLookupDto } from 'app/services';
import { CommAddrEditModalComponent } from '../comm-addr-edit-modal/comm-addr-edit-modal.component';

class Tag {
	name: string;
	className?: string;
}

@Component({
	selector: 'pp-comm-addr-display-edit',
	templateUrl: './comm-addr-display-edit.component.html',
	styleUrls: ['./comm-addr-display-edit.component.scss'],
	host: {
		'cdkAriaLive': 'polite',
		'aria-live': 'polite',
		'aria-atomic': "false",
		'aria-relevant': "all",
	},
})
export class CommAddrDisplayEditComponent implements OnChanges {
	tags: Tag[];
	details: string;

	@Input() address: CommunicationAddressDto;
	@Input() countries: CountryLookupDto[];
	@Input() readOnly: boolean;
	@Output() updated = new EventEmitter<CommunicationAddressDto>();

	@ViewChild('modalArea', { static: true }) modalAreaEl: ElementRef;

	constructor(private cd: ChangeDetectorRef, private dialog: MatDialog, private media: MediaObserver) { }

	ngOnChanges() {
		this.setDetails();
	}

	add() {
		this.address.areaCode = '';
		this.address.text = '';

		this.addEditModal();
	}

	edit() {
		this.addEditModal();
	}

	delete() {
		const addr = this.address;
		addr.text = '';
		addr.areaCode = '';
		addr.countryId = undefined;
		addr.isPrimary = false;
		addr.isSecondary = false;
		addr.isForNotifyEmail = false;
		addr.isForNotifySms = false;
		addr.isCanSendEmail = false;
		addr.isCanLeaveMessage = false;

		this.updated.emit(addr);
	}

	private addEditModal() {
		const gtSm = this.media.isActive('gt-sm');

		const rect = this.modalAreaEl.nativeElement.getBoundingClientRect();
		let dialogRef = this.dialog.open(CommAddrEditModalComponent, {
			width: gtSm ? rect.width + 'px' : '95%',
			position: {
				left: gtSm ? rect.left + 'px' : '10px',
				top: gtSm ? rect.top + 'px' : '70px'
			},
			data: {
				address: this.address,
				countries: this.countries
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result === 'ok') {
				this.setDetails();
			}
			this.updated.emit(this.address);
		});
	}

	private setDetails() {
		let result = '';
		const addr = this.address;

		if (addr.countryVisible) {
			if (addr.countryId) {
				let country = _.find(this.countries, c => c.id === addr.countryId);
				if (country) {
					result += `+${country.callingCode}`;
				}
			}
		}

		if (addr.areaCodeVisible) {
			result += ` ${addr.areaCode}`;
		}

		this.tags = [];

		if (addr.showPrimarySecondary && addr.isPrimary) {
			this.addTag('Primary', 'primary');
		}

		if (addr.showPrimarySecondary && addr.isSecondary) {
			this.addTag('Secondary', 'secondary');
		}

		if (addr.canBeForNotifyEmail && addr.isForNotifyEmail) {
			this.addTag('Email (notify)');
		}

		if (addr.canBeForNotifySms && addr.isForNotifySms) {
			this.addTag('SMS (notify)');
		}

		if (addr.emailVisible && addr.isCanSendEmail) {
			this.addTag('Email (can send)');
		}

		if (addr.showCanSendMsg && addr.isCanLeaveMessage) {
			this.addTag('Leave message');
		}

		result += ` ${addr.text}`;

		this.details = result;
	}

	private addTag(name: string, cls: string = undefined) {
		this.tags.push({
			name,
			className: cls
		});
	}
}
