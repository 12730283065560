import { Component } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { Subscription } from 'rxjs'
import {
	ConfigService,
	LoggerService,
	LoadingStateService,
	CommonService,
} from 'app/services';

@Component({
	template: ''
})
export class BaseViewComponent {
	subscriptions: Subscription[] = [];


	private isLoading: boolean;
	protected config: ConfigService;
	protected logger: LoggerService;
	protected loadingState: LoadingStateService;

	get loading(): boolean {
		return this.isLoading;
	}

	set loading(value: boolean) {
		this.isLoading = value;
		this.loadingState.setLoading(value);
	}

	get loading$(): Observable<boolean> {
		return this.loadingState.loading$;
	}

	constructor(
		public common: CommonService
	) {
		this.config = common.config;
		this.logger = common.logger;
		this.loadingState = common.loadingState;
	}

	navigateWithLoading(params: any[]) {
		this.loading = true;
		this.common.router.navigate(params)
			.then(() => this.loading = false)
			.catch(() => this.loading = false);
	}

	handleError(err: any, context: string) {
		this.loading = false;
		if (this.common.isAlreadyHandledError(err)) {
			return;
		}
		this.common.showSnackBarMessage('Error ' + context);
		this.logger.logError('Error ' + context, err);
	}

	ngOnDestroy() {
		this.subscriptions.forEach(x => x.unsubscribe());
	}

	protected get systemPreferences() {
		return this.common.systemPreferences;
	}
}
