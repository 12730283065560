import { Injectable, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { GridOptions, ColDef } from 'ag-grid-community/main';
import * as moment from 'moment';
import { AuthenticationService } from './authentication.service';

@Injectable({
	providedIn: 'root',
})
export class AgGridService {
	constructor(private decimalPipe: DecimalPipe, private auth: AuthenticationService) { }

	public renderDate(field: string, row: any) {
		const m = moment(row.data[field]);
		if (!m.isValid()) {
			return '';
		}
		return m.format('DD-MMM-YYYY');
	}

	public renderDayDate(field: string, row: any) {
		const m = moment(row.data[field]);
		if (!m.isValid()) {
			return '';
		}
		return m.format('ddd DD-MMM-YYYY');
	}

	public renderDateTime(field: string, row: any) {
		const m = moment(row.data[field]);
		if (!m.isValid()) {
			return '';
		}
		return m.format('DD-MMM-YYYY h:mm a');
	}

	public renderTime(field: string, row: any) {
		const m = moment(row.data[field]);
		if (!m.isValid()) {
			return '';
		}
		return m.format('h:mm a');
	}

	public renderDayDateTime(field: string, row: any) {
		const m = moment(row.data[field]);
		if (!m.isValid()) {
			return '';
		}
		return m.format('ddd DD-MMM-YYYY h:mm a');
	}

	public renderBool(field: string, row: any) {
		return row.data[field] ? 'Yes' : 'No';
	}

	public renderBoolYesOnly(field: string, row: any) {
		return row.data[field] ? 'Yes' : '';
	}

	public renderBoolNoOnly(field: string, row: any) {
		return row.data[field] ? '' : 'No';
	}

	public renderWithPipe(field: string, pipe: PipeTransform, row: any, ...pipeArgs) {
		const fieldVal = row.data[field];
		return pipe.transform(fieldVal, pipeArgs);
	}

	public renderNumber(field: string, decimalPipeSpec: string, row: any): string {
		const fieldVal = row.data[field];
		return this.decimalPipe.transform(fieldVal, decimalPipeSpec || '1.0');
	}

	public renderBlank(): string {
		return '';
	}

	public makeDateColumn(header: string, field: string, opts: {} = null) {
		const colDef = {
			headerName: header,
			cellRenderer: this.renderDate.bind(this, field),
			field,
			width: 110,
			minWidth: 110,
			maxWidth: 110
		};
		return this.applyOpts(colDef, opts);
	}

	public makeDateTimeColumn(header: string, field: string, opts: {} = null) {
		const colDef = {
			headerName: header,
			cellRenderer: this.renderDateTime.bind(this, field),
			field,
			width: 150,
			minWidth: 150,
			maxWidth: 150
		};
		return this.applyOpts(colDef, opts);
	}

	public makeTimeColumn(header: string, field: string, opts: {} = null) {
		const colDef = {
			headerName: header,
			cellRenderer: this.renderTime.bind(this, field),
			field,
			width: 100,
			minWidth: 100,
			maxWidth: 100
		};
		return this.applyOpts(colDef, opts);
	}

	public makeDayDateTimeColumn(header: string, field: string, opts: {} = null) {
		const colDef = {
			headerName: header,
			cellRenderer: this.renderDayDateTime.bind(this, field),
			field,
			width: 180,
			minWidth: 180,
			maxWidth: 180
		};
		return this.applyOpts(colDef, opts);
	}

	public makeNumberColumn(header: string, field: string, decimalPipeSpec: string = '', opts: {} = null) {
		const colDef = {
			headerName: header,
			cellRenderer: this.renderNumber.bind(this, field, decimalPipeSpec),
			field,
			width: 80,
			minWidth: 80,
			maxWidth: 80,
			cellClass: 'align-right'
		};
		return this.applyOpts(colDef, opts);
	}

	public makeTextColumn(header: string, field: string, opts: {} = null) {
		const colDef = {
			headerName: header,
			field
		};

		return this.applyOpts(colDef, opts);
	}

	public makeRendererColumn(header: string, renderer: (params: any) => string, owner: object, opts: {} = null) {
		const colDef = {
			headerName: header,
			cellRenderer: renderer.bind(owner)
		};

		return this.applyOpts(colDef, opts);
	}

	public makePipeColumn(header: string, field: string, pipe: PipeTransform, owner: object, opts: {} = null) {
		const colDef = {
			headerName: header,
			cellRenderer: this.renderWithPipe.bind(owner, field, pipe)
		};

		return this.applyOpts(colDef, opts);
	}

	public makeIconColumn(icon: string, owner: object, iconTitle: string = '') {
		const renderer = () => {
			return `<i class="material-icons grid-command-icon" ${iconTitle ? `title="${iconTitle}"` : ''}>${icon}</i>`;
		};
		const colDef = {
			colId: icon,
			headerName: '',
			width: 30,
			minWidth: 30,
			maxWidth: 30,
			resizable: false,
			sortable: false,
			suppressSizeToFit: true,
			cellRenderer: renderer.bind(owner)
		};
		return colDef;
	}

	public makeAnchorColumn(header: string, urlPrefix: string, urlKey: string, key: string, owner: object): ColDef {
		const renderer = params => {
			const userId = this.auth.getUserId();
			const d = params.data;
			return `<a href="${urlPrefix}/${d[urlKey]}?u=${userId}" download class="url">
					${d[key]}
			</a>`;
		};
		const colDef = {
			headerName: header,
			cellRenderer: renderer.bind(owner)
		};
		return colDef;
	}

	public buildGridOptions(overrides: {} = null): GridOptions {
		const opts = <GridOptions>{
			rowHeight: 48,
			rowSelection: 'single',
			icons: {
				sortAscending: '<i class="fa fa-long-arrow-down" />',
				sortDescending: '<i class="fa fa-long-arrow-up" />'
			},
			autoSizePadding: 5,
			suppressNoRowsOverlay: true,
			suppressCellSelection: true,
			suppressMovableColumns: true,
			suppressAutoSize: true,
			defaultColDef: {
				resizable: true,
				sortable: true,
			},
		};

		if (overrides) {
			Object.assign(opts, overrides);
		}

		return opts;
	}

	private applyOpts(colDef: any, opts: any = null) {
		if (!opts) {
			return colDef;
		}

		if (opts.fixedWidth) {
			opts.width = opts.fixedWidth;
			opts.resizable = false;
			opts.suppressSizeToFit = true;

			delete opts.fixedWidth;
		}

		Object.assign(colDef, opts);
		return colDef;
	}
}
