import { Input, OnInit, Directive } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, AbstractControl } from '@angular/forms';

@Directive()
export abstract class BaseComponent<T> implements ControlValueAccessor, OnInit {
	private innerValue: T;

	private changed = new Array<(value: T) => void>();
	private touched = new Array<() => void>();
	private validator: (c: UntypedFormControl) => void = undefined;

	control: UntypedFormControl;
	isDisabled: Boolean = false;

	@Input() required = false;
	@Input() size: string;
	@Input() formControlName: string;


	// tslint:disable-next-line:no-empty
	setFocus() { }

	// tslint:disable-next-line:no-empty
	onSetValue() { }

	get value(): T {
		return this.innerValue;
	}

	@Input()
	set value(value: T) {
		if (this.innerValue !== value) {
			this.innerValue = value;
			this.changed.forEach((f) => f(value));

			this.onSetValue();
		}
	}

	get isValid(): Boolean {
		if (!this.control) {
			return true;
		}

		return this.control.valid;
	}

	ngOnInit() {
		this.checkRequired();
	}

	writeValue(value: T) {
		this.innerValue = value;
	}

	registerOnChange(fn: (value: T) => void) {
		this.changed.push(fn);
	}

	registerOnTouched(fn: () => void) {
		this.touched.push(fn);
	}

	touch() {
		this.touched.forEach((f) => f());
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	validate(c: UntypedFormControl) {
		if (!this.control) {
			this.control = c;
			this.checkRequired();
		}

		if (this.validator) {
			return this.validator(c);
		}
	}

	registerValidator(v: (c: UntypedFormControl) => void) {
		this.validator = v;
	}

	private checkRequired() {
		if (this.control) {
			if (!this.required) {
				this.required = this.hasRequiredField(this.control);
			}
		}
	}

	private hasRequiredField = (abstractControl: AbstractControl): boolean => {
		if (abstractControl.validator) {
			const validator = abstractControl.validator({} as AbstractControl);
			if (validator && validator.required) {
				return true;
			}
		}

		// eslint-disable-next-line @typescript-eslint/dot-notation
		let controls = abstractControl['controls'];
		if (controls) {
			for (const controlName in controls) {
				if (controls[controlName]) {
					if (this.hasRequiredField(controls[controlName])) {
						return true;
					}
				}
			}
		}
		return false;
	};
}
