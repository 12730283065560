<form novalidate autocomplete="off" [formGroup]="form">
	<div fxLayout="row">
		<pp-date 
			fxFlex="160px"
			formControlName="dateOfBirth" 
			label="Date of Birth" 
			placeholder="Date of Birth"
			>
		</pp-date>
	</div>
</form>
