import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

import { BaseEditComponent } from 'app/shared/base-edit/base-edit.component';
import { ClientEditDetailsComponent } from './client-edit-details/client-edit-details.component';
import { ClientEditStatisticsComponent } from './client-edit-statistics/client-edit-statistics.component';
import { ClientEditSessionsComponent } from './client-edit-sessions/client-edit-sessions.component';

import {
	CommonService,
	StatisticClientEditDto,
	toUnspecified,
} from 'app/services';
import { ICheckSaveChanges } from 'app/interfaces';
import { CasesService } from 'app/cases/cases.service';

@Component({
	selector: 'pp-client-edit',
	templateUrl: './client-edit.component.html',
	styleUrls: ['./client-edit.component.scss'],
})
export class ClientEditComponent
	extends BaseEditComponent<StatisticClientEditDto>
	implements OnInit, ICheckSaveChanges {
	private activityHeaderId: number;

	@ViewChild('details') details: ClientEditDetailsComponent;
	@ViewChild('statistics') statistics: ClientEditStatisticsComponent;
	@ViewChild('sessions') sessions: ClientEditSessionsComponent;

	constructor(
		common: CommonService,
		private router: Router,
		private route: ActivatedRoute,
		private service: CasesService,
	) {
		super(common);

		this.objectName = 'client';
		this.activityHeaderId = +route.parent.snapshot.paramMap.get('id');
	}

	ngOnInit() {
		let id = this.route.snapshot.paramMap.get('id');
		this.fetch(() => this.service.getStatisticClient(this.activityHeaderId, +id));
	}

	getComponents() {
		return [
			this,	// Needed to get the object to save against.
			this.details,
			this.statistics,
			this.sessions
		];
	}

	saveChanges() {
		const cloned = _.cloneDeep(this.editDto);
		cloned.statisticClientSessions.forEach(s => {
			s.sessionDateTime = toUnspecified(s.sessionDateTime);
		});
		return this.service.statisticClientSave(this.activityHeaderId, cloned);
	}

	complete(result: boolean) {
		this.completeWithBack(result, undefined, this);
	}
}
