import { Component, OnInit } from '@angular/core';
import { LiveReaderService } from '../live-reader.service';

@Component({
	selector: 'pp-live-reader',
	templateUrl: './live-reader.component.html',
	styleUrls: ['./live-reader.component.scss'],
	host: {
		"class": "cdk-visually-hidden",
		"[attr.aria-live]": "'polite'",
	}
})
export class LiveReaderComponent {

	get messages() { return this.announcer.messages; }

	constructor(private announcer: LiveReaderService) { }

}
