<div fxLayout="column" layout-fill>
	<mat-toolbar color="primary" class="md-whiteframe-z1 login-toolbar" [ngStyle]="toolbarStyle">
		<h1>{{ title }} Login</h1>
		<span flex></span>
		<pp-lang-selector></pp-lang-selector>
	</mat-toolbar>
	<div class="md-content" layout-padding flex>
		<div layout-gt-xs="row" layout-align-gt-xs="center start" class="margin">
			<ng-template tdLoading [tdLoadingUntil]="!loading" tdLoadingStrategy="overlay">
				<form novalidate autocomplete="off" (ngSubmit)="login()" [formGroup]="form">
					<mat-card [ngStyle.gt-xs]="{ 'width.px': 550 }">
						<mat-card-title fxLayout="row" fxLayoutAlign="start center">
							<mat-icon class="mat-icon-logo" svgIcon="assets:eapexec-icon"></mat-icon>
							<h2>Login</h2>
						</mat-card-title>

						<mat-card-subtitle>
							<div class="login-scheme" *ngIf="hasMultipleLoginSchemes">
								<mat-button-toggle-group
									[value]="loginScheme"
									(change)="loginSchemeChanged($event)"
									name="loginScheme"
									aria-label="Login scheme"
									[disabled]="preventSwitchScheme">
									<mat-button-toggle *ngIf="loginSchemes[LOGIN_STYLE_LEGACY]" value="legacy">Legacy login</mat-button-toggle>
									<mat-button-toggle *ngIf="loginSchemes[LOGIN_STYLE_B2C]" value="b2c">Modern external login</mat-button-toggle>
									<mat-button-toggle *ngIf="loginSchemes[LOGIN_STYLE_B2C_CORP]" value="b2c-corp">Modern corporate login</mat-button-toggle>
								</mat-button-toggle-group>
							</div>

							<div class="login-instructions" *ngIf="loginScheme === LOGIN_STYLE_LEGACY">
								Please login with your username and password.
							</div>

							<div class="login-instructions" *ngIf="loginScheme === LOGIN_STYLE_B2C">
								Tap the Login button to start.
							</div>

							<div class="login-instructions" *ngIf="loginScheme === LOGIN_STYLE_B2C_CORP">
								Tap the Login button to start.
							</div>
						</mat-card-subtitle>

						<mat-divider></mat-divider>

						<!-- Alert error -->
						<pp-alert [opened]="error" role="alert" aria-live="polite">
							<td-message label="{{ error }}" [opened]="error" color="warn" icon="error"></td-message>
						</pp-alert>

						<!-- Alert successful login -->
						<pp-alert [opened]="loggedSuccessfully">
							<td-message label="Logged in successfully"></td-message>
						</pp-alert>

						<mat-card-content>
							<div
								class="aadb2c-progress"
								*ngIf="isExternalLoginScheme || isExternalLoginSchemeCorporate">
								<div>
									{{ authenticationService.aadB2CLoginProgress$ | async }}
								</div>

								<mat-spinner
									*ngIf="
										authenticationService.loggedInB2C &&
										(authenticationService.i$ | async)
									"
									mode="indeterminate"
									diameter="30">
								</mat-spinner>
							</div>

							<ng-template tdLoading="loading" *ngIf="loginScheme === 'legacy'">
								<pp-text maxLength="50" #username formControlName="username" label="Username" autocomplete="username"></pp-text>

								<pp-text
									*ngIf="!passwordExpired"
									#password
									formControlName="password"
									type="password"
									label="Password"
									placeholder="Password"
									autocomplete="password"></pp-text>

								<div [hidden]="!passwordExpired">
									<h3>Password expired - please enter a new password.</h3>

									<pp-text
										formControlName="oldPassword"
										#oldPassword
										type="password"
										label="Old password"
										placeholder="Old password"
										autocomplete="old-password"></pp-text>

									<pp-text
										#newPassword
										ppValidateEqual="newPasswordConfirm"
										reverse="true"
										formControlName="newPassword"
										type="password"
										label="New password"
										autocomplete="new-password"></pp-text>

									<pp-text
										#newPasswordConfirm
										ppValidateEqual="newPassword"
										formControlName="newPasswordConfirm"
										type="password"
										label="Confirm"
										autocomplete="confirm-new-password"></pp-text>

									<div
										*ngIf="
											form.get('newPasswordConfirm').touched &&
											form.get('newPasswordConfirm').hasError('validateEqual')
										"
										class="validation-message">
										Passwords are not the same
									</div>
								</div>

								<pp-checkbox label="Remember me" formControlName="rememberMe"></pp-checkbox>
							</ng-template>
						</mat-card-content>

						<mat-divider></mat-divider>

						<mat-card-actions layout="row" layout-align="start center">
							<div layout="row" layout-align="center center">
								<button
									mat-raised-button
									*ngIf="passwordExpired"
									type="submit"
									[disabled]="form.invalid">
									Change password
								</button>

								<button
									mat-raised-button
									color="accent"
									*ngIf="!passwordExpired"
									[disabled]="preventLogin || mustReload"
									type="submit">
									Login
								</button>

								<button mat-raised-button type="button" *ngIf="passwordExpired" (click)="cancel()">
									Cancel
								</button>
							</div>
						</mat-card-actions>
					</mat-card>
				</form>
			</ng-template>
		</div>
	</div>
</div>
