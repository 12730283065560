import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MediaObserver } from '@angular/flex-layout';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { BaseService } from 'app/services/base-service';
import {
	CountriesDto,
	CitiesLookupDto,
	CitiesDto,
	DialogResult,
	CommonService,
} from 'app/services';

export class PostcodeSelection {
	cityId: number
}

@Injectable()
export class PostcodeService extends BaseService {

	constructor(common: CommonService, 
		private matDialog: MatDialog, 
		private media: MediaObserver,
		) {
		super(common);
	}

	getCountries(): Observable<CountriesDto> {
		return this.crud.loadWithGet('api/addressCountries');
	}
	
	lookupCities(search: CitiesLookupDto): Observable<CitiesDto> {
		return this.crud.loadWithPost('api/addressCities', search);
	}

	findPostcode (handleSelection: Function, country: string, comp: any): Observable<DialogResult<PostcodeSelection>> {
		const gtSm = this.media.isActive('gt-sm');

		return this.matDialog
			.open(comp, {
				width: gtSm ? '850px' : '95%',
				height: gtSm ? '80%' : '95%',
				data: {
					handleSelection: handleSelection,
					country: country
				},
			})
			.afterClosed();
	}
}
