import { LogoutService } from './../services/logout.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { CustomisationService, IOptions } from './../services/customisation.service';

// TODO: Fix up

//import { msalDynamicConfig } from '../msal.config';
import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AuthenticationService } from '../services';
import { TextComponent } from 'app/shared/forms/text.component';
// import { MsalService } from '@azure/msal-angular';
import { finalize, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';

class User {
	username: string;
	oldPassword: string;
	password: string;
	newPassword: string;
	rememberMe: boolean;
}

interface IToolbarStyle {
	'background-color'?: string;
	color?: string;
}

enum Mode {
	Login,
	ChangePassword,
	StaleClient,
}

@Component({
	selector: 'pp-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
	get LOGIN_STYLE_LEGACY() {
		return AuthenticationService.LOGIN_STYLE_LEGACY;
	}

	get LOGIN_STYLE_B2C() {
		return AuthenticationService.LOGIN_STYLE_B2C;
	}

	get LOGIN_STYLE_B2C_CORP() {
		return AuthenticationService.LOGIN_STYLE_B2C_CORP;
	}

	get isLegacyLoginScheme() {
		return this.loginScheme === this.LOGIN_STYLE_LEGACY;
	}

	get isExternalLoginScheme() {
		return this.loginScheme === this.LOGIN_STYLE_B2C;
	}

	get isExternalLoginSchemeCorporate() {
		return this.loginScheme === this.LOGIN_STYLE_B2C_CORP;
	}

	get preventLogin() {
		if (this.loginScheme === this.LOGIN_STYLE_LEGACY) {
			return this.form.invalid;
		}

		return this.authenticationService.isAadB2CLoginInProgress$.value;
	}

	private subs = new SubSink();
	form: UntypedFormGroup;

	loading: boolean = false;
	passwordExpired: boolean = false;
	error: string = '';
	mustReload = false;
	minVersion: string;

	loginScheme: string;
	preventSwitchScheme = false;
	loggedSuccessfully: boolean = false;

	title = 'Professional Portal';
	toolbarStyle: IToolbarStyle = {};

	hasMultipleLoginSchemes: boolean;
	loginSchemes = {
		[AuthenticationService.LOGIN_STYLE_LEGACY]: true,
		[AuthenticationService.LOGIN_STYLE_B2C]: false,
		[AuthenticationService.LOGIN_STYLE_B2C_CORP]: false,
	};

	@ViewChild('username') usernameControl: TextComponent;
	@ViewChild('password') passwordControl: TextComponent;
	@ViewChild('oldPassword') oldPasswordControl: TextComponent;

	constructor(
		private router: Router,
		private fb: UntypedFormBuilder,
		public authenticationService: AuthenticationService,
		private cdRef: ChangeDetectorRef,
		// private authService: MsalService,
		private logoutService: LogoutService,
		customisationService: CustomisationService
	) {
		const customisation = customisationService.customisation;
		if (customisation.toolbarBackgroundColor) {
			this.toolbarStyle['background-color'] = customisation.toolbarBackgroundColor;
		}

		if (customisation.toolbarColor) {
			this.toolbarStyle.color = customisation.toolbarColor;
		}

		if (customisation && customisation.accessibility)
			this.setAccessibility();

		this.title = customisation.appTitle || this.title;
		this.determineLoginSchemes(customisationService.options);
	}

	ngOnInit() {
		// Reset login status
		this.authenticationService.logout();
		this.logoutService.loggingOut = false;
		this.buildForm();

		const defaultUsername = this.authenticationService.defaultUserName;
		if (defaultUsername) {
			this.form.patchValue({
				username: defaultUsername,
				rememberMe: true,
			});
		}

		this.subs.sink = this.authenticationService.isAadB2CLoginInProgress$.subscribe((v) => {
			if (!v) {
				this.preventSwitchScheme = false;
			}
		});
	}

	ngAfterViewInit() {
		this.setInitialFocus();
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loginSchemeChanged(event: MatButtonToggleChange) {
		this.loginScheme = event.value;
		this.error = '';

		if (this.loginScheme === AuthenticationService.LOGIN_STYLE_B2C) {
			this.authenticationService.setAadB2CLoginInProgress(false);
			this.authenticationService.setAadB2CProgress('');
		}

		if (this.loginScheme === AuthenticationService.LOGIN_STYLE_B2C_CORP) {
			this.authenticationService.setAadB2CLoginInProgress(false);
			this.authenticationService.setAadB2CProgress('');
		}
	}

	login() {
		switch (this.loginScheme) {
			case AuthenticationService.LOGIN_STYLE_LEGACY:
				this.loginLegacy();
				break;
			case AuthenticationService.LOGIN_STYLE_B2C:
				// this.loginAadB2cWithAuthority(msalDynamicConfig.signInAuthority);
				break;
			case AuthenticationService.LOGIN_STYLE_B2C_CORP:
				// this.loginAadB2cWithAuthority(msalDynamicConfig.signInAuthorityCorp);
				break;
			default:
				break;
		}
	}

	loginLegacy() {
		this.loading = true;
		this.error = '';

		const user = this.form.getRawValue() as User,
			password = user.password || user.oldPassword;

		this.preventSwitchScheme = true;

		this.authenticationService
			.login(user.username, password, this.passwordExpired, user.newPassword)
			.pipe(
				tap((result) => {
					if (result) {
						if (result.ok) {
							this.loginOK(user);
						} else if (result.passwordExpired) {
							this.error = 'Your password has expired.';
							this.setMode(Mode.ChangePassword);
							setTimeout(() => {
								this.oldPasswordControl.setFocus();
							});
						} else {
							this.error = result.errorMessage;

							if (result.forceReload) {
								this.mustReload = true;
								this.setMode(Mode.StaleClient);
								setTimeout(() => {
									window.location.reload();
								}, 5000);
							}

							if (this.passwordExpired) {
								this.oldPasswordControl.setFocus();
							} else {
								this.passwordControl.setFocus();
							}
						}
					} else {
						this.error = 'An error occurred while logging in. Please try again.';
						this.passwordControl.setFocus();
					}
				}),
				finalize(() => {
					this.preventSwitchScheme = false;
					this.loading = false;
				})
			)
			.subscribe();
	}

	loginAadB2cWithAuthority(authority: string) {
		const isIE =
			window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

		this.authenticationService.setAadB2CLoginInProgress(true);
		this.authenticationService.setAadB2CProgress('Logging in...');
		this.preventSwitchScheme = true;

		// Note - this is a big hack - poking the redirectUri directly into the authService instance.
		// TODO: Maybe MSAL will allow this to be dynamically set in the future.
		// let as: any = this.authService;
		// as._redirectUri = msalDynamicConfig.redirectUri;

		// // this.authService.clientId = msalDynamicConfig.clientId;
		// // this.authService.authority = authority;

		// if (isIE) {
		// 	this.authService.loginRedirect();
		// } else {
		// 	this.authService.instance.loginPopup().then(null, (failureResult: string) => {
		// 		const msgs = failureResult.split(/\r\n|\r|\n/);
		// 		let msg = msgs[0];
		// 		if (msg) {
		// 			let parts = msg.split(/:\s/);
		// 			let code = parts[0];
		// 			if (code === 'AADB2C90118') {
		// 				this.runForgottenPasswordFlow();
		// 			}
		// 		}
		// 	});
		// }
	}

	runForgottenPasswordFlow() {
		this.authenticationService.runAADB2CForgottenPasswordFlow();
	}

	cancel() {
		this.setMode(Mode.Login);

		this.error = '';
		setTimeout(() => {
			this.passwordControl.setFocus();
		}, 1);
	}

	private loginOK(user: User) {
		this.authenticationService.defaultUserName = user.rememberMe ? user.username : '';
		this.authenticationService.defaultLoginScheme = AuthenticationService.LOGIN_STYLE_LEGACY;


		this.loggedSuccessfully = true;
		/// Wait before the redirect to let the narrator read the alert
		setTimeout(() => this.router.navigate(['/']), 100);
	}

	private setInitialFocus() {
		if (this.loginScheme !== AuthenticationService.LOGIN_STYLE_LEGACY) {
			return;
		}

		const formValue = this.form.value;
		if (!formValue.username) {
			this.usernameControl.setFocus();
		} else {
			this.passwordControl.setFocus();
		}

		this.cdRef.detectChanges();
	}

	private buildForm() {
		this.form = this.fb.group({
			username: ['', Validators.required],
			password: ['', Validators.required],
			oldPassword: ['', Validators.required],
			newPassword: ['', Validators.required],
			newPasswordConfirm: ['', Validators.required],
			rememberMe: [''],
		});

		this.setMode(Mode.Login);
	}

	private setMode(mode: Mode) {
		switch (mode) {
			case Mode.Login:
				this.form.controls.username.enable();
				this.form.controls.password.enable();
				this.form.controls.password.reset();

				this.form.controls.oldPassword.disable();
				this.form.controls.newPassword.disable();
				this.form.controls.newPasswordConfirm.disable();

				this.passwordExpired = false;
				break;
			case Mode.ChangePassword:
				this.form.controls.username.disable();
				this.form.controls.password.disable();
				this.form.controls.password.reset();

				this.form.controls.oldPassword.enable();
				this.form.controls.newPassword.enable();
				this.form.controls.newPasswordConfirm.enable();

				this.passwordExpired = true;
				break;
			case Mode.StaleClient:
				this.form.controls.username.disable();
				this.form.controls.password.disable();
				break;
			default:
				break;
		}

		this.form.controls.password.reset();
		this.form.controls.oldPassword.reset();
		this.form.controls.newPassword.reset();
		this.form.controls.newPasswordConfirm.reset();
	}

	private determineLoginSchemes(options: IOptions) {
		// If an options object was provided, configure the available login schemes from its settings.
		if (options) {
			this.loginSchemes[AuthenticationService.LOGIN_STYLE_LEGACY] = !options.disableLegacyLogin;
			this.loginSchemes[AuthenticationService.LOGIN_STYLE_B2C] = options.enableB2cLogin;
			this.loginSchemes[AuthenticationService.LOGIN_STYLE_B2C_CORP] = options.enableB2cCorporateLogin;
		}

		// Count the number of login schemes so we can hide the selector if there's only one.
		let schemeCount = 0;
		for (let scheme in this.loginSchemes) {
			if (this.loginSchemes[scheme]) {
				schemeCount++;
			}
		}
		this.hasMultipleLoginSchemes = schemeCount > 1;

		// Check the default login scheme is available.
		let defaultLoginScheme = this.authenticationService.defaultLoginScheme;
		if (defaultLoginScheme && !this.loginSchemes[defaultLoginScheme]) {
			defaultLoginScheme = '';
		}

		// If there's no default login scheme, choose the first configured one.
		if (!defaultLoginScheme) {
			for (let scheme in this.loginSchemes) {
				if (this.loginSchemes[scheme]) {
					defaultLoginScheme = scheme;
					break;
				}
			}
		}
		this.loginScheme = defaultLoginScheme || AuthenticationService.LOGIN_STYLE_LEGACY;
	}

	//Calendar configuration for Health Canada
	private setAccessibility (){
console.log ('Setting Accessibility') //TEST123
		document.documentElement.style.setProperty('--calendar-available-background', '#fff');
		document.documentElement.style.setProperty('--calendar-techonly-background', '#c4d6f750'); //rgba('#c4d6f7', 0.3)
		document.documentElement.style.setProperty('--calendar-unavailable-background', '#ff00001a'); //rgba(255, 0, 0, 0.1)
		document.documentElement.style.setProperty('--calendar-start-background', '#ff00001a'); //rgba(255, 0, 0, 0.1)
		document.documentElement.style.setProperty('--calendar-selected-background', '#e0e0e0');
		document.documentElement.style.setProperty('--calendar-available-color', 'black');
		document.documentElement.style.setProperty('--calendar-techonly-color', 'black');
		document.documentElement.style.setProperty('--calendar-unavailable-color', 'black');
		document.documentElement.style.setProperty('--calendar-start-color', 'black');
	}

}
