<div class="survey" *ngIf="questionnaire && !(questionnaire.noData || questionnaire.error)">
	<div class="header">
		<div class="survey-name">{{ questionnaire.surveyName }}</div>

		<div class="overall-status">
			<pp-completion-percent
				[count]="questionnaire.questionsAnswered"
				[total]="questionnaire.questionsAsked"
			></pp-completion-percent>

			<div class="status">{{ questionnaire.status }}</div>
		</div>
	</div>

	<div class="survey-detail">
		<pp-clinical-profile-display [clinicalProfile]="questionnaire.clinicalProfile"></pp-clinical-profile-display>

		<pp-risk-profile-display [riskProfile]="questionnaire.riskProfile"></pp-risk-profile-display>
	</div>
</div>

<div class="no-data" *ngIf="questionnaire && questionnaire.noData">
	<h2>There is no client questionnaire available.</h2>
</div>

<div class="error" *ngIf="questionnaire && questionnaire.error">
	<h2>An error occurred retrieving the client questionnaire ({{ questionnaire.error }}). Please try again later.</h2>
</div>
