import {
	Component,
	Input,
	Output,
	ElementRef,
	EventEmitter,
	AfterViewInit,
	OnDestroy,
	ViewEncapsulation,
} from '@angular/core';
import * as $ from 'jquery';
import 'fullcalendar';
import 'fullcalendar-scheduler';

export class EventContextEvent {
	mouseEvent: MouseEvent;
	appointmentId: number;
}

export class DayContextEvent {
	mouseEvent: MouseEvent;
	dayIndex: number;
	time: string;
	resource: object;
}

@Component({
	selector: 'pp-full-calendar-scheduler',
	template: '',
	encapsulation: ViewEncapsulation.None,
})
export class FullCalendarSchedulerComponent implements AfterViewInit, OnDestroy {
	@Input() options: any;

	@Output() initialized: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() keydown: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();
	@Output() keyup: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();
	@Output() eventContext: EventEmitter<EventContextEvent> = new EventEmitter<EventContextEvent>();
	@Output() dayContext: EventEmitter<DayContextEvent> = new EventEmitter<DayContextEvent>();

	constructor(private element: ElementRef) { }

	ngAfterViewInit() {
		let that = this;
		setTimeout(() => {
			$('pp-full-calendar-scheduler').fullCalendar(this.options);
			this.initialized.emit(true);

			$(document).on('keydown', (e) => {
				let ke = <KeyboardEvent>e.originalEvent;
				this.keydown.emit(ke);
			});
			$(document).on('keyup', (e) => {
				let ke = <KeyboardEvent>e.originalEvent;
				this.keyup.emit(ke);
			});

			$(document).on('contextmenu', (jqe) => {
				let e = <MouseEvent>jqe.originalEvent;
				let isEvent = false;
				let isSlot = false;
				let time;
				let appointmentId;
				let resourceObject;
				let dayIndex;
				let j = $(e.target).closest('.fc-event');
				if (j.length) {
					appointmentId = j.data('appointmentId');
					isEvent = true;
				} else {
					if ($(e.target).closest('.fc-axis').length) {
						return;
					}

					const scrollTop = $('.fc-scroller').scrollTop();
					const scrollerTop = $('.fc-scroller').offset().top;

					const timeCells = $(e.target).closest('.fc-view-container').find('.fc-axis.fc-time');
					const targetTop = e.clientY + scrollTop - scrollerTop;
					const timeCell = timeCells
						.toArray()
						.reverse()
						.find((c) => (<HTMLElement>c).offsetTop < targetTop);

					j = $(timeCell).closest('tr');
					if (j.length) {
						time = j.data('time');
						isSlot = !!time;

						if (!isSlot) {
							return;
						}

						let t = $(e.target).closest('.fc-agendaDay-view');
						let calculateDay = true;
						if (t.length) {
							dayIndex = 0;
							calculateDay = false;
						} else {
							t = $(e.target).closest('.fc-agendaWeek-view');
						}
						let resourceCells = t.find('th.fc-resource-cell');
						const calLeft = t.offset().left;
						const cellsLeft = resourceCells.offset().left;
						const pageX = e.pageX;
						const cellLeft = pageX - cellsLeft;
						const cellWidth = (<HTMLElement>resourceCells[0]).clientWidth;
						const resourceIndex = Math.floor(cellLeft / cellWidth);

						if (calculateDay) {
							dayIndex = Math.floor(cellLeft / (cellWidth / 7)) % 7;
						}

						let resources = this.fullCalendar('getResources');
						resourceObject = resources[resourceIndex];
					}
				}
				if (!isEvent && !isSlot) {
					return;
				}

				e.preventDefault();

				if (isEvent) {
					this.eventContext.emit({
						mouseEvent: e,
						appointmentId,
					});
				} else if (isSlot) {
					this.dayContext.emit({
						mouseEvent: e,
						dayIndex,
						time,
						resource: resourceObject,
					});
				}
			});
		}, 100);
	}

	ngOnDestroy() {
		$(document).off('keydown');
		$(document).off('keyup');
		$(document).off('contextmenu');
	}

	fullCalendar(...args: any[]) {
		if (!args) {
			return;
		}

		let cal = $(this.element.nativeElement);

		let debug = false;

		switch (args.length) {
			case 0:
				return;
			case 1:
				debug && console.log('fullCalendar', args[0]);
				return cal.fullCalendar(args[0]);
			case 2:
				debug && console.log('fullCalendar', args[0], args[1]);
				return cal.fullCalendar(args[0], args[1]);
			case 3:
				debug && console.log('fullCalendar', args[0], args[1], args[2]);
				return cal.fullCalendar(args[0], args[1], args[2]);
			case 4:
				debug && console.log('fullCalendar', args[0], args[1], args[2], args[3]);
				return cal.fullCalendar(args[0], args[1], args[2], args[3]);
			default:
				break;
		}
	}
}
