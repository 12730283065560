<div fxLayout="column" cdkTrapFocus>
	<pp-modal-route-header 
		title="Edit client header"
		(result)="complete($event)">
	</pp-modal-route-header>
	
	<mat-tab-group fxFlex *ngIf="!loading">
		<mat-tab label="Details">
			<div fxLayout="column" class="tab-container">
				<pp-client-edit-details
					#details
					[client]="editDto">
				</pp-client-edit-details>
			</div>	
		</mat-tab>

		<mat-tab label="Statistics">
			<div fxLayout="column" class="tab-container">
				<pp-client-edit-statistics
					#statistics
					[client]="editDto">
				</pp-client-edit-statistics>
			</div>	
		</mat-tab>

		<mat-tab label="Sessions">
			<div fxLayout="column" class="tab-container">
				<pp-client-edit-sessions
					#sessions
					[client]="editDto">
				</pp-client-edit-sessions>
			</div>	
		</mat-tab>
	</mat-tab-group>
</div>