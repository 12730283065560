import { Component, Input, Output, EventEmitter, forwardRef, ViewChild, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseComponent } from './base-component';

let identifier = 0;

@Component({
	selector: 'pp-switch',
	template: `
<div class="form-component">
	<mat-slide-toggle
		[id]="identifier"
		[(ngModel)]="value"
		[disabled]="isDisabled"
		(blur)="onBlur()"
		(change)="handleChange($event)">
		{{ label }}
	</mat-slide-toggle>
</div>`,
	styleUrls: ['./switch.component.scss'],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SwitchComponent), multi: true }
	]
})
export class SwitchComponent extends BaseComponent<boolean> {
	@Input() label: string;
	@Output() onChange = new EventEmitter<boolean>();

	public identifier = `switch-${identifier++}`;

	@ViewChild('inputElement') inputEl: ElementRef;

	onBlur() {
		this.touch();
	}

	handleChange(state: boolean) {
		this.onChange.emit(state);
	}

	setFocus() {
		this.inputEl.nativeElement.focus();
		this.inputEl.nativeElement.select();
	}
}
