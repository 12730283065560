<mat-card fxFlexFill>
	<mat-card-title>
		<div fxLayout="row" fxLayoutAlign="start center">
			<a class="dashboard-hover" routerLink="/appointments">
				<h2>Appointments</h2>
			</a>
			<button *ngIf="hasICalUrl" mat-icon-button (click)="showICalDetails($event)" aria-label="Show iCal details">
				<mat-icon>perm_contact_calendar</mat-icon>
			</button>
			<span fxFlex></span>
			<button
				aria-label="Refresh appointments"
				mat-icon-button
				matTooltip="Refresh"
				class="hover"
				(click)="refresh()">
				<mat-icon>refresh</mat-icon>
			</button>
		</div>
	</mat-card-title>
	<mat-card-subtitle>Appointments in the next {{ numAppointmentDays }} days</mat-card-subtitle>
	<mat-divider></mat-divider>

	<ng-template tdLoading [tdLoadingUntil]="!loading" tdLoadingStrategy="overlay">
		<div class="data">
			<div *ngFor="let day of view">
				<div class="day-name" fxLayout="row">
					<h3>{{ day.name }}</h3>
					<span *ngIf="countNonLeaveAppointments(day) > 0" class="day-count">({{ countNonLeaveAppointments(day) }})</span>
					<span fxFlex class="date">{{ day.date | date: 'EEEE d MMM' }}</span>
				</div>
				<div *ngIf="day.appointments.length">
					<a
						[routerLink]="['/appointments', appointment.appointmentId]"
						class="appointment"
						*ngFor="let appointment of day.appointments"
						fxLayout="row">
						<div class="appointment-item" fxFlex="100%">
							<div fxLayout="row">
								<div class="times-continuing" *ngIf="isLeave(appointment) && appointment['continuing']">
									Continuing
								</div>
								<div class="times" fxFlex="130px">
									{{ appointment.dateTimeStart | date: 'shortTime' }} -
									{{ appointment.dateTimeEnd | date: 'shortTime' }}
								</div>
								<div
									class="times-since"
									*ngIf="isLeave(appointment) && appointment['sinceDateTimeStart']">
									since {{ appointment['sinceDateTimeStart'] | date: 'dd-MMM-yyyy h:mm a ' }}
								</div>
								<div class="times-until" *ngIf="isLeave(appointment) && appointment['toDateTimeEnd']">
									until {{ appointment['toDateTimeEnd'] | date: 'dd-MMM-yyyy h:mm a ' }}
								</div>
								<div fxLayout="row" fxFlex fxLayoutAlign="start start">
									<div class="booking-resource">({{ appointment.bookingResourceName }})</div>
									<div *ngIf="appointment.clientTimeZoneName" fxLayoutAlign="start start">
										<span class="client-time-header">Client</span>
										<span class="client-start-time">{{
											appointment.clientTimeStart | date: 'shortTime'
											}}</span>
										<span class="client-time-zone">({{ appointment.clientTimeZoneName }})</span>
									</div>
									<span fxFlex></span>
									<div *ngIf="isActivity(appointment)" fxLayout="row" fxLayoutAlign="start start">
										<div class="activity-type">{{ appointment.activityType }}</div>
										<div class="service-type">{{ appointment.serviceType }}</div>
									</div>
									<div *ngIf="isPrivate(appointment)" class="pa-header">Private apointment</div>
									<div *ngIf="isLeave(appointment)" class="pa-header">On leave</div>
								</div>
							</div>
							<div *ngIf="practiceMode" fxLayout="row">
								<mat-icon class="professional-icon">person</mat-icon>
								<div class="professional-name">{{ getProfessional(appointment.professionalId) }}</div>
							</div>
							<div *ngIf="isActivity(appointment)" class="roles">
								<div fxFlex="50">
									<div fxLayout="row">
										<div class="file-number" fxFlex="55px">{{ appointment.fileNumber }}</div>
										<div class="session-client">
											<div class="session-numbers">{{ getSessionNumber(appointment) }}</div>
											<div class="client-name">{{ appointment.clientNameOrDescription }}</div>

											<div *ngIf="appointment.isClientRedFlag" class="client-red-flag">
												&nbsp;<i class="fa fa-flag" style="color: red"></i>
											</div>

										</div>
										<div *ngIf="appointment.excludeAsSession" class="not-session">
											(Not a session)
										</div>
									</div>
									<div fxLayout="row" *ngIf="getPhoneNumbers(appointment)">
										<i class="fa fa-phone" aria-hidden="true"></i>
										<div *ngFor="let p of getPhoneNumbers(appointment)" class="phone-numbers">
											<a
												(click)="$event.stopPropagation()"
												class="phone-number"
												href="tel:{{ p.number }}">{{ p.text }}</a>
										</div>
									</div>
								</div>
								<div fxLayout="column" fxFlex>
									<div fxLayout="row">
										<div fxFlex *ngIf="appointment.customerName" class="customer-name">
											{{ appointment.customerName }}
										</div>
									</div>
									<div *ngIf="appointment.employerName" class="employer-name">
										{{ appointment.customerName }}
									</div>
								</div>
							</div>

							<div *ngIf="isPrivate(appointment)">
								<div class="pa-name">{{ appointment.privateAppointmentName }}</div>
								<div class="pa-location">{{ appointment.location }}</div>
							</div>

							<div *ngIf="isLeave(appointment)">
								<div class="pa-location">{{ appointment.location }}</div>
							</div>

							<div *ngIf="appointment.zoomInfoUrl" class="zoom-info">
								<span class="zoom-tag">Video URL</span>
								<div>
									<a
										(click)="$event.stopPropagation()"
										href="{{ appointment.zoomInfoUrl }}"
										target="_blank">{{ appointment.zoomInfoUrl }}</a>
								</div>
							</div>
						</div>
					</a>
				</div>
				<div *ngIf="!day.appointments.length">
					<p class="strong">No appointments</p>
				</div>
			</div>
		</div>
	</ng-template>
</mat-card>
