import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, RouterStateSnapshot, TitleStrategy, ActivatedRouteSnapshot } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { AuthGuard } from 'app/_guards';
import { LogoutGuard } from 'app/_guards';

import { MainComponent } from './main/main.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ActiveCasesComponent } from './professional-dashboard/active-cases/active-cases.component';
import { ActiveInvoicesComponent } from './professional-dashboard/active-invoices/active-invoices.component';
import { LoginComponent } from './login/login.component';

import { ProfessionalDashboardComponent } from './professional-dashboard/professional-dashboard.component';
import { DashboardAppointmentsComponent } from './professional-dashboard/dashboard-appointments/dashboard-appointments.component';


@Injectable()
export class BreadcrumbsTitleStrategy extends TitleStrategy {
	constructor(private readonly title: Title) {
		super();
	}

	override updateTitle(routerState: RouterStateSnapshot): void {
		const title: string = this.getRouteTitles(routerState.root)?.reverse().join(' - ');
		if (title !== undefined) {
			this.title.setTitle(`${title}`);
		}
	}

	private getRouteTitles(routerState: ActivatedRouteSnapshot, titles: string[] = []): string[] {
		const title: any = this.getResolvedTitleForRoute(routerState);
		if (typeof title === 'string' && title.length > 0) {
			titles.push(title);
		}

		if (routerState.children.length != 0) {
			for (const r of routerState.children) {
				titles.push(...this.getRouteTitles(r));
			}
		}
		return titles;
	}
}


const routes: Routes = [
	{
		path: '',
		title: 'Professional Portal',
		children: [
			{
				path: 'login',
				title: 'Login',
				component: LoginComponent,
				canActivate: [LogoutGuard],
			},
			{
				path: '',
				component: MainComponent,
				canActivate: [AuthGuard],
				title: '',
				children: [
					{
						path: 'dashboard',
						component: DashboardComponent,
						title: 'Dashboard',
					},
					{
						path: '',
						redirectTo: 'dashboard',
						pathMatch: 'full'
					},
					// All the modules below are lazy-loaded.
					{
						path: 'cases',
						loadChildren: () => import('./cases/cases.module').then(m => m.CasesModule),
					},
					{
						path: 'invoices',
						loadChildren: () => import('./invoices/invoices.module').then(m => m.InvoicesModule),
					},
					{
						path: 'appointments',
						loadChildren: () => import('./appointments/appointments.module').then(m => m.AppointmentsModule),
					},
					{
						path: 'availability',
						loadChildren: () => import('./availability/availability.module').then(m => m.AvailabilityModule),
					},
					{
						path: 'activity-query',
						loadChildren: () => import('./activity-query/activity-query.module').then(m => m.ActivityQueryModule),
			},
			{
				path: 'resource-hub',
				loadChildren: () => import('./resource-hub/resource-hub.module').then(m => m.ResourceHubModule)
			},
			{
				path: 'profile/credentials',
				loadChildren: () => import('./profile/credentials/credentials.module').then(m => m.CredentialsModule)
			},
			{
				path: 'profile/rates',
				loadChildren: () => import('./profile/rates/rates.module').then(m => m.RatesModule)
			},
			{
				path: 'profile/checks',
				loadChildren: () => import('./profile/checks/checks.module').then(m => m.ChecksModule)
			},
			{
				path: 'profile/notifications',
				loadChildren: () => import('./profile/notifications/notifications.module').then(m => m.NotificationsModule)
			},
			{
				path: 'profile/attachments',
				loadChildren: () => import('./profile/attachments/prof-attachment.module').then(m => m.ProfessionalAttachmentModule)
			},
			{
				path: 'profile/insurance',
				loadChildren: () => import('./profile/insurance/insurance.module').then(m => m.InsuranceModule)
			},
			{
				path: 'profile/workhealthsafety',
				loadChildren: () => import('./profile/workhealthsafety/workhealthsafety.module').then(m => m.WorkHealthSafetyModule)
			},
			{
				path: 'profile/offices',
				loadChildren: () => import('./profile/offices/offices.module').then(m => m.OfficesModule)
			},
			{
				path: 'profile/contact',
				loadChildren: () => import('./profile/contact/contact.module').then(m => m.ContactModule)
			},
			{
				path: 'announcements',
				loadChildren: () => import('./announcements/announcements.module').then(m => m.AnnouncementsModule)
			},
			{
				path: 'announcement',
				loadChildren: () => import('./announcements/announcement/announcement.module').then(m => m.AnnouncementModule)
					}
				]
			},
			{
				path: '*',
				redirectTo: ''
			}
		]
	},
];

@NgModule({
	imports: [
		// Configure the router to pre-load all lazily-loaded
		// modules.
		RouterModule.forRoot(routes, {
			//enableTracing: true, // <-- debugging purposes only
			preloadingStrategy: PreloadAllModules,
			relativeLinkResolution: 'legacy'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
export const routedComponents: any[] = [
	MainComponent,
	LoginComponent,
	DashboardComponent,
	ProfessionalDashboardComponent,
	ActiveCasesComponent,
	ActiveInvoicesComponent,
	DashboardAppointmentsComponent
];
