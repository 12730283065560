import { DataSource } from '@angular/cdk/collections';
import {
	Component,
	Input,
	AfterContentInit,
	ContentChildren,
	ContentChild,
	QueryList,
	ViewChild,
} from '@angular/core';
import {
	MatColumnDef,
	MatFooterRowDef,
	MatHeaderRowDef,
	MatNoDataRow,
	MatRowDef,
	MatTable,
	MatTableDataSource,
} from '@angular/material/table';

@Component({
	selector: 'pp-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
	host: {
		class: 'mat-elevation-z6',
	}
})
export class TableComponent<T> implements AfterContentInit {
	@ContentChildren(MatHeaderRowDef) headerRowDefs: QueryList<MatHeaderRowDef>;
	@ContentChildren(MatFooterRowDef) footerRowDef: QueryList<MatFooterRowDef>;
	@ContentChildren(MatRowDef) rowDefs: QueryList<MatRowDef<T>>;
	@ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;
	@ContentChild(MatNoDataRow) noDataRow: MatNoDataRow;

	@ViewChild(MatTable, { static: true }) table!: MatTable<T>;

	@Input() columns: string[];

	@Input() dataSource: MatTableDataSource<T> | DataSource<T> | any[];

	ngAfterContentInit() {
		this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
		this.rowDefs.forEach(rowDef => this.table.addRowDef(rowDef));
		this.headerRowDefs.forEach(headerRowDef => this.table.addHeaderRowDef(headerRowDef));
		this.footerRowDef.forEach(footerRowDef => this.table.addFooterRowDef(footerRowDef));
		this.table.setNoDataRow(this.noDataRow);
	}
}
