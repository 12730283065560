<form novalidate autocomplete="off" [formGroup]="form">
	<pp-text 
		[label]="type.description" 
		placeholder="" 
		formControlName="dec"
		[required]="type.isRequired"
		[numericOnly]="true"
		[maxIntDigits]="10"
		[allowNegative]="true"
		[decimalPlaces]="2">
	</pp-text>
</form>