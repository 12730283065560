import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import * as _ from 'lodash';
import {
	MatDialogRef,
	MAT_DIALOG_DATA
} from '@angular/material/dialog';

import {
	StatisticalChoiceDto,
	ModalService,
} from 'app/services';

@Component({
	selector: 'pp-add-choice-modal',
	templateUrl: './add-choice-modal.component.html',
	styleUrls: ['./add-choice-modal.component.scss']
})
export class AddChoiceModalComponent implements OnInit {
	form: UntypedFormGroup;
	choices: StatisticalChoiceDto[];
	filteredChoices: Observable<StatisticalChoiceDto[]>;

	constructor(
		public dialogRef: MatDialogRef<AddChoiceModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { choices: StatisticalChoiceDto[], choice: StatisticalChoiceDto },
		private fb: UntypedFormBuilder,
		private modal: ModalService,
	) { }

	ngOnInit() {
		this.buildForm();
		this.choices = <StatisticalChoiceDto[]>_.sortBy(this.data.choices, c => c.description);
		this.filteredChoices = this.form.controls.choice.valueChanges.pipe(
			startWith(undefined),
			map(v => v ? this.filter(v) : this.choices.slice())
		);
		this.modal.isOpen = true;
		this.dialogRef.afterClosed().subscribe(() => this.modal.isOpen = false);
	}

	filter(val: string): StatisticalChoiceDto[] {
		const regex = new RegExp(`${val}`, 'gi');
		const result = this.choices.filter(c => regex.test(c.description));
		return result;
	}

	closeIfValid() {
		if (!this.form.valid) {
			return;
		}

		this.submit();
		this.dialogRef.close('ok');
	}

	submit() {
		const form = this.form;
		this.data.choice = form.value.choice;
	}

	displayFn(choice: StatisticalChoiceDto): string {
		return choice ? choice.description : '';
	}

	private buildForm() {
		this.form = this.fb.group({
			choice: ['', Validators.required]
		});
	}
}
