<div fxLayout="row">
	<button mat-icon-button
		[attr.aria-label]="asSave ? 'Save' : 'Done'"
		class="hover"
		[disabled]="isDisabled"
		(click)="apply()">
		<mat-icon matTooltip="{{ asSave ? 'Save' : 'Done' }}">{{ asSave ? 'save' : 'done' }}</mat-icon>
	</button>

	<button mat-icon-button
		aria-label="Close"
		class="hover"
		[disabled]="!active"
		(click)="cancel()">
		<mat-icon matTooltip="Close">close</mat-icon>
	</button>
</div>
