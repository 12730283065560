import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private jwtHelper: JwtHelperService) {}

	canActivate() {
		if (this.isAuthenticated()) {
			return true;
		}

		// not logged in so redirect to login page
		this.router.navigate(['/login']);
		return false;
	}

	isAuthenticated() {
		return !!sessionStorage.getItem(AuthenticationService.KEY_CURRENT_USER);
	}
}
