<div fxLayout="column">
	<pp-selection-grid
		#grid
		fxFlex
		[columnDefs]="columnDefs" 
		[rowData]="clients"
		(edit)="edit($event)"
		(delete)="delete($event)"
		(add)="add($event)"
		typeName="client"
		[confirmDelete]="true">
	</pp-selection-grid>
</div>