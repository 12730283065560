<div class="result-item" (click)="onSelect(result)" (dblClick)="onSelectComplete(result)">	
	<div fxLayout="row" fxLayoutAlign="start start">
		<div class="file-number" fxFlex="75px">{{ result.fileNumber }}</div>
		<div class="client-name">{{ result.name }}</div>
		<div *ngIf="result.isClientRedFlag" class="client-red-flag">
			&nbsp;<i class="fa fa-flag" style="color:red" aria-hidden="true"></i>
		</div>
		<span fxFlex></span>
		<div class="service-type" fxFlex="120px">{{ result.serviceType }}</div>
	</div>

	<div fxLayout="row" fxLayoutAlign="start start">
		<div class="activity-status" fxFlex="75px" [ngClass]="{ open: result.isOpen, closed: result.isClosed, 'referred-on': result.isReferredOn }">{{ result.activityStatus }}</div>
		<div fxLayout="row">
			<div class="opened-date">
				<span class="data-header">Opened: </span><span class="data">{{ result.openedDateTime | date:'EEE d MMM y' }}</span>	
			</div>
			<div *ngIf="result.isClosed" class="closed-date">
				<span class="data-header closed">Closed: </span><span class="data">{{ result.closedDateTime | date:'EEE d MMM y' }}</span>	
			</div>
		</div>
	<div fxLayout="row">
		<span fxFlexOffset="80px" class="customer-employer">{{ getCustomerOrEmployer(result) }}</span>
		<span *ngIf="isManager()" class="manager">{{ getManager(result) }}</span>
	</div>
</div>
