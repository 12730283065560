import { Directive, Host, Self, Optional } from '@angular/core';
import { MatOption } from '@angular/material/core';
@Directive({
	selector: 'mat-option',
	host: {
		/// Add a directive to mat-option
		/// to have `aria-selected` default to false
		/// for the screen reader
		'[attr.aria-selected]': 'selected',
	},
})
export class MatOptionDirective {

	get selected(): boolean {
		return this.matOption?.selected ?? false;
	}

	constructor(
		@Host() @Self() private matOption: MatOption,
	) { }

}
