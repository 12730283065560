import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as moment from 'moment';

import { AppointmentDto, CommAddressDisplayDto, ConfigService } from 'app/services';
import { ExtendedDetailsHelper } from 'app/common-editing/extended-details/extended-details';

const intMaxValue = Math.pow(2, 31) - 1;

@Injectable({
	providedIn: 'root',
})
export class AppointmentRendererService {
	constructor(private domSanitizer: DomSanitizer, 
		private config: ConfigService) {}

	getActivityAppointmentHtml(appointment: AppointmentDto, updating: boolean, partsText: string[] = null): string {
		const parts = [];
		partsText = partsText || [];
		const addPart = (type, value, additionalClass = '') => {
			const sanitized = this.domSanitizer.sanitize(SecurityContext.HTML, value);
			const part = `<span class="${type} ${additionalClass}">${sanitized}</span>`;
			parts.push(part);
			partsText.push(value);
		};
		const addBreak = () => {
			parts.push('</br>');
			partsText.push('\r\n');
		};

		const addFlagIcon = (index) => {

			if (index == null) index = 0;
			let redFlagColour = ExtendedDetailsHelper.redFlagAlternativeAppearenceColours[index];
			let part1 = `<i class="fa fa-flag fa-2x" style="color: ` + redFlagColour + `; position: absolute;right:8px;margin-top:4px"></i>`
			parts.push(part1);
			let part2 = `<i class="fa fa-flag-o fa-2x" style="color: red; position: absolute;right:8px;margin-top:4px"></i>`
			parts.push(part2);
			partsText.push('\r\n');
		};

		if (appointment.isReserved) {
			addPart('reserved-appointment', 'Reserved and locked - pending appointment confirmation');
			addBreak();
		}

		addPart('file-number', appointment.fileNumber, 'part');
		addPart('activity-type', appointment.activityType, 'part');
		addPart('service-type', appointment.serviceType, 'part');

		if (appointment.sessionNumber && !appointment.excludeAsSession) {
			const sessionNumbers = this.getSessionNumberDisplay(appointment);
			addPart('session-numbers', sessionNumbers);
		}

		if (appointment.isRedFlag
			&& appointment.hasExtendedActivityHeader
			&& !appointment.hideRedFlag) {
			//console.log('appointment.redFlagAlternativeAppearance',appointment.redFlagAlternativeAppearance)
			addFlagIcon(appointment.redFlagAlternativeAppearance);
		}

		if (this.config.systemPreferences.useAppointmentMoveLog
			&& appointment.numberMoves > 0)
			addPart('number-moves', ' (Moved: ' + appointment.numberMoves + ")", 'moved');

		addBreak();

		if (appointment.clientTimeZoneName) {
			addPart('client-time-header', 'Client time');
			addPart('client-time', moment(appointment.clientTimeStart).format('h:mm a'));
			addPart('client-timezone', `(${appointment.clientTimeZoneName})`);
			addBreak();
		}

		addPart('client-name', appointment.clientNameOrDescription);
		
		if (appointment.isClientRedFlag)
		{
			parts.push(`<i class="fa fa-flag" style="color: red"></i>`);
		}

		addBreak();
		if (appointment.customerName) {
			addPart('customer', appointment.customerName);
			addBreak();
		}
		if (appointment.employerName) {
			addPart('employer', appointment.employerName);
			addBreak();
		}

		if (appointment.phoneNumbers && appointment.phoneNumbers.length) {
			const numStrings = [];
			for (let i = 0; i < appointment.phoneNumbers.length; i++) {
				let num = appointment.phoneNumbers[i];
				let str = this.formatPhoneNumber(num);

				// Only add unique strings to prevent numbers being displayed twice.
				if (numStrings.indexOf(str) === -1) {
					numStrings.push(str);
					addPart('phone-number', str);
					addBreak();
				}
			}
		}

		if (appointment.staticalChoiceForDiaryDisplay) {
			addPart('choice-display', appointment.staticalChoiceForDiaryDisplay);
			addBreak();
		}

		if (appointment.isRecurring) {
			partsText.push('Recurring');
		}

		if (appointment.isCanelledChargeable) {
			partsText.push('Cancelled chargeable');
		}

		if (appointment.location) {
			addPart('location', `Loc: ${appointment.location}`);
		}

		let html = `
			<div class="activity-appointment">
				${parts.join('\r\n')}
			</div>`;
		return html;
	}

	getActivityAppointmentHtmlSanitized(
		appointment: AppointmentDto,
		updating: boolean,
		partsText: string[] = null
	): SafeHtml {
		return this.domSanitizer.bypassSecurityTrustHtml(
			this.getActivityAppointmentHtml(appointment, updating, partsText)
		);
	}

	getSessionNumberDisplay(appointment: AppointmentDto) {
		if (appointment.excludeAsSession) {
			return '';
		}

		let maxStr = '';
		if (appointment.maxSessions < intMaxValue) {
			maxStr = `(${appointment.maxSessions})`;
		}

		return appointment.sessionNumber + maxStr;
	}

	formatPhoneNumber(p: CommAddressDisplayDto) {
		let phoneParts = [];
		let hasCallingCode;
		if (p.callingCode) {
			phoneParts.push(`+${p.callingCode}`);
			hasCallingCode = true;
		}

		if (p.areaCode) {
			let areaCode = p.areaCode;
			if (areaCode[0] === '0' && hasCallingCode) {
				areaCode = areaCode.substr(1);
			}
			phoneParts.push(areaCode);
		}

		if (p.number) {
			let num = p.number;
			if (hasCallingCode && num[0] === '0') {
				num = num.substr(1);
			}
			phoneParts.push(num);
		}

		return phoneParts.join(' ');
	};
}
