<div fxLayout="column">
	<mat-tab-group (selectedTabChange)="currentIndex = $event.index" fxFlex>
		<mat-tab
			*ngFor="let statsContainer of containers; index as i"
			[label]="statsContainer.statisticalGroup.description"
		>
			<ng-template matTabContent>
				<pp-statistic-container
					[container]="statsContainer"
					[isCurrent]="i === currentIndex"
					(changed)="markChanged($event)"
				>
				</pp-statistic-container>
			</ng-template>
		</mat-tab>
	</mat-tab-group>
</div>
