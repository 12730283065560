<div fxLayout="column">
	<form novalidate autocomplete="off" [formGroup]="form">
		<div
			fxLayout="column"
			fxLayout.gt-sm="row"
			fxLayoutAlign="start start"
			fxLayoutGap.gt-sm="16px"
		>
			<pp-text
				#search
				(focus)="filter.closeFilter()"
				label="Search"
				placeholder="Text or number search"
				formControlName="searchText"
				fxFlex
			>
			</pp-text>
			<pp-search-filter
				#filter
				[form]="form"
				openLabel="Active only"
				fxFlex
			></pp-search-filter>
		</div>
	</form>

	<div
		class="search-progress"
		[fxHide]="!(searchService.searching | async)"
		fxFlex
		fxLayout="row"
		fxLayoutAlign="center center"
	>
		<mat-spinner></mat-spinner>
	</div>

	<div
		class="results-items"
		[fxHide]="searchService.searching | async"
		fxFlex
	>
		<!-- Delete Me: Not working, repalced <cdk-virtual-scroll-viewport> with <virtual-scroll> 
		<cdk-virtual-scroll-viewport itemSize="60" fxFlex>
			<div
				*cdkVirtualFor="let result of searchService?.lastResult?.items"
				class="result-item"
				(click)="onSelect(result)"
			>
				<div fxLayout="row" fxLayoutAlign="start start">
					<div class="number" fxFlex="75px">{{ result.number }}</div>
					<div class="professional-name" fxFlex="50">
						{{ result.professional }}
					</div>
					<div fxHide.lt-md="true" class="opened-date" fxFlex>
						<span class="data-header">Invoice date: </span
						><span class="data">{{
							result.openedDate | date
						}}</span>
					</div>
					<div class="amount" fxFlex="80px">
						{{ formatAmount(result.netAmount) }}
					</div>
				</div>

				<div *ngIf="result.reference" fxLayout="row">
					<span fxFlex="75px"></span>
					<div class="reference">{{ result.reference }}</div>
				</div>

				<div fxHide.lt-md="true" fxLayout="row">
					<span fxFlex="80px"></span>
					<div
						[fxHide]="!result.releasedDate"
						class="released-date"
						fxFlex="25"
					>
						<span class="data-header">Released: </span
						><span class="data">{{
							result.releasedDate | date
						}}</span>
					</div>
					<div
						[fxHide]="!result.approvedDate"
						class="accepted-date"
						fxFlex="25"
					>
						<span class="data-header">Accepted: </span
						><span class="data">{{
							result.approvedDate | date
						}}</span>
					</div>
					<div
						[fxHide]="!result.paidDate"
						class="approved-date"
						fxFlex="25"
					>
						<span class="data-header">Approved: </span
						><span class="data">{{ result.paidDate | date }}</span>
					</div>
				</div>

				<div fxHide.gt-sm="true" fxLayout="column">
					<div class="opened-date" fxLayout="row">
						<span class="data-header" fxFlex="80px"
							>Invoice date: </span
						><span class="data">{{
							result.openedDate | date
						}}</span>
					</div>
					<div
						[fxHide]="!result.releasedDate"
						class="released-date"
						fxLayout="row"
					>
						<span class="data-header" fxFlex="80px">Released: </span
						><span class="data">{{
							result.releasedDate | date
						}}</span>
					</div>
					<div
						[fxHide]="!result.approvedDate"
						class="accepted-date"
						fxLayout="row"
					>
						<span class="data-header" fxFlex="80px">Accepted: </span
						><span class="data">{{
							result.approvedDate | date
						}}</span>
					</div>
					<div
						[fxHide]="!result.paidDate"
						class="approved-date"
						fxLayout="row"
					>
						<span class="data-header" fxFlex="80px">Approved: </span
						><span class="data">{{ result.paidDate | date }}</span>
					</div>
				</div>
			</div>
		</cdk-virtual-scroll-viewport>
		-->

		<virtual-scroll #scroll [items]="searchService?.lastResult?.items" fxFlex>
			<div *ngFor="let result of scroll.viewPortItems">
				<div class="result-item" (click)="onSelect(result)">
					<div fxLayout="row" fxLayoutAlign="start start">
						<div class="number" fxFlex="75px">{{ result.number }}</div>
						<div class="professional-name" fxFlex="50">{{ result.professional }}</div>
						<div fxHide.lt-md="true" class="opened-date" fxFlex>
							<span class="data-header">Invoice date: </span><span class="data">{{ result.openedDate | date }}</span>	
						</div>
						<div class="amount" fxFlex="80px">{{ formatAmount(result.netAmount) }}</div>
					</div>

					<div *ngIf="result.reference" fxLayout="row">
						<span fxFlex="75px"></span>
						<div class="reference">{{ result.reference }}</div>
					</div>

					<div fxHide.lt-md="true" fxLayout="row">
						<span fxFlex="80px"></span>
						<div [fxHide]="!result.releasedDate" class="released-date" fxFlex="25">
							<span class="data-header">Released: </span><span class="data">{{ result.releasedDate | date }}</span>	
						</div>
						<div [fxHide]="!result.approvedDate" class="accepted-date" fxFlex="25">
							<span class="data-header">Accepted: </span><span class="data">{{ result.approvedDate | date }}</span>	
						</div>
						<div [fxHide]="!result.paidDate" class="approved-date" fxFlex="25">
							<span class="data-header">Approved: </span><span class="data">{{ result.paidDate | date }}</span>	
						</div>
					</div>

					<div fxHide.gt-sm="true" fxLayout="column">
						<div class="opened-date" fxLayout="row">
							<span class="data-header" fxFlex="80px">Invoice date: </span><span class="data">{{ result.openedDate | date }}</span>
						</div>
						<div [fxHide]="!result.releasedDate" class="released-date" fxLayout="row">
							<span class="data-header" fxFlex="80px">Released: </span><span class="data">{{ result.releasedDate | date }}</span>	
						</div>
						<div [fxHide]="!result.approvedDate" class="accepted-date" fxLayout="row">
							<span class="data-header" fxFlex="80px">Accepted: </span><span class="data">{{ result.approvedDate | date }}</span>	
						</div>
						<div [fxHide]="!result.paidDate" class="approved-date" fxLayout="row">
							<span class="data-header" fxFlex="80px">Approved: </span><span class="data">{{ result.paidDate | date }}</span>	
						</div>
					</div>
				</div>
			</div>
		</virtual-scroll>


	</div>
</div>
