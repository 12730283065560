<form novalidate autocomplete="off" [formGroup]="form" fxLayout="column">
	<div *ngIf="editDto.isShowClientPartner && !editDto.isDescriptionOnly">
		<div fxLayout="row">
			<div fxFlex fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="16px">
				<pp-display fxFlex="50" fxFlex.gt-md="230px"  [value]="editDto.clientPartnerFirstName" label="Partner first name"> </pp-display>
				<pp-display fxFlex="50" fxFlex.gt-md="230px" [value]="editDto.clientPartnerLastName" label="Partner last name"> </pp-display>
			</div>
		</div>

		<pp-comm-addr-editor
			[commAddresses]="editDto.clientPartnerCommunicationAddresses"
			[countries]="loadDto.countries"
			[readOnly]="true"
		></pp-comm-addr-editor>
	</div>
</form>
