import {
	Directive,
	Attribute,
	Host,
	Self,
	Optional,
} from '@angular/core';
import { MatSortHeader } from '@angular/material/sort';

@Directive({
	selector: '[mat-sort-header]',
	exportAs: 'matSortHeader',
})
export class MatSortHeaderDirective {

	get id(): string {
		return this._header.id;
	}

	constructor(
		@Host() @Self() @Optional() private _header: MatSortHeader,
		@Attribute('mat-sort-description') public description
	) {
		// console.log(this.matSortLabel);
		// console.trace();
	}

}
