<mat-card fxFlexFill>
	<mat-card-title>
		<div fxLayout="row" fxLayoutAlign="start center">
			<a class="dashboard-hover" routerLink="/invoices">
				<h2>Active invoices</h2>
			</a>
			<span fxFlex></span>
			<button aria-label="Refresh active invoice" class="hover" matTooltip="Refresh" mat-icon-button (click)="refresh()">
				<mat-icon>refresh</mat-icon>
			</button>
		</div>
	</mat-card-title>

	<mat-divider></mat-divider>

	<ng-template tdLoading [tdLoadingUntil]="!loading" tdLoadingStrategy="overlay">
		<div class="counts">
			<div>{{ invoices?.openCount | zeroToText:'No' }} active invoice{{ invoices?.openCount !== 1 ? 's' : '' }}</div>
			<div class="incomplete" *ngIf="invoices?.incompleteCount">{{ invoices?.incompleteCount }} need attention</div>
		</div>
	</ng-template>
</mat-card>
