<h1 mat-dialog-title>
	iCal Integration
</h1>

<div mat-dialog-content>
	<div class="doc">
		How to set up your iCal integration:
		<a target="_blank" [href]="iCalUrls.guideUrl">User guide</a>
	</div>

	<div class="personal">
		<mat-form-field>
			<mat-label>Your personal URL</mat-label>
			<input
				spellcheck="false"
				(keydown)="onKeydown($event)"
				#input
				matInput
				[(ngModel)]="iCalUrls.professionalUrl" />
		</mat-form-field>
	</div>

	<button #copyButton (click)="copyToClipboard()" mat-raised-button>Copy to clipboard</button>
</div>

<div mat-dialog-actions align="end">
	<button mat-button matDialogClose>Close</button>
</div>
