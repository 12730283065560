import { Component, Input, Output, EventEmitter } from '@angular/core';
import { StatisticContainerDto } from 'app/services';
import { IEditComponent } from 'app/interfaces';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
	selector: 'pp-statistics',
	templateUrl: './statistics.component.html',
	styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements IEditComponent {
	private hasUnsavedChanges: boolean;
	tabsVisible = true;
	currentIndex = 0;

	@Input() containers: StatisticContainerDto[];
	@Output() changed = new EventEmitter<void>();

	hasChanges(): boolean {
		return this.hasUnsavedChanges;
	}

	markClean() {
		this.hasUnsavedChanges = false;
	}

	markChanged(container: StatisticContainerDto) {
		this.hasUnsavedChanges = true;
		this.changed.emit();
	}

	selectedTabChanged(evt: MatTabChangeEvent) {
		this.currentIndex = evt.index;
	}
}
