import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

import { TextComponent } from './text.component';

@Component({
	selector: 'pp-text-plain',
	template: `
<div class="form-component">
	<div fxLayout="row">
		<input
			matInput
				#inputElement
				[id]="identifier"
				[type]="type"
				[placeholder]="placeholder"
				[maxlength]="maxLength"
				[disabled]="isDisabled"
			[(ngModel)]="value"
			[ngClass]="{ 'invalid': !isValid }"
			[textMask]="{mask: textMask}"
			(blur)="onBlur()"
			(focus)="onFocus()">
		</div>
</div>`,
	styleUrls: ['./text-plain.component.scss'],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TextPlainComponent), multi: true },
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => TextPlainComponent), multi: true }
	]
})
export class TextPlainComponent extends TextComponent {
}
