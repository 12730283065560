import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { BaseService } from 'app/services/base-service';
import { CommonService, AnnouncementsDto, AnnouncementDto } from 'app/services';
import { MessageDialogService } from './../services/message-dialog.service';

@Injectable()
export class AnnouncementsService extends BaseService {
	private announcementsUrl = 'api/announcements';
	private announcementUrl = 'api/announcement';

	//unread announcements will be broadcast to subscribers
	private unread: BehaviorSubject<number> = new BehaviorSubject<number>(-2);
	public unreadObservable: Observable<number> = this.unread.asObservable();
	public showUnreadOnly: boolean = true;

	_messageDialog: MessageDialogService;
	announcement: AnnouncementDto;

	constructor(common: CommonService,
		private messageDialog: MessageDialogService) {
		super(common);
		this._messageDialog = messageDialog;
	}

	//Get unread announcements 
	getAnnouncementsUnread(priority : boolean) {
		return this.crud.loadWithGet<string>(this.announcementsUrl + `/unread/${priority}`);
	}

	//Get unread announcements and notify subscribers
	updateAnnouncementsUnread() {
		this.getAnnouncementsUnread(false)
			.subscribe(result => {
				try {
					this.unread.next(parseInt(result));
				} catch (error) {
					this.unread.next(-2);
				}
			});
	}

	getAnnouncements(): Observable<AnnouncementsDto> {
		return this.crud.loadWithGet(this.announcementsUrl);
	}
	
	getAnnouncement(id: string): Observable<AnnouncementDto> {
		return this.crud
			.loadWithGet<AnnouncementDto>(this.announcementUrl + `/${id}`)
			.pipe(map(dto => this.onLoaded(dto)));
	}

	onLoaded(loadDto: AnnouncementDto) {
		this.updateAnnouncementsUnread();
		return loadDto;
	}

	claim (ent : AnnouncementDto, hideSpinner : () => void) {
		var decRead = !ent.isRead;
		this.claimAnnouncement(ent.id)
		.pipe(
			catchError((err) => {
				console.log(err, 'claim announcement');
				hideSpinner();
				return EMPTY;
			})
		)
		.subscribe(
			(result : any) => {
				if (result.isOk) {
					ent.isClaimed = true;	
					ent.isClaimable = false;
					if (decRead) {
						ent.isRead = true;	
						this.updateAnnouncementsUnread();
					}
				}
				var message = result.isOk? result.resultDto : result.errorMessage;
				message = message.replace ('\n','<br>');
				this.messageDialog.message(message);
				hideSpinner();
			}
		);
	}

	claimAnnouncement(id: string): Observable<AnnouncementDto> {
		return this.crud
			.loadWithPost<AnnouncementDto>(this.announcementUrl + `/claim/${id}`, null);
	}

	decline (ent : AnnouncementDto, hideSpinner : () => void) {
		var decRead = !ent.isRead;
		this.declineAnnouncement(ent.id)
		.pipe(
			catchError((err) => {
				console.log(err, 'decline announcement');
				hideSpinner();
				return EMPTY;
			})
		)
		.subscribe(
			(result : any) => {
				if (result.isOk) {
					ent.isDeclined = true;	
					ent.isClaimable = false;
					if (decRead) {
						ent.isRead = true;	
						this.updateAnnouncementsUnread();
					}
				}
				hideSpinner();
				this.messageDialog.message(result.isOk? result.resultDto : result.errorMessage);
			}
		);
	}

	declineAnnouncement(id: string): Observable<AnnouncementDto> {
		return this.crud
			.loadWithPost<AnnouncementDto>(this.announcementUrl + `/decline/${id}`, null);
	}

	readAnnouncement(id: string): Observable<AnnouncementDto> {
		return this.crud
			.loadWithPost<AnnouncementDto>(this.announcementUrl + `/read/${id}`, null);
	}

	getLoadedAnnouncement() {
		return this.announcement;
	}

	setLoadedAnnouncement(announcement: AnnouncementDto) {
		this.announcement = announcement;
	}
}
