<pp-container-controls
	*ngIf="container.statisticalGroup.uiTypeName === 'ControlContainer'"
	[container]="container"
	(changed)="valueUpdated($event)"
>
</pp-container-controls>

<pp-container-pick-list-grid
	*ngIf="container.statisticalGroup.uiTypeName === 'PickListGrid'"
	[container]="container"
	(changed)="valueUpdated($event)"
>
</pp-container-pick-list-grid>

<pp-container-two-num
	*ngIf="container.statisticalGroup.uiTypeName === 'TwoNum'"
	[container]="container"
	(changed)="valueUpdated($event)"
>
</pp-container-two-num>

<pp-container-individual-grid
	*ngIf="container.statisticalGroup.uiTypeName === 'TabContainerIndividualGrid'"
	[container]="container"
	(changed)="valueUpdated($event)"
>
</pp-container-individual-grid>

<pp-eap-services-client-questionnaire
	*ngIf="container.statisticalGroup.uiTypeName === 'EapServicesClientQuestionnaire'"
	[active]="isCurrent"
>
</pp-eap-services-client-questionnaire>

<pp-container-pick-and-add-two-num
	*ngIf="container.statisticalGroup.uiTypeName === 'PickAndAddTwoNum'"
	[container]="container"
	[header]="header"
	(changed)="valueUpdated($event)"
>
</pp-container-pick-and-add-two-num>
