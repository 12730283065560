<form novalidate autocomplete="off" [formGroup]="form" fxLayout="row">
	<pp-select 
		fxFlex.xs="300px"
		fxFlex.gt-xs="400px" 
		[options]="type.statisticalChoices" 
		[label]="type.description" 
		labelKey="description"
		[placeholder]="type.description" 
		[required]="type.isRequired"
		[nullable]="true"
		formControlName="statisticalChoiceId">
	</pp-select>
</form>
