<div class="component-container">
	<form novalidate autocomplete="off" [formGroup]="form" fxLayout="row">
		<div fxLayout="column">
			<pp-text class="wide" fxFlex="50" fxFlex.gt-md="60px" formControlName="addressLine1" label="Line 1"></pp-text>
			<pp-text fxFlex="50" fxFlex.gt-md="60px" formControlName="addressLine2" label="Line 2"></pp-text>
			<pp-text fxFlex="50" fxFlex.gt-md="60px" formControlName="addressLine3" label="Line 3"></pp-text>
			<div *ngIf="editDto.isSuburb">
				<pp-text readOnly class="wide" formControlName="suburb" label="Suburb"></pp-text>
			</div>
			<pp-text readOnly fxFlex="50" fxFlex.gt-md="60px" formControlName="city" label="City"></pp-text>
			<div *ngIf="editDto.isState">
				<pp-text readOnly class="narrow" formControlName="state" label="State"></pp-text>
			</div>
			<div fxLayout="row">
			<pp-text readOnly class="narrow" formControlName="postcode" label="Postcode" (click)="openLookup()"></pp-text>
				<pp-icon-button 
					class="postcode"
					(click)="openLookup()" 
					icon="search"> 
				</pp-icon-button>
			</div>
			<pp-text readOnly fxFlex="50" fxFlex.gt-md="60px" formControlName="country" label="Country"></pp-text>
		</div>
	</form>
</div>

<!--
-->