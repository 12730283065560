<form novalidate autocomplete="off" fxLayout="column">
	<div class="issues-header">{{ type.description }} selection</div>

	<mat-form-field >
		<mat-chip-list #chipList>
			<mat-chip *ngFor="let chip of statistics" 
				[selectable]="true"
				[removable]="removable"
				(removed)="itemRemove(type, chip)">
				{{ getChoice(chip)?.description }}
				<mat-icon matTooltip="Remove" matChipRemove *ngIf="removable">cancel</mat-icon>
			</mat-chip>

			<input
				placeholder="Select issue" 
				[matChipInputFor]="chipList"
				[matAutocomplete]="auto"
				[matChipInputAddOnBlur]="true"
				matInput 
				[formControl]="autoCompleteChipList"
				(focus)="focused()"
				#chipInput />
		</mat-chip-list>
		<mat-autocomplete 
			#auto="matAutocomplete"
			[autoActiveFirstOption]="true"
			(optionSelected)="itemAdd(type, $event, chipInput)">
			<mat-option *ngFor="let option of filteredOptions" [value]="option">
				{{ option.description }}
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>	
</form>