import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ConfigService,	ActivityHeaderItemDto } from 'app/services';

@Component({
	selector: 'pp-case-search-item',
	templateUrl: './case-search-item.component.html',
	styleUrls: ['./case-search-item.component.scss']
})
export class CaseSearchItemComponent {
	@Input() public result: ActivityHeaderItemDto;
	@Output() public select = new EventEmitter<ActivityHeaderItemDto>();
	@Output() public selectComplete = new EventEmitter<ActivityHeaderItemDto>();

	useManager = false;

	constructor(private config: ConfigService)
	{	
		this.useManager  = config.systemPreferences.useManagerInSearch
	}

	getCustomerOrEmployer(item: ActivityHeaderItemDto): string {
		const activityHeaderItem = item as ActivityHeaderItemDto;
		if (activityHeaderItem.customerName) 
			return activityHeaderItem.customerName;
		else
			return activityHeaderItem.employerName;
	}

	isManager() {
		return this.useManager;
	}

	getManager(item: ActivityHeaderItemDto): string {
		const activityHeaderItem = item as ActivityHeaderItemDto;
		if (activityHeaderItem.manager) 
			return 'Manager:' + activityHeaderItem.manager;
		return '';
	}

	onSelect(result: ActivityHeaderItemDto) {
		this.select.emit(result);
	}

	onSelectComplete(result: ActivityHeaderItemDto) {
		this.selectComplete.emit(result);
	}
}
