import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ExtendedDetailsHelper } from 'app/common-editing/extended-details/extended-details';

@Pipe({
	name: 'sessionAlertFlag'
})
export class SessionAlertFlagPipe implements PipeTransform {

	constructor(private sanitized: DomSanitizer) { }

	transform(value?: number): SafeHtml {
		if (value === null) value = 0;
		let redFlagColour = ExtendedDetailsHelper.redFlagAlternativeAppearenceColours[value],
			html = [
				`<i class="fa fa-flag fa-2x" style = "color: ${redFlagColour}; position: absolute;right:8px;margin-top:4px" > </i>`,
				`<i class="fa fa-flag-o fa-2x" style="color: red; position: absolute;right:8px;margin-top:4px"></i>`
			];

		return this.sanitized.bypassSecurityTrustHtml(html.join(' '));
	}

}
