import * as moment from 'moment';

/**
 * Converts an ISO8601 format date (and time) to a GMT moment with the
 * same date and time.
 *
 * @export
 * @param {string} iso The ISO8601-format date/time.
 * @returns {moment.Moment} The moment.Moment
 */
export function isoStringToAmbiguousMoment(iso: string): moment.Moment {
	return dateToAmbiguousMoment(moment(iso).toDate());
}

/**
 * Converts a (local) date (and time) to a GMT moment for use with the
 * FullCalendar component. E.g. 12 September 15:00 NZ time will be
 * converted to 12 Septenber 15:00 GMT.
 *
 * Calling toString() will return "Tue Sep 12 2017 15:00:00 GMT+0000"
 *
 * @export
 * @param {Date} date The date to convert.
 * @returns {moment.Moment} The resulting moment.Moment.
 */
export function dateToAmbiguousMoment(date: Date): moment.Moment {
	return moment(date).utcOffset(0, true).utc();
}

/**
 * Applies the UTC offset to the provided date to create a UTC equivalent
 * Date and time. When serialized (by the toJSON function) it will
 * arrive at the server appearing to be a UTC DateTime with the same
 * apparent (wall-clock) date and time as the provided date.
 *
 * The server will then call toUnspecified() on it to convert it to an
 * Unspecified Kind thus appearing to be the correct date and time as was selected
 * on the client.
 *
 * E.g. 12 September 08:00 NZ time will be converted to 11 Septenber 20:00 GMT.
 * Calling toJSON() would give '2017-09-12T08:00:00.000Z'
 *
 * @export
 * @param {Date} date The date to convert.
 * @returns {Date} The resulting UTC Date.
 */
export function toUnspecified(date: Date): Date {
	try{
		const tzOffset = date.getTimezoneOffset(),
			m = moment(date).subtract(tzOffset, 'minutes');
		return m.toDate();	
	}
	catch (err){
		return date;
	}
}

/**
 * Adds the date's (local) timezone offset to GMT.
 * 
 * @export
 * @param {Date} date The date to convert.
 * @returns {Date} The resulting Date with added timezoe offset.
 */
export function addDateTimezoneOffset(date: Date): Date {
	var offset = new Date(date.toDateString());
	offset = new Date(offset.getTime() - (date.getTimezoneOffset() * 60000)); 
	return offset;
}

/**
 * Applies the UTC offset to the provided local date/time to create a local wall-clock time
 * for the apparent UTC date/time.
 *
 * e.g. Nov 29th 13:00 NZDT (GMT+13:00) is midnight UTC.
 * This function will return Nov 29th 00:00 NZDT (GMT+13:00)
 *
 * Suitable for converting the wall-clock time from the server to equivalent wall-clock time
 * in whatever timezone the browser is in.
 * @export
 * @param date A date/time
 * @returns {Date} The UTC wall-clock Date.
 */
export function toLocalWallClock(date: Date): Date {
	const tzOffset = date.getTimezoneOffset(),
		m = moment(date).add(tzOffset, 'minutes');
	return m.toDate();
}

/**
 * Returns a date which is midnight of the following date for a Date.
 * @export
 * @param date
 * @Returns {Date} The date/time which is midnight of the following day.
 */
export function toMidnightNextDay(date: Date): Date {
	return moment(date).add(1, 'day').startOf('day').toDate();
}

/**
 * Returns a clone of a Date
 * @param date
 */
export function cloneDate(date: Date): Date {
	return new Date(date.getTime());
}

/**
 * Adds a number of minutes to a date, returning a new Date.
 * @param date The date
 * @param minutes The number of minutes to add.
 */
export function addMinutes(date: Date, minutes: number): Date {
	return moment(date).add(minutes, 'minutes').toDate();
}

/**
 * Adds a number of days to a date, returning a new Date.
 * @param date The date
 * @param days The number of days to add
 */
export const addDays = (date: Date, days: number): Date => {
	return moment(date).add(days, 'days').toDate();
};

/**
 * Returns whether both dates are the same day, regardless of time.
 * @param date
 * @param otherDate
 */
export function isSameDay(date: Date, otherDate: Date): boolean {
	return moment(date).isSame(otherDate, 'day');
}

/**
 * Returns whether both dates are the same.
 * @param date
 * @param otherDate
 */
export function isSame(date: Date, otherDate: Date): boolean {
	return moment(date).isSame(otherDate);
}

/**
 * Returns the number of minutes between the start and end.
 * @param start
 * @param end
 */
export function minutesDiff(start: Date, end: Date): number {
	return moment(end).diff(start, 'minutes');
}

/**
 * Gets a start of date timestamp (ms) for a Date.
 * @param date
 */
export function getStartOfDayTimestamp(date: Date): number {
	return moment(date).startOf('day').toDate().getTime();
}

/**
 * Returns a new Date for the start of the day.
 */
export function getStartOfDayDate() {
	return new Date(getStartOfDayTimestamp(new Date()));
}

/**
 * Gets a Date that is set to the start of the hour.
 * @param dateTime The date/time
 */
export const getStartOfHourTime = (dateTime: Date): Date => {
	return moment(dateTime).startOf('hour').toDate();
};

/**
 * Gets a Date that is set to the start of the day.
 * @param dateTime The date/time
 */
export const getStartOfDayTime = (dateTime: Date): Date => {
	return moment(dateTime).startOf('day').toDate();
};

/**
 * Gets a Date that is set to the start of the week.
 * @param dateTime The date/time
 */
export const getStartOfWeekTime = (dateTime: Date): Date => {
	return moment(dateTime).startOf('week').toDate();
};

/**
 * Combines a date and a time to produce a Date that combines both.
 * @param date The date
 * @param time The time (with the date part ignored)
 */
export const combineDateTime = (date: Date, time: Date): Date => {
	const startOfDayTime = moment(time).startOf('day').toDate().getTime();
	const msOffset = moment(time).diff(startOfDayTime);
	const combined = moment(date).startOf('day').add(msOffset).toDate();
	return combined;
};

export const formatTime = (time: Date): string => {
	return moment(time).format('h:mm a');
};

export const formatTimeWithDate = (time: Date): string => {
	return moment(time).format('ll h:mm a');
};

export const formatTimeRange = (startTime: Date, endTime: Date): string => {
	return `${formatTime(startTime)} - ${formatTime(endTime)}`;
};

export const formatTimeRangeWithDates = (startTime: Date, endTime: Date): string => {
	return `${formatTimeWithDate(startTime)} - ${formatTimeWithDate(endTime)}`;
};

export const formatDayOfWeek = (date: Date): string => {
	return moment(date).format('ddd');
};

export const formatDateShort = (date: Date): string => {
	return moment(date).format('Do MMM');
};
