import { Injectable } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';

import { interval, Observable } from 'rxjs';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class LiveReaderService {
	constructor(private announcer: LiveAnnouncer) { }

	messages: {
		message: string;
		politeness: string;
		duration: number;
		addedAt: Date | moment.Moment
		isValid?: boolean
	}[] = [];

	announce(message: string, duration: number = 600) {
		const msg = Object.defineProperty(
			{
				message,
				politeness: 'polite',
				duration,
				addedAt: moment(),
			},
			'isValid',
			{
				get: function () {
					const
						endDate = moment(this.addedAt).add(this.duration, 'ms'),
						currentDate = moment(),
						diff = endDate.diff(currentDate, 'ms');
					return diff > 0;
				}
			});
		this.messages = this.messages.filter(x => x.isValid);
		this.messages.push(msg);
	}
}
