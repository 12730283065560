<div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px" #modalArea>
	<div fxLayout="row" fxLayoutAlign="start center" fxFlex="auto" fxFlex.gt-sm="100px">
		<div class="type" fxFlex>{{ address.typeName }}</div>

		<div class="commands" fxLayout="row" fxHide.gt-sm>
			<span fxFlex></span>
			<button [matTooltip]="'Edit ' + address.typeName?.toLowerCase()" [attr.aria-label]="'Edit ' + address.typeName?.toLowerCase()" ngClass.lt-md="shrink-button" mat-icon-button (click)="edit()">
				<mat-icon>mode_edit</mat-icon>
			</button>
			<button [matTooltip]="'Delete ' + address.typeName?.toLowerCase()" [attr.aria-label]="'Delete ' + address.typeName?.toLowerCase()" ngClass.lt-md="shrink-button" mat-icon-button (click)="delete()">
				<mat-icon>delete</mat-icon>
			</button>
		</div>
	</div>

	<div fxLayout="column" fxLayout.gt-sm="row" fxFlex="auto" fxFlex.gt-sm fxLayoutAlign.gt-sm="start center" class="address-details">
		<span class="details" fxFlex.gt-sm>{{ details }}</span>
		<div fxLayout="row" fxLayoutWrap>			
			<div *ngFor="let tag of tags">
				<span class="tag" [ngClass]="tag.className">{{ tag.name }}</span>
			</div>
		</div>

		<div class="commands" fxLayout="row" fxHide.lt-md>
			<button [matTooltip]="'Edit ' + address.typeName?.toLowerCase()" [attr.aria-label]="'Edit ' + address.typeName?.toLowerCase()" class="hover" [disabled]="readOnly" mat-icon-button (click)="edit()">
				<mat-icon>mode_edit</mat-icon>
			</button>
			<button [matTooltip]="'Delete ' + address.typeName?.toLowerCase()" [attr.aria-label]="'Delete ' + address.typeName?.toLowerCase()" class="hover" [disabled]="readOnly" mat-icon-button (click)="delete()">
				<mat-icon>delete</mat-icon>
			</button>
		</div>
	</div>
</div>
