<div fxLayout="column">
	<div fxLayout="row">
		<a *ngIf="hasServiceType" (click)="scrollIntoView('service-type-alert')">Service type alert</a>
		<a *ngIf="hasContract" (click)="scrollIntoView('contract-alert')">Contract alert</a>
		<a *ngIf="hasCustomer" (click)="scrollIntoView('customer-alert')">Customer alert</a>
	</div>

	<div id="alert-container" fxFlex>
		<div *ngIf="hasServiceType" class="alert-header-border"></div>
		<div id="service-type-alert" *ngIf="hasServiceType" class="alert-header">Service type alert</div>
		<div *ngIf="hasServiceType" [innerHtml]="serviceTypeAlertHtml"></div>			

		<div *ngIf="hasContract" class="alert-header-border"></div>
		<div id="contract-alert" *ngIf="hasContract" class="alert-header">Contract alert</div>
		<div *ngIf="hasContract" [innerHtml]="contractAlertHtml"></div>

		<div *ngIf="hasCustomer" class="alert-header-border"></div>
		<div id="customer-alert" *ngIf="hasCustomer" class="alert-header">Customer alert</div>
		<div *ngIf="hasCustomer" [innerHtml]="customerAlertHtml"></div>
	</div>
</div>