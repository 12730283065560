<form novalidate autocomplete="off" [formGroup]="form" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
	<pp-display label="Item" fxFlex fxFlex.gt-xs="300px">
		{{ choice.description }}
	</pp-display>

	<pp-text
		fxFlex="60px"
		[label]="type.descriptionExtraField1"
		placeholder=""
		formControlName="int"
		[maxIntDigits]="10"
		[numericOnly]="true"
	>
	</pp-text>

	<pp-text
		fxFlex="60px"
		[label]="type.descriptionExtraField2"
		placeholder=""
		formControlName="intExtra1"
		[maxIntDigits]="10"
		[numericOnly]="true"
	>
	</pp-text>

	<button type="button" mat-icon-button (click)="deleteChoice()"><mat-icon>close</mat-icon></button>
</form>
