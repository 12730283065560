import { Component, Input, OnInit, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
	StatisticalChoiceDto,
	StatisticDto,
} from 'app/services';
import { StatisticEditorBase } from '../statistic-editor-base';
import { TextComponent } from 'app/shared/forms/text.component';

@Component({
	selector: 'pp-editor-two-num',
	templateUrl: './editor-two-num.component.html',
	styleUrls: ['./editor-two-num.component.scss']
})
export class EditorTwoNumComponent extends StatisticEditorBase implements OnInit {
	form: UntypedFormGroup;

	@Input() choice: StatisticalChoiceDto;

	@ViewChildren(TextComponent) inputs: QueryList<TextComponent>;

	constructor(
		private fb: UntypedFormBuilder,
	) {
		super();
	}

	ngOnInit() {
		this.buildForm();

		if (this.statistic) {
			this.form.patchValue({
				int: this.statistic.int,
				intExtra1: this.statistic.intExtra1
			});
		}

		this.form.controls.int.valueChanges.subscribe(val => {
			const statistic = this.getStatistic();
			statistic.int = +val;
			this.emitChange();
		});

		this.form.controls.intExtra1.valueChanges.subscribe(val => {
			const statistic = this.getStatistic();
			statistic.intExtra1 = +val;
			this.emitChange();
		});
	}

	setFocus() {
		this.inputs.first.setFocus();
	}

	private getStatistic(): StatisticDto {
		if (this.statistic) {
			return this.statistic;
		}

		this.statistic = <StatisticDto> {
			statisticalChoiceId: this.choice.id,
			statisticalTypeId: this.type.id
		};
	}

	private buildForm() {
		this.form = this.fb.group({
			int: undefined,
			intExtra1: undefined,
		});
	}
}
