<div>
	<div fxLayout="row" fxLayoutAlign="start center">
		<h2>Add issue</h2>
		<span fxFlex></span>
		<button mat-icon-button matTooltip="OK" matDialogClose="ok" [disabled]="!form.valid" tabindex="-1" (click)="submit()"><mat-icon>done</mat-icon></button>
		<button mat-icon-button matTooltip="Cancel" matDialogClose="cancel" tabindex="-1"><mat-icon>close</mat-icon></button>
	</div>

	<form novalidate autocomplete="off" [formGroup]="form" fxLayout="row">
		<mat-form-field fxFlex>
			<input type="text" matInput (keyup.enter)="closeIfValid()" formControlName="choice" [matAutocomplete]="auto">
		</mat-form-field>		

		<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
			<mat-option *ngFor="let choice of filteredChoices | async" [value]="choice">
				{{ choice.description }}
			</mat-option>
		</mat-autocomplete>		
	</form>
</div>