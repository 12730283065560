import { Injectable } from '@angular/core';

/**
 * @export
 * @class SessionStorageManagementService
 */
@Injectable({
	providedIn: 'root',
})
export class SessionStorageManagementService {
	public static KEY_CALENDAR_CURRENT_VIEW = 'calendar-view';
	public static KEY_CALENDAR_CURRENT_DATE = 'calendar-view-date';
	public static KEY_APPOINTMENTS_PRACTICE_STATE = 'appointments-practice-state';

	clearVolatileData() {
		sessionStorage.removeItem(SessionStorageManagementService.KEY_CALENDAR_CURRENT_DATE);
		sessionStorage.removeItem(SessionStorageManagementService.KEY_CALENDAR_CURRENT_VIEW);
		sessionStorage.removeItem(SessionStorageManagementService.KEY_APPOINTMENTS_PRACTICE_STATE);
	}

	getItem(key: string) {
		return sessionStorage.getItem(key);
	}

	setItem(key: string, value: any) {
		if (typeof value === 'object' && value instanceof Date) {
			value = (<Date> value).toJSON();
		}
		sessionStorage.setItem(key, value);
	}
}
