<div class="wrapper">
	<div class="header-wrapper" [ngClass]="{ 'at-risk': atRisk, 'not-at-risk': !atRisk }">
		<h1>{{ type }}</h1>
	</div>
	<div class="factors">
		<div class="factor" *ngFor="let factor of factors">
			<div class="option">{{ factor.option }}</div>
			<div class="value" [ngClass]="{ 'at-risk': factor.isAtRisk, 'not-at-risk': !factor.isAtRisk }">
				{{ factor.isAtRisk ? 'At risk' : 'Not at risk' }}
			</div>
		</div>
	</div>
</div>
