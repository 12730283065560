import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UnmanagedCookieService } from 'ng2-cookies';
import { VirtualScrollModule } from 'angular2-virtual-scroll';

// Covalent modules
import { CovalentCommonModule } from '@covalent/core/common';
import { CovalentDialogsModule } from '@covalent/core/dialogs';
import { CovalentFileModule } from '@covalent/core/file';
import { CovalentLayoutModule } from '@covalent/core/layout';
import { CovalentLoadingModule } from '@covalent/core/loading';
import { CovalentMessageModule } from '@covalent/core/message';

// Angular material modules
import { MAT_MOMENT_DATE_FORMATS, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter'
import { MatNativeDateModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule, } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';

// Misc 3rd party modules
import { AgGridModule } from 'ag-grid-angular';
import { TextMaskModule } from 'angular2-text-mask';
import { QuillModule } from 'ngx-quill';

// Local directives
import { BlockNonNumberDirective } from './block-non-number.directive';
import { EqualValidatorDirective } from './equal-validator/equal-validator.directive';
import { FocusDirective } from './focus/focus.directive';
import { MatOptionDirective } from './accessibility/mat-option.directive';
import { MatSortDirective } from './accessibility/mat-sort.directive';
import { MatSortHeaderDirective } from './accessibility/mat-sort-header.directive';

// Local reusable components
import { ApplyCancelComponent } from './apply-cancel/apply-cancel.component';
import { ButtonComponent } from './forms/button.component';
import { ButtonToggleComponent } from './forms/button-toggle.component';
import { CheckboxComponent } from './forms/checkbox.component';
import { CaseSearchItemComponent } from './case-search-item/case-search-item.component';
import { DateComponent } from './forms/date-component';
import { DisplayComponent } from './forms/display.component';
import { HtmlEditorComponent } from './forms/html-editor.component';
import { IconButtonComponent } from './forms/icon-button.component';
import { RadioGroupComponent } from './forms/radio-group.component';
import { SelectComponent } from './forms/select.component';
import { SelectionGridComponent } from './selection-grid/selection-grid.component';
import { SwitchComponent } from './forms/switch.component';
import { TextAreaComponent } from './forms/text-area.component';
import { TextComponent } from './forms/text.component';
import { TextPlainComponent } from './forms/text-plain.component';

import { FullCalendarSchedulerComponent } from './full-calendar-scheduler/full-calendar-scheduler.component';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { AddressEditComponent } from '../profile/shared-forms/address-edit.component';
import { SearchPostcodeComponent } from '../profile/shared-forms/search-postcode/search-postcode.component';

// Local pipes
import { DurationPipe } from './duration.pipe';
import { ZeroToTextPipePipe } from './zero-to-text-pipe.pipe';
import { FileSizePipe } from './file-size.pipe';
import { BreakifyPipe } from './breakify.pipe';
import { AppointmentPhonePipe } from './appointment-phone.pipe';
import { SessionNumberPipe } from './session-number.pipe';
import { SessionAlertFlagPipe } from './session-alert-flag.pipe';
import { InPipe } from './in.pipe';

// Local misc stuff
import { ModalRouteHeaderComponent } from './modal-route-header/modal-route-header.component';
import { ModalDialogHeaderComponent } from './modal-dialog-header/modal-dialog-header.component';
import { ModalDialogFooterComponent } from './modal-dialog-footer/modal-dialog-footer.component';
import { TableComponent } from './table/table.component';
import { LangService } from 'app/services/lang.service';
import { LangSelectorComponent } from './lang-selector/lang-selector.component';



// Define constants to bundle up ecah of the types of import.
const FLEX_LAYOUT_MODULES: any[] = [FlexLayoutModule];

const ANGULAR_MODULES: any[] = [FormsModule, ReactiveFormsModule];

const MATERIAL_MODULES: any[] = [
	MatAutocompleteModule,
	MatButtonModule,
	MatButtonToggleModule,
	MatCardModule,
	MatCheckboxModule,
	MatChipsModule,
	MatDatepickerModule,
	MatDialogModule,
	MatExpansionModule,
	MatIconModule,
	MatInputModule,
	MatListModule,
	MatMenuModule,
	MatNativeDateModule,
	MatProgressSpinnerModule,
	MatProgressBarModule,
	MatRadioModule,
	MatSelectModule,
	MatSidenavModule,
	MatSlideToggleModule,
	MatSnackBarModule,
	MatTableModule,
	MatTabsModule,
	MatToolbarModule,
	MatTooltipModule,
	MatSortModule,
];

const COVALENT_MODULES: any[] = [
	CovalentCommonModule,
	CovalentDialogsModule,
	CovalentFileModule,
	CovalentLayoutModule,
	CovalentLoadingModule,
	CovalentMessageModule,
];

const DIRECTIVES: any[] = [BlockNonNumberDirective, EqualValidatorDirective, FocusDirective, MatOptionDirective, MatSortDirective, MatSortHeaderDirective];

const PIPES: any[] = [
	DurationPipe,
	ZeroToTextPipePipe,
	FileSizePipe,
	BreakifyPipe,
	AppointmentPhonePipe,
	SessionNumberPipe,
	SessionAlertFlagPipe,
	InPipe,
];

const CONTROLS: any[] = [
	ApplyCancelComponent,
	ButtonComponent,
	CheckboxComponent,
	DateComponent,
	DisplayComponent,
	HtmlEditorComponent,
	IconButtonComponent,
	RadioGroupComponent,
	SelectComponent,
	SelectionGridComponent,
	SwitchComponent,
	TextComponent,
	TextAreaComponent,
	TextPlainComponent,
	ButtonToggleComponent,

	FullCalendarSchedulerComponent,
	ModalRouteHeaderComponent,
	SearchFilterComponent,
	ModalDialogHeaderComponent,
	ModalDialogFooterComponent,
	AddressEditComponent,
	SearchPostcodeComponent,

	CaseSearchItemComponent,
	TableComponent,
	LangSelectorComponent,
];

@NgModule({
	imports: [
		CommonModule,
		ANGULAR_MODULES,
		MATERIAL_MODULES,
		COVALENT_MODULES,
		FLEX_LAYOUT_MODULES,

		AgGridModule,
		TextMaskModule,
		QuillModule,
		VirtualScrollModule,
	],
	declarations: [DIRECTIVES, CONTROLS, PIPES],
	exports: [
		ANGULAR_MODULES,
		MATERIAL_MODULES,
		COVALENT_MODULES,
		FLEX_LAYOUT_MODULES,

		AgGridModule,
		TextMaskModule,
		QuillModule,
		VirtualScrollModule,

		DIRECTIVES,
		PIPES,
		CONTROLS,
	],
	providers: [
		PIPES,
		UnmanagedCookieService,
		LangService,
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},
		{
			provide: LOCALE_ID,
			deps: [UnmanagedCookieService],
			useFactory: (_cookie: UnmanagedCookieService) => _cookie.check('lang') ? `${_cookie.get('lang')}-CA` : 'en-CA',
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue: MAT_MOMENT_DATE_FORMATS,
		},
		{
			provide: MAT_DATE_LOCALE,
			deps: [UnmanagedCookieService],
			useFactory: (_cookie: UnmanagedCookieService) => _cookie.check('lang') ? `${_cookie.get('lang')}-CA` : 'en-CA',
		}
	],
})
export class SharedModule { }
