import { Component, Input, OnInit  } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { AddressDto, CityDto, FormUtils } from 'app/services';
import { IForm } from 'app/interfaces';
import { PostcodeService } from './search-postcode/search-postcode.service';
import { SearchPostcodeComponent } from './search-postcode/search-postcode.component';

@Component({
	selector: 'pp-address-edit',
	templateUrl: './address-edit.component.html',
	styleUrls: ['./address-edit.component.scss']
})
export class AddressEditComponent implements OnInit, IForm  {

	@Input() loaded: boolean;
	@Input() editDto: AddressDto;
	@Input() markChanged: any;
	@Input() saving: Subject<boolean>;
	
	form: FormGroup;
	
	constructor(
		private fb: FormBuilder,
		private servicePostcode: PostcodeService,
	) {}
	
	ngOnInit() {
		this.buildForm();

		var a = this.editDto;
		this.form.patchValue({
			addressLine1: a.addressLine1,
			addressLine2: a.addressLine2,
			addressLine3: a.addressLine3,
			suburb: a.suburb,
			city: a.city,
			postcode: a.postcode,
			state: a.state,
			country: a.country
		});
		
		this.saving.subscribe(v => { 
			FormUtils.applyChanges(this.form, this.editDto);
		})
	}

	getForm(): FormGroup {
		return this.form;
	}

	openLookup = () => {
		this.servicePostcode.findPostcode(this.handleSelection, 
			this.editDto.country,
			SearchPostcodeComponent);
	}

	handleSelection = (selection: CityDto) => {
		this.form.controls['suburb'].setValue(selection.suburb);
		this.form.controls['city'].setValue(selection.city);
		this.form.controls['postcode'].setValue(selection.postcode);
		this.form.controls['state'].setValue(selection.state);
		this.form.controls['country'].setValue(selection.country);
		this.editDto.cityId = selection.id;
		this.editDto.isSuburb = selection.isSuburb;
		this.editDto.isState = selection.isState;
	}

	private buildForm() {
		this.form = this.fb.group({
			addressLine1: [],
			addressLine2: [],
			addressLine3: [],
			suburb: [],
			city: [],
			postcode: [],
			state: [],
			country: [],
		});
	}

	isValid(): boolean {
		return true;
	}
}
