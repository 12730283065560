import { Component, OnInit, OnChanges, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import {
	StatisticClientSessionDto,
	FormUtils,
} from 'app/services';
import { DateComponent } from 'app/shared/forms/date-component';

@Component({
	selector: 'pp-client-session-edit',
	templateUrl: './client-session-edit.component.html',
	styleUrls: ['./client-session-edit.component.scss']
})
export class ClientSessionEditComponent implements OnInit, OnChanges {
	form: UntypedFormGroup;

	@ViewChild('date', { static: true }) date: DateComponent;
	@Input() session: StatisticClientSessionDto;
	@Input() addMode: boolean;
	@Output() updated = new EventEmitter<StatisticClientSessionDto>();

	constructor(
		private fb: UntypedFormBuilder,
	) {
		this.buildForm();
	}

	get header() {
		return this.addMode
			? 'Add client session'
			: 'Edit client session';
	}

	ngOnInit() {
		this.form.valueChanges.subscribe(this.formValueChanged.bind(this));
	}

	ngOnChanges() {
		this.form.patchValue({
			sessionDateTime: this.session.sessionDateTime,
			sessionDurationHours: this.session.sessionDurationHours,
			sessionDurationMinutes: this.session.sessionDurationMinutes
		});

		if (this.addMode) {
			this.date.setFocus();
		}
	}

	minutesBlurred(e) {
		const formVal = (<StatisticClientSessionDto> this.form.value);
		let minutes = +formVal.sessionDurationMinutes;
		let hours = +formVal.sessionDurationHours;

		if (!minutes) {
			return;
		}

		if (minutes > 60) {
			hours += minutes / 60;
			minutes = minutes % 60;

			this.form.patchValue({
				sessionDurationHours: hours,
				sessionDurationMinutes: minutes
			});
		}
	}

	private formValueChanged(v: StatisticClientSessionDto) {
		if (!this.form.valid) {
			return;
		}

		FormUtils.applyChanges(this.form, this.session);
		this.updated.emit(this.session);
	}

	private buildForm() {
		this.form = this.fb.group({
			sessionDateTime: [undefined, Validators.required],
			sessionDurationHours: [undefined, Validators.required],
			sessionDurationMinutes: [undefined, Validators.required],
		});
	}
}
