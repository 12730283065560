<div *ngIf="clinicalProfile" class="wrapper">
	<div class="header">
		<h1>Clinical Profile</h1>
	</div>

	<div class="concerns wrapper-parent">
		<div class="header-wrapper">
			<h2>Concerns</h2>
		</div>
		<div class="concerns-wrapper">
			<div class="concern" *ngFor="let concern of concerns">
				{{ concern.value }}
			</div>
		</div>
	</div>

	<div class="goals wrapper-parent">
		<div class="header-wrapper">
			<h2>Goals</h2>
		</div>
		<div class="bool-goals">
			<div class="bool-goal" *ngFor="let goal of booleanGoals">
				<pp-boolean-goal-display [goal]="goal"></pp-boolean-goal-display>
			</div>
		</div>
	</div>

	<div [hidden]="!hasOtherGoals" class="other-goals wrapper-parent">
		<div class="header-wrapper">
			<h2>Other Goals</h2>
		</div>
		<div class="other-goals-list">
			<div class="other-goal" *ngFor="let goal of otherGoals">
				<pp-other-goal-display [goal]="goal"></pp-other-goal-display>
			</div>
		</div>
	</div>
</div>
