import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModel } from './confirm-dialog.model';

@Injectable({
	providedIn: 'root'
})
export class ConfirmDialogService {
	constructor(private dialog: MatDialog) { }

	confirm(msg: string, title?: string) {
		const dialogData = new ConfirmDialogModel(title || 'Confirm Action', msg);

		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			maxWidth: '400px',
			data: dialogData
		});

		return dialogRef.afterClosed().toPromise();
	}
}
