<div fxLayout="column">
	<div fxLayout="column" *ngFor="let type of types">
		<div class="header">
			<span *ngIf="type.isRequired" class="required">*</span>
			{{ type.description }}
		</div>
		<div fxLayout="column" *ngFor="let choice of type.statisticalChoices">
			<mat-checkbox [checked]="isChecked(choice)" (change)="update(type, choice, $event)">{{
				choice.description
			}}</mat-checkbox>
		</div>
	</div>
</div>
