<form novalidate autocomplete="off" [formGroup]="form" fxLayout="column">
	<div fxLayout="row">
		<div *ngIf="!editDto.isDescriptionOnly" fxFlex fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="16px">
			<pp-text fxFlex="50" fxFlex.gt-md="230px" formControlName="firstName" label="First name"> </pp-text>
			<pp-text fxFlex="50" fxFlex.gt-md="230px" formControlName="lastName" label="Last name"> </pp-text>
		</div>
		<div *ngIf="editDto.isDescriptionOnly" fxFlex fxLayout="row">
			<pp-text fxFlex fxFlex.gt-xs="400px" formControlName="description" label="Description"> </pp-text>
		</div>
	</div>

	<div fxLayout="row" *ngIf="editDto.isSelfServiceRegistered">
		<div class="self-service">Self-service registered</div>
	</div>

	<pp-comm-addr-editor
		[commAddresses]="editDto.communicationAddresses"
		[countries]="loadDto.countries"
		[readOnly]="readOnly"
		(updated)="addressesUpdated()"
	>
	</pp-comm-addr-editor>
</form>
