import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SaveResultDto, DeleteResultDto } from 'app/services/server-data';
import { JsonService } from './json.service';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class CrudService {
	constructor(private http: HttpClient, private json: JsonService) {}

	save<T>(saveUrl: string, dto: T): Observable<SaveResultDto<T>>	{
		const body = this.json.stringify(dto);
		return this.http.post<SaveResultDto<T>>(saveUrl, body)
			.pipe(tap(dto => this.convertISODate(dto))); 
	}

	saveWithInfo<T, TResult>(saveUrl: string, dto: T): Observable<SaveResultDto<TResult>>	{
		const body = this.json.stringify(dto);
		return this.http.post<SaveResultDto<TResult>>(saveUrl, body)
					.pipe(tap(dto => this.convertISODate(dto))); 
	}

	delete(url: string): Observable<DeleteResultDto>	{
		return this.http.delete<DeleteResultDto>(url)
			.pipe(tap(dto => this.convertISODate(dto))); 
	}

	loadWithPost<T>(loadUrl: string, params: {}): Observable<T> {
		const body = this.json.stringify(params);
		return this.http.post<T>(loadUrl, body)
				.pipe(tap(dto => this.convertISODate(dto))); 
	}

	//This is a work around. The update of the API to .NET8 has caused dates to be transferred
	//in ISO 8601 format, instead of Microsoft Date Format.
	convertISODate(dto: any) {

		if(typeof dto === 'undefined') return;

		Object.keys(dto).forEach(key => {
			var field = dto[key];
			if (field === 'undefined' || field === null) return;

			if (field.constructor === Array) {
				field.forEach(i => this.convertISODate(i));
				return;
			}

			if (typeof field === 'object' && !Array.isArray(field)) {
				this.convertISODate(field);
				return;
			}

			if (typeof field === 'string' || field instanceof String) {
				if (moment(field.toString(), "YYYY-MM-DDTHH:mm:ss", true).isValid())
					dto[key] = new Date (field.toString());
			}
		});
	}


	loadWithGet<T>(loadUrl: string, params?: {}): Observable<T> {
		let httpParams: HttpParams;

		if (params) {
			httpParams = new HttpParams();
			for (let key in params) {
				if (params.hasOwnProperty(key)) {
					const element = params[key];
					httpParams = httpParams.append(key, element);
				}
			}
		}
		return this.http.get<T>(loadUrl, {
			params: httpParams
		}).pipe(tap(dto => this.convertISODate(dto))); 
	}
}
