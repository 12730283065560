import { Component, Input, Output, EventEmitter } from '@angular/core';

let identifier = 0;

@Component({
	selector: 'pp-icon-button',
	template: `
	<button
		[attr.aria-label]="label"
		mat-icon-button
		class="hover"
		[id]="identifier"
		[disabled]="disabled"
		[matTooltip]="label"
		(click)="handleClick($event)">
		<mat-icon class="md-24">{{ icon }}</mat-icon>
	</button>
`,
	styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent {
	@Input() icon: string;
	@Input() label: string;
	@Input() disabled: boolean;
	@Output() click = new EventEmitter<Event>();

	public identifier = `icon-button-${identifier++}`;

	handleClick(ev: Event) {
		this.click.emit(ev);
		ev.stopPropagation();
	}
}
