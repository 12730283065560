<div class="wrapper">
	<div class="overall">
		<div class="header">
			<h1>Risk Profile</h1>
			<div class="score">
				<div class="overall-header">
					Overall score
				</div>
				<div class="overall-value">
					{{ riskProfile.total }}
				</div>
			</div>
		</div>
	</div>

	<div class="risk-factors" [ngClass]="{ 'has-other-factors': otherFactors.length }">
		<pp-risk-profile-factor id="health" type="Health" [factors]="healthFactors"></pp-risk-profile-factor>
		<pp-risk-profile-factor id="mental" type="Mental" [factors]="mentalFactors"></pp-risk-profile-factor>
		<pp-risk-profile-factor id="life" type="Life" [factors]="lifeStatusFactors"></pp-risk-profile-factor>
		<pp-risk-profile-factor id="work" type="Work" [factors]="workStatusFactors"></pp-risk-profile-factor>
		<div class="other-wrapper" id="other" *ngIf="otherFactors.length">
			<pp-risk-profile-factor type="Other" [factors]="otherFactors"></pp-risk-profile-factor>
		</div>
	</div>
</div>
