import { Component, ChangeDetectionStrategy, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { ICalUrls } from './iCalUrls.model';

@Component({
	selector: 'pp-i-cal-details-dialog',
	templateUrl: './i-cal-details-dialog.component.html',
	styleUrls: ['./i-cal-details-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ICalDetailsDialogComponent {
	@ViewChild('input', { static: true }) inputEl: ElementRef;
	@ViewChild('copyButton', { static: true }) copyButton: MatButton;

	iCalUrls: ICalUrls;

	constructor(@Inject(MAT_DIALOG_DATA) public data: ICalUrls) {
		this.iCalUrls = data;
	}

	copyToClipboard() {
		this.inputEl.nativeElement.select();
		document.execCommand('copy');
		this.copyButton.focus();
	}

	onKeydown(event: KeyboardEvent) {
		// Fake up a read-only input by rejecting any keys that would mutate the value.
		if (event.key.length === 1 && (!event.ctrlKey || event.metaKey)) {
			event.preventDefault();
		}

		if (event.key === 'Delete' || event.key === 'Backspace') {
			event.preventDefault();
		}

		// Prevent Paste and Cut actions
		if ((event.key === 'v' || event.key === 'x') && (event.ctrlKey || event.metaKey)) {
			event.preventDefault();
		}
	}
}
