<form novalidate autocomplete="off" [formGroup]="form" fxLayout="column">
	<div class="header">{{ header }}</div>

	<div fxLayout="row">
		<pp-date
			#date 
			label="Session date" 
			formControlName="sessionDateTime"></pp-date>
	</div>
	
	<div fxLayout="row" fxLayoutGap="16px">
		<pp-text 
			fxFlex="70px"
			label="Hours"
			[numericOnly]="true"
			[maxIntDigits]="2"
			formControlName="sessionDurationHours">
		</pp-text>

		<pp-text
			fxFlex="70px"
			label="Minutes"
			[numericOnly]="true"
			[maxIntDigits]="2"
			(blur)="minutesBlurred($event)"
			formControlName="sessionDurationMinutes">
		</pp-text>		
	</div>
</form>