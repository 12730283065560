<form novalidate autocomplete="off" [formGroup]="form">
	<mat-expansion-panel
		[@.disabled]="disableAnimation"
		(closed)="panelClosed()"
		(opened)="panelOpened()"
		fxFlex
		#panel
	>
		<mat-expansion-panel-header>
			<mat-panel-title
				>Filter
				<pp-switch
					*ngIf="!hideShowAll"
					(onChange)="showAllChanged($event)"
					label="Show all"
					formControlName="showAll"
				></pp-switch
			></mat-panel-title>
			<mat-panel-description>{{ filterDescription }}</mat-panel-description>
		</mat-expansion-panel-header>

		<div
			fxLayout="column"
			fxLayout.gt-sm="row"
			fxLayoutGap.gt-sm="16px"
			fxLayoutAlign.gt-sm="start center"
			class="filter-form"
		>
			<pp-date fxFlex.gt-sm="140px" label="Date from" formControlName="dateFrom"></pp-date>
			<pp-date fxFlex.gt-sm="140px" label="Date to" formControlName="dateTo"></pp-date>
			<pp-switch *ngIf="!hideOpenOnly" [label]="openLabel" formControlName="openOnly"></pp-switch>
			<span fxHide.lt-md="true" fxFlex></span>
			<pp-button label="Clear filter" [disabled]="showingAll" (clicked)="clearFilter()"></pp-button>
		</div>
	</mat-expansion-panel>
</form>
