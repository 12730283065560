import { Inject, Injectable } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { UnmanagedCookieService } from 'ng2-cookies';
import { firstValueFrom } from 'rxjs'
import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class LangService {

	defaultLang: string = 'en';

	get currentLang(): string {
		return this._translate.currentLang;
	}

	get currentLocal() {
		return `${this.currentLang}-CA`;
	}

	constructor(
		private _translate: TranslateService,
		@Inject(DOCUMENT) private _document: any,
		private _cookie: UnmanagedCookieService,
		private _adapter: DateAdapter<any>,
		@Inject(MAT_DATE_LOCALE) private _locale: string,
	) {
		this.init();
	}

	async init() {
		let lang;
		if (this._cookie.check('lang')) {
			lang = this._cookie.get('lang');
		} else {
			[lang,] = navigator.language.split('-');

			if (!['fr', 'en'].includes(lang)) {
				lang = 'en';
			}
		}

		this.changeLang(lang);
	}


	changeLang(lang: string) {
		this._cookie.set('lang', lang);
		this._translate.use(lang);
		this._document.documentElement.lang = this.currentLang;
		this._locale = this.currentLocal;
		this._adapter.setLocale(this.currentLocal);
		moment.locale(this.currentLocal);
	}
}
