<div fxLayout="column">
	<mat-tab-group fxFlex>
		<mat-tab
			*ngFor="let statsContainer of header?.statisticContainers"
			[label]="statsContainer.statisticalGroup.description"
		>
			<div fxLayout="column" class="tab-container">
				<pp-statistic-container
					[container]="statsContainer"
					[header]="header"
					(changed)="statisticsChanged($event)"
				>
				</pp-statistic-container>
			</div>
		</mat-tab>
	</mat-tab-group>
</div>
