import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
	StatisticalTypeDto,
	StatisticDto,
} from 'app/services';
import { StatisticEditorBase } from '../statistic-editor-base';

@Component({
	selector: 'pp-editor-textbox',
	templateUrl: './editor-textbox.component.html',
	styleUrls: ['./editor-textbox.component.scss']
})
export class EditorTextboxComponent extends StatisticEditorBase implements OnInit {
	form: UntypedFormGroup;

	constructor(
		private fb: UntypedFormBuilder,
	) {
		super();
	}

	ngOnInit() {
		this.buildForm();
		this.form.patchValue({
			text: this.statistic.text
		});

		this.form.controls.text.valueChanges.subscribe(val => {
			this.statistic.text = val;
			this.emitChange();
		});
	}

	private buildForm() {
		this.form = this.fb.group({
			text: undefined
		});

		if (this.type.isRequired) {
			this.form.controls.text.setValidators(Validators.required);
		}
	}
}
