import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class UtilsService {
	diff(o1: object, o2: object, inverse: boolean = false): object {
		const result = {};
		for (let prop in o1) {
			if (!o1.hasOwnProperty(prop)) {
				continue;
			}

			if (typeof o1[prop] === 'object') {
				if (!o2.hasOwnProperty(prop)) {
					result[prop] = {};
				} else {
					if (o1[prop] instanceof Date && o2[prop] instanceof Date) {
						if (o1[prop].getTime() !== o2[prop].getTime()) {
							result[prop] = o1[prop];
						}
					} else {
						if (!o1[prop] && o2[prop]) {
							result[prop] = {};
						} else {
							const childDiff = this.diff(o1[prop], o2[prop]);
							if (childDiff) {
								result[prop] = childDiff;
							}
						}
					}
				}
			} else if (o2[prop] !== o1[prop]) {
				result[prop] = o1[prop];
			}
		}

		if (_.isEqual(result, {})) {
			if (!inverse) {
				// Check the inverse difference - i.e. compare o2's properties to o1's.
				return this.diff(o2, o1, true);
			}
			return undefined;
		}
		return result;
	}

	formatDate(date: Date): string {
		if (!date) {
			return '';
		}

		return moment(date).format('DD-MMM-YYYY');
	}

	formatTime(date: Date): string {
		if (!date) {
			return '';
		}

		return moment(date).format('HH:MM');
	}

	/**
	 * Walks up DOM elements checking to see whether the target is or has an eventual parent of parent.
	 * @param {*} target The target element.
	 * @param {*} parent The parent to test for.
	 * @returns {boolean} True if the target is the parent or has it as an eventual parent.
	 * @memberof UtilsService
	 */
	hasEventualParent(target: any, parent: any): boolean {
		let t = target;
		while (t) {
			if (t === parent) {
				break;
			}

			t = t.parentElement;
		}
		return !!t;
	}

	/**
	 * Converts text containing new lines into an HTML string with the new lines replaced by <br />
	 * tags.
	 * @param {string} input The input text.
	 * @param { removeMultipleNewLines: boolean } opts Options
	 */
	breakify(input: string, opts?: { removeMultipleNewLines: boolean }) {
		if (!input) {
			return input;
		}
		let result = input.replace(/\r\n/gm, '<br />');
		result = result.replace(/\n/gm, '<br />');
		result = result.replace(/\r/gm, '<br />');

		if (opts && opts.removeMultipleNewLines) {
			result = result.replace(/(<br \/>+)+/gm, '<br />');
		}

		return result;
	}

	base64ToArrayBuffer(base64: string) {
		const binary_string = window.atob(base64);
		const len = binary_string.length;
		const bytes = new Uint8Array(len);
		for (let i = 0; i < len; i++) {
			bytes[i] = binary_string.charCodeAt(i);
		}
		return bytes.buffer;
	}

	base64FileToBlob(file: string) {
		const buff = this.base64ToArrayBuffer(file);
		const blob = new Blob([buff]);
		return blob;
	}
}

export class Guid {
	static newGuid() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			let r = (Math.random() * 16) | 0,
				v = c === 'x' ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	}
}
