<div fxLayout="column" cdkTrapFocus>
	<pp-modal-route-header title="Edit statistic header" (result)="complete($event)"> </pp-modal-route-header>

	<mat-tab-group fxFlex *ngIf="!loading">
		<mat-tab label="Summary">
			<div fxLayout="column" class="tab-container">
				<pp-header-edit-summary #summary [header]="editDto"> </pp-header-edit-summary>
			</div>
		</mat-tab>

		<mat-tab label="Statistics">
			<div fxLayout="column" class="tab-container">
				<pp-header-edit-statistics #statistics [header]="editDto"> </pp-header-edit-statistics>
			</div>
		</mat-tab>
	</mat-tab-group>
</div>
