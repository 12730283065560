import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'in'
})
export class InPipe implements PipeTransform {

	transform(value: string | number, args: any): boolean {
		// console.log(value in args, value, args);
		return value in args;
	}

}
