import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EMPTY } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize, catchError } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { PostcodeService } from './search-postcode.service';
import {
	CountriesDto,
	CountryLookupDto,
	CitiesDto,
	CityDto,
	CitiesLookupDto,
	ConfigService,
} from 'app/services';


@Component({
	selector: 'pp-search-postcode',
	templateUrl: './search-postcode.component.html',
	styleUrls: ['./search-postcode.component.scss']
})
export class SearchPostcodeComponent implements OnInit {
	
	handleSelection: Function
	loading: boolean;
	form: FormGroup;
	country: string
	countries: CountryLookupDto[];
	cities: CityDto[];
	searchDto: CitiesLookupDto;
	isSuburb: boolean = false;
	isState: boolean = true;
	
	constructor(
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<SearchPostcodeComponent>,
		public service: PostcodeService,
		public config: ConfigService,
		private sanitization:DomSanitizer,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			handleSelection: any,
			country: string
		}
	) {
		this.handleSelection = data.handleSelection;
		this.country = data.country;
		this.searchDto = new CitiesLookupDto();
	}
	
	ngOnInit() {
		this.loading = true;
		this.buildForm();
		this.form.patchValue({
			countryId: this.searchDto.countryId
		})
		this.display();
	}

	isSuburbVisible() {
		return this.sanitization.bypassSecurityTrustStyle(this.isSuburb ? '' : 'display:none');
	}

	isStateVisible() {
		return this.sanitization.bypassSecurityTrustStyle(this.isState ? '' : 'display:none');
	}

	display() {
		this.service
			.getCountries()
			.pipe(
				catchError((err) => {
					console.log(err, 'loading countries');
					return EMPTY;
				}),
				finalize(() => (this.loading = false))
			)
			.subscribe(
				(result) => this.handleCountriesResult(result),
				(err) => console.log(err, 'running countries query')
		);
	}

	private handleCountriesResult(result: CountriesDto): void {
		this.countries = result.countries;

		if (this.country != null && this.country.length>0)
			this.searchDto.countryId = this.countries.find(c => c.name === this.country).id;

		if (this.searchDto.countryId == null)
			this.searchDto.countryId = this.config.systemPreferences.defaultCountryId;

		this.form.patchValue({
			countryId: this.searchDto.countryId
		});
	}

	cancel() {
		this.dialogRef.close();
	}

	private buildForm() {
		this.form = this.fb.group({
			countryId: ['', Validators.required],
			postcode: [],
			city: []
		});
	}

	search = () => {
		this.loading = true;
		this.searchDto.city = this.form.controls['city'].value;
		this.searchDto.postcode = this.form.controls['postcode'].value;
		this.searchDto.countryId = this.form.controls['countryId'].value;

		if ((this.searchDto.city == null || this.searchDto.city.length == 0)
			&& (this.searchDto.postcode == null || this.searchDto.postcode.length == 0)) {
			this.loading = false
			alert("Either City or Postcode must have a value");
			return;
		}

		this.service
			.lookupCities(this.searchDto)
			.pipe(
				catchError((err) => {
					console.log(err, 'loading cities');
					return EMPTY;
				}),
				finalize(() => (this.loading = false))
			)
			.subscribe(
				(result) => this.handleCitiesResult(result),
				(err) => console.log(err, 'running cities query')
		);
	}

	private handleCitiesResult(result: CitiesDto): void {
		this.cities = result.cities;

		if (this.cities.length>0){
			this.isSuburb = this.cities[0].isSuburb;
			this.isState = this.cities[0].isState;
		}
	}

	select(selection: CityDto) {
		this.handleSelection(selection);
		this.cancel();
	}
}
