<div fxLayout="column">
	<pp-modal-dialog-header title="Find a postocde"></pp-modal-dialog-header>
	
	<div mat-dialog-content fxLayout="column" fxFlex>
		
		<form novalidate autocomplete="off" [formGroup]="form" fxLayout="row">
			<div fxLayout="row">
				<pp-text class="city-search" formControlName="city" label="City"></pp-text>
				<div class="space"></div>
				<pp-text class="postcode-search" formControlName="postcode" label="Postcode"></pp-text>
				<div class="space"></div>
				<pp-select 
					class="country-search" 
					id="country"
					label="Country"
					fxFlexAlign="baseline"
					[options]="countries"
					placeholder="Country"
					formControlName="countryId">
				</pp-select>
				<div class="space"></div>
				<button mat-icon-button matTooltip="Search" (click)="search()"><mat-icon>search</mat-icon>Search</button>
			</div>
		</form>

		<div>
			<table class="postcode-table">
				<thead>
					<th class="postcode">Postcode</th>
					<th class="suburb" [style]="isSuburbVisible()">Suburb</th>
					<th class="city">City</th>
					<th class="state" [style]="isStateVisible()">State</th>
				</thead>
				<tbody>
					<tr class="table-row" *ngFor="let ent of cities" (click)="select(ent)">
						<td class="postcode">{{ ent.postcode }}</td>
						<td class="suburb" [style]="isSuburbVisible()">{{ ent.suburb }}</td>
						<td class="city">{{ ent.city }}</td>
						<td class="state" [style]="isStateVisible()">{{ ent.stateShortName }}</td>
					</tr>
				</tbody>
			</table>
		</div>

	</div>
	<mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>

	<pp-modal-dialog-footer 
		cancelOnly="true"
		(cancelled)="cancel()">
	</pp-modal-dialog-footer>
</div>