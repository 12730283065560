<div>
	<div fxLayout="row" fxLayoutAlign="start center">
		<div role="heading" aria-level="2" class="address-type" id="address-type">{{ data.address.typeName }}</div>
		<span fxFlex></span>
		<button aria-label="Save" mat-icon-button matDialogClose="ok" matTooltip="OK" (click)="submit()">
			<mat-icon>done</mat-icon>
		</button>
		<button aria-label="Cancel" mat-icon-button matDialogClose="cancel" matTooltip="Cancel">
			<mat-icon>close</mat-icon>
		</button>
	</div>

	<form novalidate autocomplete=" off" [formGroup]="form" (submit)="submit()">
		<div role="group" aria-labelledby="address-type" fxLayout="row" fxLayoutGap="10px" class="flex-wrap">
			<pp-select
				id="country"
				label="Country Code"
				fxFlexAlign="baseline"
				*ngIf="address.countryVisible"
				[options]="countries"
				[getLabel]="getCountry"
				placeholder="Country Code"
				formControlName="countryId">
			</pp-select>

			<pp-text
				fxFlexAlign="baseline"
				*ngIf="address.areaCodeVisible"
				formControlName="areaCode"
				label="Area code"
				fxFlex="100px">
			</pp-text>

			<pp-text
				fxFlexAlign="baseline"
				*ngIf="address.areaCodeVisible"
				formControlName="text"
				label="Number"
				fxFlex="150px">
			</pp-text>

			<pp-text
				fxFlexAlign="baseline"
				*ngIf="address.emailVisible"
				formControlName="text"
				label="Email"
				fxFlex>
			</pp-text>

			<div fxLayout="row" *ngIf="address.gridViewNormal" fxLayoutGap="10px">
				<pp-checkbox *ngIf="address.canBeForNotifyEmail" formControlName="isForNotifyEmail" label="For notify"></pp-checkbox>
				<pp-checkbox *ngIf="address.canBeForNotifySms" formControlName="isForNotifySms" label="For notify"></pp-checkbox>
			</div>
		</div>

		<div role="heading" aria-level="2" class="address-type">Additional Information</div>
		<div *ngIf="address.gridViewExtended" fxLayout="row" fxLayoutGap="10px" class="flex-wrap">
			<pp-checkbox *ngIf="address.canBeForNotifyEmail" formControlName="isForNotifyEmail" label="For notify"></pp-checkbox>
			<pp-checkbox *ngIf="address.canBeForNotifySms" formControlName="isForNotifySms" label="For notify"></pp-checkbox>
			<pp-checkbox *ngIf="address.emailVisible" formControlName="isCanSendEmail" label="Can send email"></pp-checkbox>
			<pp-checkbox *ngIf="address.showCanSendMsg" formControlName="isCanLeaveMessage" label="Can leave message"></pp-checkbox>
			<pp-checkbox *ngIf="address.showPrimarySecondary" formControlName="isPrimary" label="Is primary"></pp-checkbox>
			<pp-checkbox *ngIf="address.showPrimarySecondary" formControlName="isSecondary" label="Is secondary"></pp-checkbox>
		</div>
	</form>
</div>
