<div fxLayout="row" fxLayoutGap="16px">
	<pp-selection-grid
		#grid
		fxFlex="200px"
		[columnDefs]="columnDefs" 
		[rowData]="client?.statisticClientSessions"
		[rowClickIsEdit]="true"
		(edit)="edit($event)"
		(delete)="delete($event)"
		(add)="add($event)">
	</pp-selection-grid>
	
	<pp-client-session-edit
		#editor
		*ngIf="session"
		[session]="session"
		[addMode]="isAdding"
		(updated)="sessionUpdated($event)">
	</pp-client-session-edit>
</div>