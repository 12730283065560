import { Component, OnInit, Input, ViewChild, ChangeDetectionStrategy, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TdDialogService } from '@covalent/core/dialogs';


import { StatisticClientListDto, AgGridService } from 'app/services';
import { SelectionGridComponent } from 'app/shared/selection-grid/selection-grid.component';

import { CasesService } from 'app/cases/cases.service';

@Component({
	selector: 'pp-clients',
	templateUrl: './clients.component.html',
	styleUrls: ['./clients.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientsComponent implements OnInit {
	columnDefs: any[];
	editClient: StatisticClientListDto;
	activityHeaderId: number;

	@Input() clients: StatisticClientListDto[];
	@ViewChild('grid', { static: true }) grid: SelectionGridComponent;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private agGrid: AgGridService,
		private service: CasesService,
		private dialog: TdDialogService,
	) {
		this.activityHeaderId = +route.snapshot.paramMap.get('id');
	}

	ngOnInit() {
		this.setUpAgGrid();
	}

	add(e) {
		this.router.navigate(['client'], { relativeTo: this.route });
	}

	edit(client: StatisticClientListDto) {
		this.router.navigate(['client', { id: client.id }], { relativeTo: this.route });
	}

	delete(client: StatisticClientListDto) {
		this.service.getStatisticClient(this.activityHeaderId, client.id).subscribe(editDto => {
			if (editDto.statisticClientSessions.length) {
				this.dialog.openAlert({
					message: 'This client can\'t be removed until all sessions are removed. Please remove the sessions first.',
					title: 'Remove client',
				})
				return;
			}

			this.service.statisticClientDelete(this.activityHeaderId, editDto);
		});
	}

	private setUpAgGrid() {
		this.columnDefs = [
			{ headerName: 'Unique ID', field: 'id', width: 80, maxWidth: 80 },
			{ headerName: 'Client reference', field: 'anonymousReference', width: 200, maxWidth: 250 },
			{ headerName: 'Unique ID', field: 'id', width: 80, maxWidth: 80 },
			{ headerName: 'Client reference', field: 'anonymousReference', width: 200, maxWidth: 250 },
			{ headerName: 'Professional', field: 'professionalName', width: 175, maxWidth: 250 },
		];

		this.grid.subscribe(this.service.statisticClientsChanged$);
	}
}
