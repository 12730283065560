import {
	Directive,
	ViewChildren,
	Host,
	Self,
	Optional,
	QueryList,
	OnDestroy,
	ContentChildren
} from '@angular/core';
import { MatSort, MatSortHeader, Sort } from '@angular/material/sort';
import { LiveReaderService } from 'app/accessibility/live-reader.service';

import { Subscription } from 'rxjs';
import { MatSortHeaderDirective } from './mat-sort-header.directive';

@Directive({
	selector: '[matSort]'
})
export class MatSortDirective implements OnDestroy {

	private subscriptions: Subscription[] = [];

	private _headers: { [sortKey: string]: string } = {};
	@ContentChildren(MatSortHeaderDirective) set headers(value: QueryList<MatSortHeaderDirective>) {
		console.log(value);
		this._headers = {};
		value.forEach(x => {
			this._headers[x.id] = x.description;
		});
	};

	constructor(
		@Host() @Self() @Optional() public sort: MatSort,
		private announcer: LiveReaderService
	) {
		this.subscriptions.push(
			this.sort.sortChange.subscribe((sort: Sort) => {
				if (sort.active in this._headers) {
					const header = this._headers[sort.active];
					if (typeof header === 'string' && header.trim() != '') {
						switch (sort.direction) {
							case 'asc': return this.announcer.announce(`"${header.trim()}" sorted in ascending order`);
							case 'desc': return this.announcer.announce(`"${header.trim()}" sorted in descending order`);
							default: return this.announcer.announce(`"${header.trim()}" sort removed`);
						}
					}

				}
			}),
		)
	}

	ngAfterViewInit() { }

	ngOnDestroy(): void {
		this.subscriptions.forEach(x => x.unsubscribe());
	}

}
