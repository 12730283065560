<div fxLayout="column">
	<pp-selection-grid
		#grid
		fxFlex
		[columnDefs]="columnDefs" 
		[rowData]="headers"
		(edit)="edit($event)"
		[hasDelete]="false"
		[hasAdd]="false">
	</pp-selection-grid>
</div>