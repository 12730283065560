import { Pipe, PipeTransform } from '@angular/core';
import { AppointmentRendererService } from 'app/appointments/appointment-renderer.service';
import { AppointmentDto } from 'app/services';

@Pipe({
	name: 'sessionNumber'
})
export class SessionNumberPipe implements PipeTransform {

	constructor(private renderer: AppointmentRendererService) { }

	transform(value: AppointmentDto): string {
		return this.renderer.getSessionNumberDisplay(value);
	}

}
