import { ContentTypeInterceptor } from './content-type.interceptor';
import { TimeoutInterceptor, DEFAULT_TIMEOUT } from './timeoutInterceptor';
import { CustomisationService } from './services/customisation.service';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { TitleStrategy } from '@angular/router';
import { DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MAT_MOMENT_DATE_FORMATS, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter'
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import localFr from '@angular/common/locales/fr-CA';
import localFrExtra from '@angular/common/locales/extra/fr-CA';

import { AppComponent } from './app.component';

import { JwtModule } from '@auth0/angular-jwt';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from './interceptor';
import { CustomErrorHandler } from './services/error-handler';

import { routedComponents, AppRoutingModule, BreadcrumbsTitleStrategy } from './app-routing.module';

import { SharedModule } from './shared/shared.module';
import { CommonEditingModule } from './common-editing/common-editing.module';

import { AuthGuard, LogoutGuard } from './_guards';

import { MsalModule, MsalInterceptorConfiguration, MSAL_INSTANCE } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';

import {
	AgGridService,
	AuthenticationService,
	AuthsService,
	CheckSaveChangesService,
	CommonService,
	ConfigService,
	SignalrService,
	AnnouncementsService,
	CrudService,
	DashboardService,
	FormsService,
	JsonService,
	LoadingStateService,
	LoggerService,
	LogoutService,
	ModalService,
	SearchStateService,
	SessionStorageManagementService,
	UtilsService,
	CheckSaveChangesDialogComponent,
	SaveProgressDialogComponent,
	WarningsService,
	SyncfusionService,
} from './services';

import { CasesSearchService } from 'app/search/case-search/cases-search.service';
import { InvoicesSearchService } from 'app/search/invoice-search/invoices-search.service';
import { SearchComponent } from './search/search.component';
import { WarningsDialogComponent } from './services/warnings-dialog/warnings-dialog.component';
import { CaseSearchComponent } from './search/case-search/case-search.component';
import { InvoiceSearchComponent } from './search/invoice-search/invoice-search.component';
import { BaseViewComponent } from './shared/base-view/base-view-component';
import { BaseEditComponent } from './shared/base-edit/base-edit.component';
import { PracticeNavigatorComponent } from './practice-navigator/practice-navigator.component';
import { ProfileNavigatorComponent } from './profile/profile.component';
import { initScaleProps } from 'fullcalendar-scheduler/src/timeline/TimelineView.defaults';
import { appMsalConfig } from './msal.config';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { ICalDetailsDialogComponent } from './i-cal-details-dialog/i-cal-details-dialog.component';
import { HttpRetryInterceptor } from './httpRetryInterceptor';
import { AlertComponent } from './accessibility/alert/alert.component';
import { LiveReaderService } from './accessibility/live-reader.service';
import { LiveReaderComponent } from './accessibility/live-reader/live-reader.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function getToken() {
	return sessionStorage.getItem('access_token');
}

export function initApp(svc: CustomisationService) {
	return () => {
		return svc.loadConfigurationData();
	};
}

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localFr, 'fr-CA', localFrExtra);

@NgModule({
	declarations: [
		AppComponent,
		routedComponents,
		BaseEditComponent,
		BaseViewComponent,
		CaseSearchComponent,
		CheckSaveChangesDialogComponent,
		ConfirmDialogComponent,
		MessageDialogComponent,
		ICalDetailsDialogComponent,
		InvoiceSearchComponent,
		PracticeNavigatorComponent,
		ProfileNavigatorComponent,
		SaveProgressDialogComponent,
		SearchComponent,
		WarningsDialogComponent,
		AlertComponent,
		LiveReaderComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		SharedModule,

		HttpClientModule,
		JwtModule.forRoot({
			config: {
				tokenGetter: () => AuthenticationService.getToken()
			},
		}),

		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
			defaultLanguage: 'en',
		}),

		CommonEditingModule,
		AppRoutingModule,
		// MsalModule,//.forRoot(appMsalConfig),
	],
	providers: [
		AgGridService,
		AuthGuard,
		AuthenticationService,
		AuthsService,
		CasesSearchService,
		CheckSaveChangesService,
		CommonService,
		ConfigService,
		CrudService,
		DashboardService,
		DatePipe,
		DecimalPipe,
		FormsService,
		InvoicesSearchService,
		JsonService,
		LoadingStateService,
		LoggerService,
		LogoutGuard,
		LogoutService,
		ModalService,
		SearchStateService,
		SessionStorageManagementService,
		SignalrService,
		AnnouncementsService,
		Title,
		UtilsService,
		WarningsService,
		SyncfusionService,
		LiveReaderService,
		DatePipe,
		DecimalPipe,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpRetryInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: Interceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ContentTypeInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TimeoutInterceptor,
			multi: true,
		},
		{ provide: DEFAULT_TIMEOUT, useValue: 30000 },
		{
			provide: ErrorHandler,
			useClass: CustomErrorHandler,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: initApp,
			multi: true,
			deps: [CustomisationService],
		},
		{
			provide: TitleStrategy,
			useClass: BreadcrumbsTitleStrategy,
		},
	],
	bootstrap: [AppComponent, LiveReaderComponent],
	entryComponents: [
		CheckSaveChangesDialogComponent,
		ConfirmDialogComponent,
		MessageDialogComponent,
		ICalDetailsDialogComponent,
		SaveProgressDialogComponent,
		SearchComponent,
		WarningsDialogComponent,
	]
})
export class AppModule { }
