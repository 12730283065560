<mat-tab-group class="absolute-100" [selectedIndex]="mainTabIndex"
	(selectedIndexChange)="mainTabIndexChanged($event)">
	<mat-tab label="Contracted sessions">
		<ng-template matTabContent>
			<div class="tab-container" fxLayout="column">
				<div id="contracted-sessions" fxLayout="row" fxLayoutGap="16px" *ngIf="hasSessions">
					<pp-display label="Number per client" [value]="sessionsPerClient"></pp-display>
					<pp-button
						[disabled]="readOnly || addingExtensionRequest"
						label="Add extension request"
						(clicked)="addExtensionRequest($event)">
					</pp-button>
				</div>

				<div fxFlex fxHide.xs="true" id="vsp-wrapper" fxLayout="row">
					<vertical-split-pane
						fxFlex
						id="notes"
						primary-component-minsize="250"
						secondary-component-minsize="250"
						primary-component-initialratio="0.3">
						<div class="split-pane-content-primary">
							<pp-contract-service-type-items-display [contractInfo]="contractInfo">
							</pp-contract-service-type-items-display>
						</div>

						<div class="split-pane-content-secondary">
							<pp-contract-notes-display [contractInfo]="contractInfo">
							</pp-contract-notes-display>
						</div>
					</vertical-split-pane>
				</div>

				<div fxLayout="column" fxFlex fxHide.gt-xs="true">
					<pp-contract-service-type-items-display [contractInfo]="contractInfo">
					</pp-contract-service-type-items-display>

					<div class="xs-notes-display" fxFlex>
						<ng-content
							*ngTemplateOutlet="notesDisplay"
						></ng-content>
					</div>
				</div>

				<ng-template #contractTerms>
					<pp-contract-service-type-items-display
						[contractInfo]="contractInfo"
					>
					</pp-contract-service-type-items-display>
				</ng-template>

				<ng-template #notesDisplay>
					<pp-contract-notes-display [contractInfo]="contractInfo">
					</pp-contract-notes-display>
				</ng-template>
			</div>
		</ng-template>
	</mat-tab>

	<mat-tab label="Additional sessions" *ngIf="editDto.additionalSessions.length">
		<ng-template matTabContent>
			<div fxLayout="row" fxLayoutGap="16px">
				<mat-nav-list fxFlex="150px" fxHide.lt-md="true">
					<mat-list-item
						class="session-select"
						[ngClass]="{ active: request === selectedRequest }"
						*ngFor="let request of editDto.additionalSessions"
					>
						<div
							class="session-select"
							(click)="selectRequest(request)"
						>
							<span
								class="session"
								[ngClass]="{
									'new-request': !request.requestId
								}"
							>
								{{ request.dateOfRequest | date }}
							</span>
							<mat-icon
								class="additional-session-status"
								ngClass="{{ getSessionStatusClass(request) }}"
							>
								{{ getSessionStatusIcon(request) }}
							</mat-icon>
						</div>
					</mat-list-item>
				</mat-nav-list>

				<div fxFlex class="request-edit-container">
					<div
						*ngFor="let request of editDto.additionalSessions"
						ngClass.lt-md="additional-session-edit-mobile"
					>
						<pp-additional-session-edit
							[fxHide.gt-sm]="request !== selectedRequest"
							fxShow.lt-md="true"
							[request]="request"
							[readOnly]="readOnly"
							(isDirty)="requestUpdated()"
							(delete)="deleteRequest($event)"
						>
						</pp-additional-session-edit>
					</div>
				</div>
			</div>
		</ng-template>
	</mat-tab>

	<mat-tab label="Social services contact" *ngIf="!!contractInfo.socialServicesContactId">
		<ng-template matTabContent>
			<div fxLayout="row" fxLayoutGap="16px" [formGroup]="form">
				<pp-display
					label="Contact details"
					fxFlex="60"
					[value]="socialServicesContactInfo"
				>
				</pp-display>
				<pp-date
					label="Contacted date"
					formControlName="socialServicesContactedDate"
				></pp-date>
			</div>
		</ng-template>
	</mat-tab>
</mat-tab-group>
