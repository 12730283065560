<div fxLayout="column">
	<form novalidate autocomplete="off" [formGroup]="form">
		<div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start start" fxLayoutGap.gt-sm="16px">
			<pp-text #search 
				label="Search"
				placeholder="Text or file number search"
				formControlName="searchText" (focus)="filter.closeFilter()"
				fxFlex.gt-sm>
			</pp-text>
			<pp-search-filter #filter filterTextDatePrefix="Open" [form]="form" fxFlex></pp-search-filter>
		</div>
	</form>

	<div
		class="search-progress"
		[fxHide]="!(searchService.searching | async)"
		fxFlex fxLayout="row"
		fxLayoutAlign="center center">
		<mat-spinner></mat-spinner>
	</div>

	<div
		class="results-items"
		[fxHide]="searchService.searching | async"
		fxFlex
		fxLayout="column"
	>
		<!-- Delete Me: Not working, repalced with virtual-scroll
		<cdk-virtual-scroll-viewport itemSize="77" fxFlex>
			<pp-case-search-item
				*cdkVirtualFor="let result of searchService?.lastResult?.items"
				[result]="result"
				(select)="onSelect(result)"
				>
			</pp-case-search-item>
		</cdk-virtual-scroll-viewport>
		-->

		<virtual-scroll #scroll [items]="searchService?.lastResult?.items" fxFlex>
			<div *ngFor="let result of scroll.viewPortItems">
				<pp-case-search-item
				[result]="result"
				(select)="onSelect(result)">
			</pp-case-search-item>
		</div>
		</virtual-scroll>

	</div>
</div>