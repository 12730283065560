<div id="controls-wrapper">
	<div *ngFor="let statistic of statistics">
		<div fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="start center">
			<span class="description" fxHide.xs="true" fxFlex.gt-xs="200px">
				<span *ngIf="statistic.type.isRequired" class="required">*</span>
				{{ statistic.type.description }}
			</span>

			<div fxFlex.gt-xs class="editor" [ngSwitch]="statistic.type.uiTypeName">
				<pp-editor-picklist
					fxFlex="400px"
					fxFlex.xs="300px"
					*ngSwitchCase="'PickList'"
					[type]="statistic.type"
					[statistic]="statistic.statistic"
					(changed)="valueUpdated($event)"
				>
				</pp-editor-picklist>

				<pp-editor-textbox
					fxFlex="400px"
					fxFlex.xs="300px"
					*ngSwitchCase="'TextBox'"
					[type]="statistic.type"
					[statistic]="statistic.statistic"
					(changed)="valueUpdated($event)"
				>
				</pp-editor-textbox>

				<pp-editor-textbox-multi
					fxFlex
					*ngSwitchCase="'TextBoxMulti'"
					[type]="statistic.type"
					[statistic]="statistic.statistic"
					(changed)="valueUpdated($event)"
				>
				</pp-editor-textbox-multi>

				<pp-editor-numeric-textbox-int
					fxFlex="400px"
					fxFlex.xs="300px"
					*ngSwitchCase="'NumericTextBoxInt'"
					[type]="statistic.type"
					[statistic]="statistic.statistic"
					(changed)="valueUpdated($event)"
				>
				</pp-editor-numeric-textbox-int>

				<pp-editor-numeric-textbox-dec
					fxFlex="400px"
					fxFlex.xs="300px"
					*ngSwitchCase="'NumericTextBoxDec'"
					[type]="statistic.type"
					[statistic]="statistic.statistic"
					(changed)="valueUpdated($event)"
				>
				</pp-editor-numeric-textbox-dec>

				<pp-editor-hours-minutes
					fxFlex="400px"
					fxFlex.xs="300px"
					*ngSwitchCase="'HourMinuteTextBox'"
					[type]="statistic.type"
					[statistic]="statistic.statistic"
					(changed)="valueUpdated($event)"
				>
				</pp-editor-hours-minutes>
			</div>
		</div>
	</div>
</div>
