import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { WarningsDialogComponent } from 'app/services/warnings-dialog/warnings-dialog.component';
import { MediaObserver } from '@angular/flex-layout';
import { switchMap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class WarningsService {
	constructor(private matDialog: MatDialog, private media: MediaObserver) { }

	getMaxWidth() {
		if (this.media.isActive('lt-sm')) {
			return '375px';
		}

		return '500px';
	}

	showWarnings(title: string, messages: string[]): Observable<void> {
		return this.matDialog
			.open(WarningsDialogComponent, {
				width: this.getMaxWidth(),
				data: {
					title,
					messages,
				},
			})
			.afterClosed();
	}

	showWarningsWithConfirm(title: string, messages: string[], confirms?: string[]): Observable<boolean | string> {
		// If there are confirms, show those first, then if "Yes" carry on to show the warnings.
		if (confirms && confirms.length) {
			return this.showConfirm(title, confirms).pipe(
				switchMap((result) => {
					if (result === true) {
						if (!messages.length) {
							return of(true);
						}

						return this.showWarningsWithConfirm(title, messages);
					}

					return of(false);
				})
			);
		}

		return this.matDialog
			.open(WarningsDialogComponent, {
				width: this.getMaxWidth(),
				autoFocus: false,
				data: {
					title,
					messages,
					withConfirm: true,
				},
			})
			.afterClosed();
	}

	showConfirm(title: string, confirms: string[]): Observable<boolean | string> {
		return this.matDialog
			.open(WarningsDialogComponent, {
				width: this.getMaxWidth(),
				autoFocus: false,
				data: {
					title,
					messages: confirms,
					withConfirm: true,
					asYesNo: true,
				},
			})
			.afterClosed();
	}

	showRestricted(title: string, confirms: string[]): Observable<boolean | string> {
		return this.matDialog
			.open(WarningsDialogComponent, {
				width: this.getMaxWidth(),
				autoFocus: false,
				data: {
					title,
					messages: confirms,
					withConfirm: false,
					asYesNo: false,
				},
			})
			.afterClosed();
	}

	showMultiErrors(title: string, messagesString: string): Observable<void> {
		let messages = messagesString.split('\r\n').filter((s) => s);
		return this.matDialog
			.open(WarningsDialogComponent, {
				width: this.getMaxWidth(),
				data: {
					title,
					messages,
				},
			})
			.afterClosed();
	}
}
