import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ActivityHeaderEditDto, dateToAmbiguousMoment, addDateTimezoneOffset } from 'app/services';

/**
 * Form to hold client date of birth
 */

@Component({
	selector: 'pp-personal-details-edit',
	templateUrl: './personal-details-edit.component.html',
	styleUrls: ['./personal-details-edit.component.scss']
})
export class PersonalDetailsEditComponent implements OnInit {
	
	@Input() editDto: ActivityHeaderEditDto;
	@Input() readOnly: boolean;
	
	form: FormGroup;
	dateOfBirth: Date;
	
	constructor(private fb: FormBuilder) {
	}
	
	ngOnInit() {
		this.buildForm();
		if (this.editDto) {
			this.form.patchValue({
				dateOfBirth: this.editDto.clientDoB
			});
		}

		// Emit a selectionChanged when one of the values changes.
		this.form.valueChanges.subscribe(() => {
			let values = this.form.getRawValue();
			
			if (!values.dateOfBirth) {
				return;
			}
			let dob = dateToAmbiguousMoment(values.dateOfBirth);
			this.updateDob(dob);
		});
	}

	private updateDob(dob: any)
	{
		if (!this.readOnly){
			this.editDto.clientDoB =  addDateTimezoneOffset(dob.toDate());
			this.editDto.clientDoBChanged = true;
		}
	}

	buildForm() {
		this.form = this.fb.group({
			dateOfBirth: new FormControl({value : this.dateOfBirth, disabled: this.readOnly})
		});
	}

}
