
import { map, filter } from 'rxjs/operators';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
	selector: 'pp-modal-route-header',
	templateUrl: './modal-route-header.component.html',
	styleUrls: ['./modal-route-header.component.scss']
})
export class ModalRouteHeaderComponent {
	private sub: Subscription;
	active: boolean;

	@Input() title: string;
	@Input() form: UntypedFormGroup;
	@Output() result = new EventEmitter<boolean>();

	constructor(
		private route: ActivatedRoute,
		private router: Router,
	) {
		this.sub = this.router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			map(() => this.route),
			map((r) => {
				while (r.firstChild) {
					r = r.firstChild;
				}
				return r;
			}))
			.subscribe((currentRoute) => {
				this.active = this.route === currentRoute;
			});
	}

	applyResult(result: boolean) {
		this.result.emit(result);
	}
}
