import {
	Component,
	OnChanges,
	AfterViewInit,
	OnDestroy,
	Input,
	Output,
	ChangeDetectorRef,
	ViewChildren,
	ViewChild,
	QueryList,
	EventEmitter,
	ElementRef,
} from '@angular/core';
import * as _ from 'lodash';
import {
	CommunicationAddressDto,
	CountryLookupDto,
} from 'app/services';
import { CommAddrDisplayEditComponent } from '../comm-addr-display-edit/comm-addr-display-edit.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subscription } from 'rxjs';

@Component({
	selector: 'pp-comm-addr-editor',
	templateUrl: './comm-addr-editor.component.html',
	styleUrls: ['./comm-addr-editor.component.scss'],
})
export class CommAddrEditorComponent implements OnChanges, AfterViewInit {

	commAddressesWithValues: CommunicationAddressDto[];
	canAddMore: boolean = true;
	addableTypes: string[];

	@Input() commAddresses: CommunicationAddressDto[];
	@Input() countries: CountryLookupDto[];
	@Input() readOnly: boolean;
	@Output() updated = new EventEmitter<boolean>();

	@ViewChildren(CommAddrDisplayEditComponent) editorQuery: QueryList<CommAddrDisplayEditComponent>;
	@ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

	constructor(
		private cd: ChangeDetectorRef,
		private ref: ElementRef,
	) { }

	ngOnChanges(changes) {
		if (changes.commAddresses) {
			this.filterCommAddresses();
		}
	}

	ngAfterViewInit() {

		// To Fix the aria-expended not set when not opened
		// This is fixed with angular material v15, which was yet to be release when updating angular to v14
		this.menuTrigger?.menuOpened.subscribe(() => this.changeAriaExpended(true));
		this.menuTrigger?.menuClosed.subscribe(() => this.changeAriaExpended(false));
		this.changeAriaExpended(false);
	}

	changeAriaExpended(state: boolean) {
		Array.from(this.ref.nativeElement.querySelectorAll('.menu-trigger')).forEach((x: HTMLElement) => {
			x.setAttribute('aria-expended', state ? 'true' : 'false');
		});
	}

	commAddressUpdated(address) {
		this.filterCommAddresses();
		this.updated.emit(true);
	}

	addAddress(typeName: string) {
		const addressType = this.commAddresses.find(a => a.typeName === typeName);
		addressType.areaCode = '';
		addressType.text = '';
		this.commAddressesWithValues.push(addressType);

		this.cd.detectChanges();

		const comp = this.editorQuery.last;
		comp.add();
	}

	private filterCommAddresses(): void {
		this.commAddressesWithValues = (this.commAddresses || []).filter(a => {
			return a.text;
		});

		this.canAddMore = this.commAddressesWithValues.length < (this.commAddresses || []).length;
		const emptyTypes = _.difference(this.commAddresses, this.commAddressesWithValues);
		this.addableTypes = emptyTypes.map(t => t.typeName);
	}
}
