<div class="split-pane-tab-container" fxLayout="column">
	<div fxFlex fxHide.xs="true" id="vsp-wrapper" fxLayout="row">
		<vertical-split-pane fxFlex id="notes" primary-component-minsize="250" secondary-component-minsize="250"
			primary-component-initialratio="0.3">
			<div class="split-pane-content-primary">
				<pp-extended-details-items-display
				[extendedDetails] = "extendedDetails"
				[redFlagColour]="extendedDetails.redFlagColour"
				(changed)="itemsChanged($event)" 
				>
				</pp-extended-details-items-display>
			</div>

			<div class="split-pane-content-secondary">
				<pp-extended-details-edit-other
				[extendedDetails]="extendedDetails"
				(changed)="secondaryDetailsChanged($event)"  >
				</pp-extended-details-edit-other>
			</div>
		</vertical-split-pane>
	</div>

	<div fxLayout="column" fxFlex fxHide.gt-xs="true">
		<pp-extended-details-items-display>
		</pp-extended-details-items-display>
		<div class="xs-notes-display" fxFlex>
			<pp-extended-details-edit-other>
			</pp-extended-details-edit-other>
		</div>
	</div>
</div>