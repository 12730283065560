
import { of, Observable,  BehaviorSubject } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import {
	GlobalSearchResultDto,
	GlobalSearchResultItemDto,
	GlobalCaseSearchParams,
	CommonService,
	AuthenticationService,
} from 'app/services';

import {
	BaseService
} from 'app/services/base-service';

@Injectable({
	providedIn: 'root',
})
export class CasesSearchService extends BaseService {
	public searchText: string = null;
	public dateFrom?: Date = null;
	public dateTo?: Date = null;
	public openOnly = false;
	public showAll = false;

	public searching = new BehaviorSubject(false);
	public canShowMore = new BehaviorSubject(false);

	public lastResult: GlobalSearchResultDto;

	constructor(
		common: CommonService,
		private authenticationService: AuthenticationService) {
		super(common);

		this.authenticationService.isAuthenticated$.subscribe(isAuth => {
			if (!isAuth) {
				this.clearSearch();
			}
		});
	}

	search(
		searchText: string,
		all?: boolean,
		dateFrom?: Date,
		dateTo?: Date,
		openOnly?: boolean,
		saveResults: boolean = false): Observable<GlobalSearchResultItemDto[]> {
		const params = <GlobalCaseSearchParams>{
			searchText,
			showAll: !!all,
			dateFrom: dateFrom ? dateFrom : null,
			dateTo: dateTo ? dateTo : null,
			openOnly: !!openOnly
		};

		// Don't search with no parameters.
		if (!searchText && !all && !dateFrom && !dateTo && !openOnly) {
			this.lastResult = undefined;
			return of([]);
		}

		// Save the settings for the filter on this service.
		this.searchText = params.searchText;
		this.dateFrom = params.dateFrom;
		this.dateTo = params.dateTo;
		this.openOnly = params.openOnly;
		this.showAll = params.showAll;

		this.setSearching(true);

		return this.crud.loadWithPost<GlobalSearchResultDto>('api/globalSearch/cases', params).pipe(
			tap(r => {
				if (saveResults) {
					this.lastResult = r;
				}
			}),
			map(r => r.items),
			finalize(() => {
				this.setSearching(false);
			})
		);
	}

	setSearching(state: boolean) {
		this.searching.next(state);
	}

	private clearSearch() {
		this.searchText = undefined;
		this.dateFrom = undefined;
		this.dateTo = undefined;
		this.openOnly = false;
		this.showAll = false;

		this.lastResult = undefined;
	}
}
