<form novalidate autocomplete="off">
	<mat-form-field fxFlex hintLabel="HHHH:mm">
		<input
			name="input"
			matInput
			[placeholder]="type.description"
			type="text"
			[required]="type.isRequired"
			[(ngModel)]="value"
			[textMask]="textMask"
		/>
	</mat-form-field>
</form>
