import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';

import { ICheckSaveChanges } from 'app/interfaces';
import {
	CommonService,
	SaveResultDto,
	SaveResult,
	CheckSaveChangesService,
} from 'app/services';

import { IEditComponent } from 'app/interfaces';

@Component({
	template: ''
})
export class BaseEditComponent<T extends object> implements ICheckSaveChanges {
	protected location: Location;
	protected checkSaveChanges: CheckSaveChangesService;

	protected objectName: string;
	editDto: T;
	loading: boolean;

	constructor(
		public common: CommonService
	) {
		this.location = common.location;
		this.checkSaveChanges = common.checkSaveChanges;
	}

	getObject() {
		return this.editDto;
	}

	getObjectName() {
		return this.objectName;
	}

	protected fetch(fetcher: () => Observable<T>) {
		this.startLoading();

		fetcher().subscribe(r =>
			this.setEditDto(r),
			this.handleError.bind(this),
			this.fetchComplete.bind(this)
		);
	}

	protected completeWithBack(
		result: boolean,
		saver: () => Observable<SaveResultDto<any>> | boolean = undefined,
		component: IEditComponent = undefined) {
			
		if (!result) {
			this.location.back();
			return;
		}

		let saverResult: Observable<SaveResultDto<any>> | boolean;
		if (!saver) {
			saverResult = this.checkSaveChanges.saveChanges(component);
		} else {
			saverResult = saver();
		}

		if (typeof saverResult === 'boolean') {
			return;
		}

		saverResult.subscribe(r => {
			if (r.saveResult === SaveResult.OK) {
				this.location.back();
			}
		});
	}

	protected startLoading() {
		this.loading = true;
	}

	protected setEditDto(editDto: T) {
		this.loading = false;
		this.editDto = editDto;
	}

	protected handleError(err) {
		this.loading = false;
	}

	protected fetchComplete() {
		this.loading = false;
	}

	protected get systemPreferences() {
		return this.common.systemPreferences;
	}
}
