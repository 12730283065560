<pp-modal-dialog-header [title]="title"></pp-modal-dialog-header>
<div mat-dialog-content>
	<ul [ngClass]="{ single: messages.length === 1 }">
		<li *ngFor="let msg of messages">{{ msg }}</li>
	</ul>
</div>
<pp-modal-dialog-footer *ngIf="!withConfirm" [asClose]="true" (closed)="close()"> </pp-modal-dialog-footer>
<div *ngIf="withConfirm" mat-dialog-actions fxLayout="row" fxLayoutGap="10px">
	<span fxFlex></span>
	<button mat-button color="primary" (click)="confirm()">{{ confirmCaption }}</button>
	<button mat-button color="warn" #cancelButton (click)="cancel()">{{ cancelCaption }}</button>
</div>
