import { Component, OnChanges, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import * as _ from 'lodash';

import {
	IClientEditLoadDto,
	IClientEditDto,
	ContractInfoDto,
	AdditionalSessionDto,
	FormUtils
} from 'app/services';

import { ContractTermsService } from './contract-terms.service';
import { IEditComponent, IForm } from 'app/interfaces';

import { AdditionalSessionEditComponent } from '../additional-session-edit/additional-session-edit.component';
import { AdditionalSessionStatus } from '../additional-session-status.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
	selector: 'pp-contract-terms',
	templateUrl: './contract-terms.component.html',
	styleUrls: ['./contract-terms.component.scss']
})
export class ContractTermsComponent implements OnChanges, IForm, IEditComponent {
	private hasUnsavedChanges = false;

	@Input() contractInfo: ContractInfoDto;
	@Input() editDto: IClientEditDto;
	@Input() readOnly: boolean;

	@Output() changed = new EventEmitter<void>();

	@ViewChildren(AdditionalSessionEditComponent) additionalSessionEdits: QueryList<AdditionalSessionEditComponent>;

	selectedRequest: AdditionalSessionDto;
	mainTabIndex: number;
	sessionIndex: number;
	sessionsPerClient: string;
	columnDefs: any[];
	hasSessions: boolean;
	socialServicesContactInfo: string;

	form: UntypedFormGroup;

	constructor(
		private service: ContractTermsService,
		private additionalSessionStatus: AdditionalSessionStatus,
		private fb: UntypedFormBuilder
	) {
		this.buildForm();
	}

	ngOnChanges(changes) {
		let sessionsPerClient = this.contractInfo.numberSessionsPerClient;
		if (this.contractInfo.numberSessionsPerClientOverride) {
			sessionsPerClient = this.contractInfo.numberSessionsPerClientOverride;
		}

		if (sessionsPerClient > 100000) {
			this.sessionsPerClient = 'Unlimited';
		} else {
			this.sessionsPerClient = sessionsPerClient + '';
		}

		this.hasSessions = this.contractInfo.hasSessions;
		this.mainTabIndex = 0;
		this.hasUnsavedChanges = false;
		this.selectedRequest = undefined;

		if (this.editDto.additionalSessions.length) {
			this.sessionIndex = 0;
		}

		if (changes.editDto) {
			this.socialServicesContactInfo = this.editDto.socialServicesContactDetails;
			this.form.patchValue({
				socialServicesContactedDate: this.editDto.socialServicesContactedDate
			});
		}
	}

	getForm(): UntypedFormGroup {
		return this.form;
	}

	mainTabIndexChanged(newIndex) {
		this.mainTabIndex = newIndex;
		if (newIndex === 1 && !this.selectedRequest) {
			this.selectRequest(this.editDto.additionalSessions[0]);
		}
	}

	addExtensionRequest(event) {
		const newRequest = this.service.newAdditionalSession();
		this.editDto.additionalSessions.push(newRequest);

		this.sessionIndex = this.editDto.additionalSessions.length - 1;
		this.selectedRequest = newRequest;

		// Set the mainTabIndex after the additionalSessions have rendered.
		setTimeout(() => {
			this.mainTabIndex = 1;

			let edit = this.additionalSessionEdits.find(e => e.dto === this.selectedRequest);
			if (!edit) {
				return;
			}
			edit.setFocus();
		}, 100);
	}

	get addingExtensionRequest () {
		// Return true is there's an unsaved (requestId is 0) addiitonal session request.
		return !!this.editDto.additionalSessions.filter(r => r.requestId === 0).length;
	}

	selectRequest(e: AdditionalSessionDto) {
		this.selectedRequest = e;
	}

	requestUpdated() {
		this.hasUnsavedChanges = true;
		this.changed.emit();
	}

	deleteRequest(additionalSession: AdditionalSessionDto) {
		if (!additionalSession.requestId) {
			// Hasn't been saved
			const index = this.editDto.additionalSessions.indexOf(additionalSession);
			this.editDto.additionalSessions.splice(index, 1);

			if (this.editDto.additionalSessions.length) {
				this.selectedRequest = this.editDto.additionalSessions[0];
				this.sessionIndex = this.editDto.additionalSessions.length - 1;
			} else {
				this.selectedRequest = undefined;
				this.sessionIndex = - 1;
			}
		}
	}

	getSessionStatusClass(additionalSession: AdditionalSessionDto) {
		return this.additionalSessionStatus.getSessionStatusClass(additionalSession);
	}

	getSessionStatusIcon(additionalSession: AdditionalSessionDto) {
		return this.additionalSessionStatus.getSessionStatusIcon(additionalSession);
	}

	// IEditComponent methods
	hasChanges(): boolean {
		return this.hasUnsavedChanges || this.form.dirty;
	}

	markClean() {
		this.hasUnsavedChanges = false;
	}

	isValid(): boolean {
		let childEdits = this.additionalSessionEdits.toArray();
		let allValid = _.every(childEdits,  i => FormUtils.isFormValid(i.getForm()));
		return allValid;
	}

	private buildForm() {
		this.form = this.fb.group({
			socialServicesContactedDate: null
		});
	}
}
