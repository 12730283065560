<h1 mat-dialog-title class="title">
	{{ title }}
</h1>

<mat-dialog-content [innerHTML]="message" class="message"></mat-dialog-content>

<div mat-dialog-content class="sub-message">
	<p>{{ subMessage }}</p>
</div>

<div mat-dialog-actions align="end">
	<button mat-raised-button color="primary" (click)="onClose()">Close</button>
</div>
