<section>
	<div *ngFor="let addr of commAddressesWithValues">
		<pp-comm-addr-display-edit
			[address]="addr"
			[countries]="countries"
			[readOnly]="readOnly"
			(updated)="commAddressUpdated($event)">
		</pp-comm-addr-display-edit>
	</div>

	<div *ngIf="canAddMore" fxLayout="row" class="add-more">
		<span fxFlex></span>
		<button #trigger class="menu-trigger" aria-label="Add new contact information" matTooltip="Add new" mat-mini-fab [matMenuTriggerFor]="menu" [disabled]="readOnly">
			<mat-icon>add</mat-icon>
		</button>
		<mat-menu #menu="matMenu">
			<button mat-menu-item [attr.aria-label]="'Add new ' + addrType?.toLowerCase()" *ngFor=" let addrType of addableTypes" (click)="addAddress(addrType)">
				<mat-icon [matTooltip]="'Add new ' + addrType.toLowerCase()">add</mat-icon>
				<span>{{ addrType }}</span>
			</button>
		</mat-menu>
	</div>
</section>
