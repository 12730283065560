import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, } from '@angular/forms';

import {
	ActivityHeaderEditLoadDto,
	ActivityHeaderEditDto,
	FormUtils
} from 'app/services';
import { IForm } from 'app/interfaces';

@Component({
	selector: 'pp-client-partner-edit',
	templateUrl: './client-partner-edit.component.html',
	styleUrls: ['./client-partner-edit.component.scss']
})
export class ClientPartnerEditComponent implements IForm {
	@Input() loadDto: ActivityHeaderEditLoadDto;
	@Input() editDto: ActivityHeaderEditDto;

	form: UntypedFormGroup;

	constructor(
		private fb: UntypedFormBuilder
	) {
		this.buildForm();
	}
	
	getForm(): UntypedFormGroup {
		return this.form;
	}

	private buildForm() {
		this.form = this.fb.group({
			clientPartnerFirstName: ['', null],
			clientPartnerLastName: ['', null]
		});
	}
}
