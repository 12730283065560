import { CustomisationService } from './services/customisation.service';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { environment } from '../environments/environment';

import { AuthenticationService } from 'app/services';
import { LangService } from './services/lang.service';

@Component({
	selector: 'pp-app',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	constructor(
		private _iconRegistry: MatIconRegistry,
		private _domSanitizer: DomSanitizer,
		private authentication: AuthenticationService,
		customisationService: CustomisationService,
		_lang: LangService,
	) {

		_lang.init();

		if (environment.production) {
			// Ensure logged out on application startup - only in production.
			this.authentication.logout();
		}

		const buildSvgDataUrlBase64 = (svgBase64Encoded: string) => {
			if (!svgBase64Encoded) {
				return;
			}

			return `data:image/svg+xml;base64,${svgBase64Encoded}`;
		};

		const customisation = customisationService.customisation;

		const logoResourceUrl = buildSvgDataUrlBase64(customisation.logoSvg) || 'assets/icons/eap-executive-rgb.svg';
		const iconResourceUrl = buildSvgDataUrlBase64(customisation.iconSvg) || 'assets/icons/eap-exec-icon.svg';

		this._iconRegistry.addSvgIconInNamespace(
			'assets',
			'eapexec',
			this._domSanitizer.bypassSecurityTrustResourceUrl(logoResourceUrl),
		);

		this._iconRegistry.addSvgIconInNamespace(
			'assets',
			'eapexec-icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(iconResourceUrl),
		);
	}
}
