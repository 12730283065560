<div fxLayout="column" id="container">
	<mat-expansion-panel #panelProfile	[expanded]="false">
		
		<mat-expansion-panel-header>
			<mat-panel-title  class="profile-item">
				<mat-icon ngClass="profile-icon">perm_identity</mat-icon>
				<div class="profile-label">Profile</div>
			</mat-panel-title>
		</mat-expansion-panel-header>
		
		<div fxLayout="column" class="selection-container">
			<mat-nav-list class="profile-navigator">
				<div class="profile-line"></div>
				<mat-list-item class="profile-list-item" (click)="gotoProfile($event, profile)" *ngFor="let profile of profiles">
					<a class="nav-item profile-list-item-label" matLine>{{ profile }}</a>
				</mat-list-item>
			</mat-nav-list>
		</div>
		
	</mat-expansion-panel>
</div>
