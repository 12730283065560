import { AuthenticationService } from './services/authentication.service';
import { Observable, Subject, throwError, EMPTY } from 'rxjs';
import { catchError, tap, switchMap, retryWhen, delay, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
	HttpErrorResponse,
	HttpHandler,
	HttpEvent,
} from '@angular/common/http';
import { JsonService } from './services/json.service';
import { LoggerService } from './services/logger.service';
import { LogoutService } from './services/logout.service';
import { MatSnackBar } from '@angular/material/snack-bar';

const logRequests = false;

@Injectable()
export class Interceptor implements HttpInterceptor {
	private _refreshSubject: Subject<any> = new Subject<any>();

	constructor(
		private json: JsonService,
		private logger: LoggerService,
		private snackBar: MatSnackBar,
		private logout: LogoutService,
		private auth: AuthenticationService
	) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const tapResponseHandleAspNetDates = (event) => {
			if (event instanceof HttpResponse) {
				const resp: HttpResponse<any> = event;

				// Mutate the body, replace ASP.NET Dates with actual local Date objects.
				let body: {} = resp.body;
				this.json.recurseResponseBody(body);

				if (logRequests) {
					this.logger.logInfo('Result:');
					this.logger.logInfo(body);
				}
			}
		};

		if (logRequests) {
			this.logger.logInfo('Requests:');
			this.logger.logInfo(req.urlWithParams, req.body);
		}

		if (this.logout.loggingOut || req.url.endsWith('/refresh')) {
			return next.handle(req);
		} else {
			return next.handle(req).pipe(
				tap(tapResponseHandleAspNetDates.bind(this)),
				catchError((error) => {
					if (error instanceof HttpErrorResponse) {
						if (this._checkTokenExpiryErr(error)) {
							return this._ifTokenExpired().pipe(
								retryWhen((err) => {
									// Up to 3 retries to refresh the token
									let refreshRetries = 1;
									return err.pipe(
										delay(500),
										map((tokenRefreshErr) => {
											if (refreshRetries++ === 3) {
												throw tokenRefreshErr;
											}

											return tokenRefreshErr;
										})
									);
								}),
								catchError(() => {
									// Couldn't refresh the token.
									this.logout.forceLogin();
									return EMPTY;
								}),
								switchMap(() => {
									// Successfully refreshed the token, so update the
									// token on the request and try again.
									return next
										.handle(this.updateHeader(req))
										.pipe(tap(tapResponseHandleAspNetDates.bind(this)));
								})
							);
						} else {
							// Token not expired - just a normal HTTP error
							switch (error.status) {
								case 503:
								case 504:
									// Service unavailable/Gateway timeout
									this.snackBar.open('The server is unavailable', '', { duration: 2000 });
									this.logout.forceLogin();

									return EMPTY;
								default:
									return throwError(error);
							}
						}
					}

					return throwError(error);
				})
			);
		}
	}

	private _checkTokenExpiryErr(error: HttpErrorResponse): boolean {
		const isExpiry = error.status && error.status === 401;
		return isExpiry;
	}

	private _ifTokenExpired() {
		// If the token is expired, pauses requests and uses the refresh_token to get a new access_token
		// whereafter it then replays the requests using the new access_token.
		//
		// The access_token is short-lived, but the refresh_token is long-lived and allows getting a new
		// access_token after it expires.
		//
		// See: https://stackoverflow.com/questions/45202208/angular-4-interceptor-retry-requests-after-token-refresh
		// and: https://medium.com/@this.is.samy/angular-auto-session-recovery-interceptor-42e8233cfa23
		this._refreshSubject.subscribe({
			complete: () => {
				this._refreshSubject = new Subject<any>();
			},
		});
		if (this._refreshSubject.observers.length === 1) {
			this.auth.refreshToken().subscribe(this._refreshSubject);
		}
		return this._refreshSubject;
	}

	private updateHeader(req) {
		const authToken = this.auth.getToken();
		req = req.clone({
			headers: req.headers.set('Authorization', `Bearer ${authToken}`),
		});

		return req;
	}
}
