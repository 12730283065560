<div class="items">
	<div *ngFor="let item of statistics$ | async" class="item">
		<pp-container-pick-and-add-two-num-item
			[statistic]="item"
			[type]="type"
			(changed)="statisticChanged($event)"
			(deleted)="statisticDeleted($event)"
		></pp-container-pick-and-add-two-num-item>
	</div>
</div>

<div class="add-new" fxLayout="flex" fxLayoutAlign="start center" fxLayoutGap="12px">
	<pp-select
		#select
		label="Add new"
		labelKey="description"
		fxFlex
		fxFlex.gt-sm="300px"
		[options]="availableChoices$ | async"
	></pp-select>
	<button [disabled]="!select.value" (click)="addChoice(select.value)" mat-raised-button>Add choice</button>
</div>
