import { ICalDetailsDialogComponent } from './i-cal-details-dialog.component';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICalUrls } from './iCalUrls.model';

@Injectable({
	providedIn: 'root',
})
export class ICalDialogService {
	constructor(private dialog: MatDialog) { }

	showDetails(details: ICalUrls) {
		this.dialog.open(ICalDetailsDialogComponent, {
			data: details,
		});
	}
}
