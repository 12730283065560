<div fxLayout="column">
	<div fxLayout="row" *ngIf="hasChoiceLimit" fxLayoutAlign="start center" fxLayoutGap="8px" id="choice-warning">
		<mat-icon>info_outline</mat-icon>
		<span>{{ this.choiceLimitWarningText }}</span>
	</div>

	<div fxLayout="column" *ngFor="let type of types">
		<pp-chips-picker
			[container]="container"
			[canAdd]="canAdd"
			[type]="type"
			(changed)="pickerChanged($event)">
		</pp-chips-picker>
	</div>
</div>