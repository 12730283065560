import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr"
import { CrudService } from './crud.service';
import { ConfigService } from './config.service';
import * as _ from 'lodash';
import { AnnouncementsService } from 'app/services';

/**
 * Service for fetching and managing announcements for the currently authenticated user.
 * The service uses Signalr to establish a websocket to the server.
 * The server will push notifications, and these are recieved within this service.
 */
@Injectable()
export class SignalrService {
	
	constructor(
		private crud: CrudService,
		private config: ConfigService,
		private announcement: AnnouncementsService){}

	//Connect to server running Signalr
	private hubConnection: signalR.HubConnection
    public startConnection = (signalrUrl) => {
		let userConfig = this.config.config;
		this.hubConnection = new signalR.HubConnectionBuilder()
								.withUrl(signalrUrl 
									+ '?businessRoleGuid=' + userConfig.businessRoleGuid
									+ '&userGuid=' + userConfig.userGuid, {
									skipNegotiation: true,
									transport: signalR.HttpTransportType.WebSockets,
									withCredentials: false
								  })
								.build();
		this.hubConnection
			.start()
			.then(() => console.log('Connection started'))
			.catch(err => console.log('Error while starting connection: ' + err))
    }

	//Disconnect Signalr
	public stopConnection = () => {
		this.hubConnection.stop();
	}

	//Handle Signalr SendAsync messages
	public addReceiveMessageListener = () => {
		this.hubConnection.on('ReceiveMessage', (data) => {
			if (data === 'Announcement'){
				this.announcement.updateAnnouncementsUnread();
			}
		});
	  }

}
