<mat-tab-group>
	<mat-tab label="Headers" *ngIf="hasHeaders">
		<div fxLayout="column" class="tab-container">
			<pp-headers [headers]="headers"> </pp-headers>
		</div>
	</mat-tab>

	<mat-tab label="Clients" *ngIf="hasClients">
		<div fxLayout="column" class="tab-container">
			<pp-clients [clients]="clients"></pp-clients>
		</div>
	</mat-tab>
</mat-tab-group>
