import { ConfirmDialogService } from './../services/confirm-dialog.service';
import { MessageDialogService } from './../services/message-dialog.service';
import { CustomisationService } from './../services/customisation.service';
import { Component, ElementRef, ViewChild, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { LogoutService, AuthenticationService, SignalrService, ConfigService, AnnouncementsService } from 'app/services';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { MediaObserver } from '@angular/flex-layout';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { AnnouncementsDto } from 'app/services';
import { SearchComponent } from '../search/search.component';
import { environment } from 'environments/environment';

class RouteSpec {
	title: string;
	route: string;
	icon: string;
	hideForPractice?: boolean;
	hideForProfessional?: boolean;
	hideForEmployee?: boolean;
	hideResouceHub?: boolean;
}

interface IToolbarStyle {
	'background-color'?: string;
	color?: string;
}

@Component({
	selector: 'pp-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
	@ViewChild('search', { static: true }) searchEl: ElementRef;

	showCopyright = false;
	username: string;
	version: string;
	serverVersion: string;

	practiceMode = false;
	isEmployee = false;
	useResouceHub = false;
	useProfile = false;

	param = { value: ', world!' };

	_signalrService;
	_announcementService: AnnouncementsService;
	showAnnouncements = false;
	unread = -2;
	_messageDialog: MessageDialogService
	
	// Routes for the sidebar menu.
	allRoutes: RouteSpec[] = [
		{
			title: 'Dashboard',
			route: '/dashboard',
			icon: 'dashboard'
		},
		{
			title: 'Cases',
			route: '/cases',
			icon: 'view_quilt'
		},
		{
			title: 'Appointments',
			route: '/appointments',
			icon: 'face',
			hideForPractice: true
		},
		{
			title: 'Invoices',
			route: '/invoices',
			icon: 'receipt',
			hideForEmployee: true
		},
		{
			title: 'Availability',
			route: '/availability',
			icon: 'people',
			hideForPractice: true
		},
		{
			title: 'Activity query',
			route: '/activity-query',
			icon: 'reorder'
		},
		{
			title: 'Resource Hub',
			route: '/resource-hub',
			icon: 'library_books',
			hideResouceHub: true
		}
	];

	routes: RouteSpec[];

	sidenavMode = 'side';
	sidenavOpened = true;

	mediaWatcher: Subscription;
	activeMediaQuery = '';
	mediaAlias = '';

	title = 'Professional Portal';
	toolbarStyle: IToolbarStyle = {};

	constructor(
		private router: Router,
		private config: ConfigService,
		private signalrService: SignalrService,
		private announcement: AnnouncementsService,
		private dialog: MatDialog,
		private logoutService: LogoutService,
		public auth: AuthenticationService,
		public media: MediaObserver,
		public iconRegistry: MatIconRegistry,
		sanitizer: DomSanitizer,
		customisationService: CustomisationService,
		private confirmDialog: ConfirmDialogService,
		private messageDialog: MessageDialogService
	) {
		this.version = environment.PACKAGE.version;
		this.serverVersion = this.getServerVersion();
		this.username = this.getUsername();

		iconRegistry.addSvgIcon(
			'eapexec-icon',
			sanitizer.bypassSecurityTrustResourceUrl('assets/icons/eap-exec-icon.svg')
		);

		this.practiceMode = config.config.isPractice;
		this.isEmployee = config.config.isEmployee;
		this.useResouceHub = config.systemPreferences.useResouceHub;
		this.useProfile = config.systemPreferences.useProfile;
		this.showAnnouncements = config.systemPreferences.useAnnouncements;
		this._signalrService = signalrService;
		this._announcementService = announcement;

		this.configureRoutesForModes();

		const customisation = customisationService.customisation;
		if (customisation.toolbarBackgroundColor) {
			this.toolbarStyle['background-color'] = customisation.toolbarBackgroundColor;
		}

		if (customisation.toolbarColor) {
			this.toolbarStyle.color = customisation.toolbarColor;
		}

		this.title = customisation.appTitle || this.title;
		this.showCopyright = !!customisation.showCopyright;
	}

	ngOnInit() {
		this.auth.isAuthenticated$.subscribe(isAuthenticated => {
			if (isAuthenticated) {
				this.isEmployee = this.config.config.isEmployee;
				this.configureRoutesForModes();
			}
		});

		this.auth.isPracticeMode$.subscribe(practiceMode => {
			this.practiceMode = practiceMode;
			this.configureRoutesForModes();
		});

		this.mediaWatcher = this.media.asObservable().subscribe(changes => {
			let change = changes[0];

			this.activeMediaQuery = change ? `'${change.mqAlias}' = (${change.mediaQuery})` : '';
			this.mediaAlias = change.mqAlias;
			this.configureFromMediaAlias();
		});

		this.sidenavOpened = this.media.isActive('gt-sm');

		if (this.showAnnouncements){
			//Initialise announcements
			this.initialiseAnnouncementUnread(true);
			
			//Setup Signalr socket
			this._signalrService.startConnection(this.config.systemPreferences.signalrUrl);
			this._signalrService.addReceiveMessageListener();
			
			//Setup a watch on changes via announcements
			this._announcementService.unreadObservable.subscribe(
				unread => {this.unread = unread;}
			);
	}
	}

	ngOnDestroy() {
		this.mediaWatcher.unsubscribe();
	}

	toggleSidenav() {
		this.sidenavOpened = !this.sidenavOpened;
	}

	sidenavOpenedChanged(isOpen) {
		this.sidenavOpened = isOpen;
	}

	configureFromMediaAlias() {
		this.sidenavMode = this.media.isActive('lt-md') ? 'over' : 'side';
	}

	configureRoutesForModes() {
		this.routes = this.allRoutes.filter(r => {
			if (r.hideForEmployee && this.isEmployee) {
				return false;
			}

			if (r.hideForPractice && this.practiceMode) {
				return false;
			}

			if (r.hideForProfessional && !this.practiceMode) {
				return false;
			}

			if (r.hideResouceHub && !this.useResouceHub) {
				return false;
			}
			
			return true;
		});
	}

	async logout(requireConfirm?: boolean) {
		const doLogout = () => {
			if (this.showAnnouncements)
				this._signalrService.stopConnection();
			this.logoutService.forceLogin();
		};

		if (!requireConfirm) {
			doLogout();
			return;
		}

		const confirmResult = await this.confirmDialog.confirm('Are you sure you want to log out?', 'Confirm Logout');
		if (confirmResult) {
			doLogout();
		}
	}

	testError() {
		throw new Error('Test error');
	}

	showSearch() {
		const gtSm = this.media.isActive('gt-sm');
		const rect = this.searchEl.nativeElement.getBoundingClientRect();

		let width;
		let height;
		let left;
		if (gtSm) {
			width = 870;
			height = '80%';
			left = rect.right - width + 'px';
		} else {
			width = 400;
			height = '90%';
			left = '10px';
		}
		let dialogRef = this.dialog.open(SearchComponent, {
			width: width + 'px',
			height: height,
			panelClass: 'search-dialog',
			position: {
				left,
				top: '60px'
			}
		});
	}

	getAnnouncementUnread() {
		return this.unread;
	}

	openAnnouncements(e: Event) {
		e.stopPropagation();
		this.unread = -2;
		this.initialiseAnnouncementUnread(false);
		this.router.navigate(['announcements']);
	}

	initialiseAnnouncementUnread(showUnreadDialog : boolean) {
		if (this.unread >= -1) 
			return this.unread;
		this.unread = -1;
	
		if (showUnreadDialog) {
			this._announcementService.getAnnouncementsUnread(true)
			.subscribe(result => {
				try {
					var unread = parseInt(result);
					if (showUnreadDialog && unread > 0)
						this.messageDialog.message('You have unread priority notifications', 
							'Please open announcements to view');

				} catch (error) {
				}
			});
		}

		//Get all unread
		this._announcementService.getAnnouncementsUnread(false)
		.subscribe(result => {
			try {
				this.unread = parseInt(result);
			} catch (error) {
				this.unread = -2;
			}
		});
	}

	getAnnouncementStyle() {
		if (this.unread > 0) return {'color':'red'}
		return {'color':'#ef6c00'}
	}

	private getUsername() {
		const userConfig = this.config.config;
		if (!userConfig) {
			return '';
		}
		return userConfig.name;
	}

	private getServerVersion() {
		const systemPrefs = this.config.systemPreferences;
		if (!systemPrefs) {
			return '';
		}
		return systemPrefs.serverVersion;
	}
}
