import { Component, AfterViewInit, ViewChild } from '@angular/core';
import {
	MatDialogRef,
} from '@angular/material/dialog';

import { CaseSearchComponent } from './case-search/case-search.component';
import { InvoiceSearchComponent } from './invoice-search/invoice-search.component';
import { SearchStateService, ConfigService } from 'app/services';
import { MatTabChangeEvent } from '@angular/material/tabs';

const TAB_CASES = 'Cases';
const TAB_INVOICES = 'Invoices';

@Component({
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss']
})
export class SearchComponent implements AfterViewInit {
	isEmployee: boolean;

	selectedTabIndex = 0;

	@ViewChild('cases') cases: CaseSearchComponent;
	@ViewChild('invoices') invoices: InvoiceSearchComponent;

	constructor(
		public dialogRef: MatDialogRef<SearchComponent>,
		private searchState: SearchStateService,
		private config: ConfigService,
	) {
		this.isEmployee = config.config.isEmployee;

		if (searchState.lastState) {
			this.selectedTab = searchState.lastState;

			if (this.isEmployee && this.selectedTab === TAB_INVOICES) {
				this.selectedTab = TAB_CASES;
			}
		} else {
			this.selectCases();
		}
	}

	get selectedTab() {
		switch (this.selectedTabIndex) {
			case 0:
				return TAB_CASES;
			case 1:
				return TAB_INVOICES;
		}
	}

	set selectedTab(value: string) {
		switch (value) {
			case TAB_CASES:
				this.selectedTabIndex = 0;
				break;
			case TAB_INVOICES:
				this.selectedTabIndex = 1;
				break;
		}
	}

	ngAfterViewInit() {
		this.setFocus();
	}

	resultSelected() {
		this.dialogRef.close();
	}

	selectCases() {
		this.selectedTab = TAB_CASES;
		this.searchState.lastState = this.selectedTab;
		this.setFocus();
	}

	selectInvoices() {
		this.selectedTab = TAB_INVOICES;
		this.searchState.lastState = this.selectedTab;
	}

	onSelectedTabChange(event: MatTabChangeEvent) {
		this.selectedTabIndex = event.index;

		switch (this.selectedTabIndex) {
			case 0:
				this.selectCases();
				break;
			case 1:
				this.selectInvoices();
				break;
		}

		this.setFocus();
	}

	setFocus() {
		setTimeout(() => {
			switch (this.selectedTab) {
				case TAB_CASES:
					this.cases.setFocus();
					break;
				case TAB_INVOICES:
					this.invoices.setFocus();
			}
		});
	}

	//Readded this code
	get isInvoices() {
		return this.selectedTab === TAB_INVOICES;
	}

	get isCases() {
		return this.selectedTab === TAB_CASES;
	}
}
